/* eslint-disable complexity */
import React, { useContext, useEffect, useState } from 'react';
import icons from '../../assets/leftSide';
import { colors } from '../../themes';
import { AppContext } from '../../context/AppProvider';
import { hasValue } from '../../utils';
import Image from 'next/image';
import image from '../../assets/Icons';

const RenderHeader = ({ screen, back, closeModal }) => {
  const context = useContext(AppContext);

  const [IsLight, setIsLight] = useState(false);

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  return (
    <div className="flex h-[50px] shadow-[0_0.33px_0_0_#BDC5CD] dark:shadow-[0_0.33px_0_0_#313234] items-center">
      {screen.length !== 1 && (
        <div className="absolute left-4" onClick={() => back()}>
          <icons.back
            color={hasValue(
              IsLight,
              colors?.Neutral900,
              colors?.Neutral900Dark
            )}
          />
        </div>
      )}
      <div className="w-full justify-center flex">
        <Image
          priority
          src={image.logo}
          alt={'logo'}
          className="w-[102.68px] h-[50px]"
        />
      </div>
      <div className="absolute right-4" onClick={() => closeModal()}>
        <icons.cross
          w={24}
          h={24}
          color={hasValue(IsLight, colors.Neutral900, colors.Neutral900Dark)}
        />
      </div>
    </div>
  );
};

export default RenderHeader;
