import * as React from 'react';

const PlusWhite = ({}) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33337 9.00016H9.00004M16.6667 9.00016H9.00004M9.00004 9.00016V1.3335M9.00004 9.00016V16.6668"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PlusWhite;
