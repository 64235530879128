const images = {
  loginBackGround: require('./loginBackground.png'),
  placeholderDark: require('./placeholder-dark (1).png'),
  placeholderLight: require('./placeholder-light (1).png'),
  appStoreBadge: require('./app-store-badge.png'),
  googlePlayBadge: require('./google-play-badge.png'),
  postPrivateLight: require('./post_private_light.svg'),
  postPrivateDark: require('./post_private_dark.svg'),
  vinePrivateLight: require('./vine_private_light.svg'),
  vinePrivateDark: require('./vine_private_dark.svg')
};
export default images;
