import API_CONSTANT from '../../constants/ApiConstant';
import {
  get,
  getWithOutToken,
  post,
  postWithTokenAndFormHeader,
  removePostComment
} from '../../services/ApiServices';

const getFeedUnAuthorizedAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    getWithOutToken(`${API_CONSTANT.FETCH_FEED_UNAUTHORIZED}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getPostAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.POST}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getFeedAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.FETCH_FEED}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getUIFlag = dispatch => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.UI_FLAGS}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getCommunityAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.COMMUNITY}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getFetchPostAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.FETCH_POST}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const unauthorizedCommunityAPI = async (dispatch, query) => {
  try {
    const result = await (
      await fetch(
        `${API_CONSTANT.BASE_URL}${API_CONSTANT.COMMUNITY_UNAUTHORIZED}${query}`
      )
    ).json();
   return result;
  } catch {
    return;
  }
};

const getLikeAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.LIKE_POST, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getDisLikeAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.DISLIKE_POST, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getPostCommentAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.FETCH_POST_COMMENTS}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getPostUnauthCommentAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.FETCH_POST_COMMENTS_UNAUTHORIZED}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const CreateCommentAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.CREATE_COMMENT, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getLikeCommentAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.POST_COMMENT_LIKE, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getDisLikeCommentAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.POST_COMMENT_UNLIKE, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const postVotePoll = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.VOTE_POLL, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const banAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.ADMIN_BAN_USER, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const reportCommentAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.REPORT_COMMENT, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const blockUserAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.BLOCK_USER, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const boostAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.ADMIN_BOOST_POST, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const deletePostAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    removePostComment(API_CONSTANT.REMOVE_POST, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};
const deleteCommentAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    removePostComment(API_CONSTANT.REMOVE_POST_COMMENTS, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const deleteWarnPostAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    removePostComment(API_CONSTANT.ADMIN_DELETE_WARN_POST, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};
const deleteWarnCommentAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    removePostComment(API_CONSTANT.ADMIN_DELETE_WARN_COMMENT, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const reportPostAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.REPORT_POST, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const reportUserAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.REPORT_USER, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const bookmarkAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.BOOKMARK_POST, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const unBookmarkAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.UNBOOKMARK_POST, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const mentionUserAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.MENTION_USER}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const CreatePostAPI = (dispatch, data) => {
  return new Promise((resolve, reject) => {
    post(API_CONSTANT.CREATE_POST, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const postImg = (dispatch, data = {}, image) => {
  const formData = new FormData();
  formData.append('community_id', `${data.community_id}`);
  if (data.flair_id) {
    formData.append('flair_id', `${data.flair_id}`);
  }
  formData.append('post_type', `${data.post_type}`);
  formData.append('title', `${data.title}`);
  if (data.content) {
    formData.append('content', `${data.content}`);
  }
  formData.append('image', data?.image);

  return new Promise((resolve, reject) => {
    postWithTokenAndFormHeader(API_CONSTANT.CREATE_POST, formData)
      .then(async response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export {
  getFeedUnAuthorizedAPI,
  getCommunityAPI,
  getFeedAPI,
  getUIFlag,
  getLikeAPI,
  getDisLikeAPI,
  getPostCommentAPI,
  CreateCommentAPI,
  getLikeCommentAPI,
  getDisLikeCommentAPI,
  postVotePoll,
  reportCommentAPI,
  banAPI,
  blockUserAPI,
  boostAPI,
  deletePostAPI,
  deleteWarnPostAPI,
  deleteCommentAPI,
  deleteWarnCommentAPI,
  reportPostAPI,
  reportUserAPI,
  getFetchPostAPI,
  bookmarkAPI,
  unBookmarkAPI,
  mentionUserAPI,
  CreatePostAPI,
  postImg
};
