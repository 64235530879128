import * as React from 'react';

const Check = ({ w, h, className }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM14.4297 7.91813C14.8202 7.5276 14.8202 6.89444 14.4297 6.50391C14.0392 6.11339 13.406 6.11339 13.0155 6.50391L9.07142 10.448L7.91807 9.29461C7.52754 8.90409 6.89438 8.90409 6.50385 9.29461C6.11333 9.68513 6.11333 10.3183 6.50385 10.7088L8.36432 12.5693C8.75484 12.9598 9.38801 12.9598 9.77853 12.5693L14.4297 7.91813Z"
      fill="#1E92FF"
    />
  </svg>
);
export default Check;
