import * as React from 'react';

const SavePost = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.0415C7.86574 1.0415 6.79859 1.0415 5.94712 1.36197C4.59955 1.86917 3.53604 2.93268 3.02884 4.28025C2.70837 5.13172 2.70837 6.19887 2.70837 8.33317V16.9965C2.70837 17.6388 2.70837 17.96 2.81182 18.1693C2.98112 18.512 3.33094 18.7282 3.71315 18.7264C3.9467 18.7253 4.23394 18.5817 4.80843 18.2944C6.38504 17.5061 7.17335 17.112 7.98598 16.9006C9.30671 16.557 10.6934 16.557 12.0141 16.9006C12.8267 17.112 13.615 17.5061 15.1917 18.2944C15.7661 18.5817 16.0534 18.7253 16.2869 18.7264C16.6691 18.7282 17.019 18.512 17.1883 18.1693C17.2917 17.96 17.2917 17.6388 17.2917 16.9965V8.33317C17.2917 6.19887 17.2917 5.13172 16.9712 4.28025C16.464 2.93268 15.4005 1.86917 14.053 1.36197C13.2015 1.0415 12.1343 1.0415 10 1.0415Z"
      fill={color}
    />
  </svg>
);
export default SavePost;
