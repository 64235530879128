import * as React from 'react';

const DPoll = ({ w, h, color, ...props }) => (
  <svg
    width="22.8"
    height="22.8"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25 10.1L7.25 6.3L6.3 7.05995M7.25 10.1L6.3 10.1M7.25 10.1L8.2 10.1M6.30016 12.95L8.2 12.95L8.19985 14.85L6.3 14.85L6.3 16.75L8.2 16.75M11.05 16.75L17.7 16.75M11.05 12L17.7 12M11.05 7.25L17.7 7.25M10.1 21.5L13.9 21.5C16.5603 21.5 17.8904 21.5 18.9065 20.9823C19.8002 20.5269 20.5269 19.8002 20.9823 18.9065C21.5 17.8904 21.5 16.5602 21.5 13.9L21.5 10.1C21.5 7.43975 21.5 6.10962 20.9823 5.09354C20.5269 4.19977 19.8002 3.47311 18.9065 3.01772C17.8904 2.5 16.5602 2.5 13.9 2.5L10.1 2.5C7.43975 2.5 6.10963 2.5 5.09355 3.01772C4.19978 3.47312 3.47312 4.19978 3.01772 5.09354C2.5 6.10963 2.5 7.43975 2.5 10.1L2.5 13.9C2.5 16.5602 2.5 17.8904 3.01772 18.9065C3.47312 19.8002 4.19978 20.5269 5.09355 20.9823C6.10963 21.5 7.43975 21.5 10.1 21.5Z"
      stroke={color}
      strokeWidth="1.425"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DPoll;
