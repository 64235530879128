import * as React from 'react';

const More = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25 1.5H20.75M1.25 8H20.75M1.25 14.5H20.75"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
export default More;
