import * as React from 'react';

const Link = ({ w, h, color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3100_3380)">
      <path
        d="M10.1179 6.07422L8.86359 7.33458C10.1084 7.43728 10.9195 7.80138 11.5324 8.40822C13.1828 10.0421 13.1734 12.3573 11.5419 13.9725L8.45808 17.016C6.81715 18.6405 4.49722 18.6498 2.84687 17.0253C1.19651 15.3822 1.20594 13.0855 2.84687 11.4611L4.69526 9.63127C4.4312 9.04307 4.3652 8.34287 4.46893 7.73603L1.69633 10.4715C-0.557582 12.7121 -0.576443 15.8863 1.70576 18.1456C3.9974 20.4143 7.20381 20.3956 9.45772 18.1643L12.6829 14.962C14.9463 12.7215 14.9652 9.54721 12.6736 7.2879C12.0794 6.69974 11.325 6.27962 10.1179 6.07422ZM9.88214 13.767L11.1364 12.5067C9.8915 12.4133 9.0805 12.0399 8.4675 11.4331C6.81715 9.79924 6.82658 7.48396 8.45808 5.86884L11.5324 2.8253C13.1828 1.20085 15.5028 1.19151 17.1531 2.8253C18.8034 4.4591 18.7846 6.76509 17.1531 8.38021L15.3047 10.21C15.5687 10.8075 15.6253 11.4984 15.5311 12.1053L18.3037 9.36983C20.5575 7.12919 20.5764 3.96429 18.2942 1.69565C16.0025 -0.572994 12.7961 -0.554322 10.5328 1.68632L7.31697 4.87922C5.05363 7.11985 5.03476 10.2941 7.3264 12.5534C7.92053 13.1415 8.67498 13.5617 9.88214 13.767Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_3100_3380">
        <rect width="20" height="20" fill={color} />
      </clipPath>
    </defs>
  </svg>
);
export default Link;
