import * as React from 'react';

const DeBoost = ({ w, h, color, ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_181_8697)">
      <path
        d="M17.0447 1.85606C17.7007 1.72486 18.0287 1.65926 18.2854 1.74807C18.5105 1.82596 18.7005 1.98168 18.821 2.18718C18.9584 2.42148 18.9584 2.75599 18.9584 3.42499L18.9584 4.97566C18.9584 5.49003 18.9584 5.74722 18.9289 5.98907C18.8116 6.94797 18.3513 7.83203 17.6329 8.47795C17.4518 8.64087 17.2411 8.78835 16.8197 9.0833C16.6487 9.20294 16.5633 9.26276 16.4976 9.28557C16.226 9.37988 15.931 9.22629 15.8525 8.94972C15.8335 8.8828 15.8335 8.77848 15.8335 8.56984L15.8335 2.75413C15.8335 2.52051 15.8335 2.4037 15.8754 2.30796C15.9123 2.22353 15.9718 2.15094 16.0473 2.0982C16.133 2.03839 16.2476 2.01548 16.4766 1.96967L17.0447 1.85606Z"
        fill={color}
      />
      <path
        d="M3.18074 9.08333C2.75925 8.78834 2.5485 8.64084 2.36729 8.4779C1.64895 7.832 1.18863 6.94796 1.07135 5.98908C1.04177 5.74718 1.04177 5.48995 1.04177 4.97548L1.04177 3.42499C1.04177 2.75598 1.04177 2.42148 1.17919 2.18718C1.29973 1.98167 1.48967 1.82595 1.71483 1.74806C1.97153 1.65926 2.29954 1.72486 2.95555 1.85606L3.52376 1.9697C3.75284 2.01552 3.86739 2.03843 3.95306 2.09824C4.02862 2.15098 4.08813 2.22357 4.12503 2.308C4.16687 2.40374 4.16687 2.52055 4.16687 2.75417L4.16687 8.56985C4.16687 8.77852 4.16687 8.88285 4.14788 8.94977C4.0694 9.22632 3.77445 9.3799 3.50289 9.28562C3.43717 9.26281 3.35169 9.20298 3.18074 9.08333Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4005 15.5457C12.544 16.4053 12.1158 16.8351 11.6622 17.0799C10.6248 17.6396 9.3754 17.6396 8.33805 17.0798C7.88449 16.8351 7.45626 16.4053 6.59981 15.5457C6.1738 15.1181 5.9608 14.9043 5.78714 14.6701C5.39401 14.1399 5.13819 13.5207 5.04248 12.8677C5.0002 12.5792 5.0002 12.2774 5.0002 11.6738L5.0002 10.9196L5.0002 3.48172C5.0002 1.54872 6.56721 -0.018283 8.5002 -0.0182829L8.75018 -0.0182828C9.09537 -0.0182828 9.3752 0.261552 9.3752 0.606744L9.3752 2.48185C9.3752 2.82703 9.65503 3.10685 10.0002 3.10685C10.3454 3.10685 10.6252 2.82703 10.6252 2.48185L10.6252 0.606746C10.6252 0.261552 10.905 -0.0182807 11.2502 -0.0182807L11.5002 -0.0182807C13.4332 -0.0182805 15.0002 1.54872 15.0002 3.48172L15.0002 11.6737C15.0002 12.2774 15.0002 12.5792 14.9579 12.8677C14.8622 13.5207 14.6064 14.1399 14.2132 14.6701C14.0396 14.9043 13.8265 15.1181 13.4005 15.5457ZM10.0002 9.77352C9.07973 9.77352 8.33354 10.5197 8.33354 11.4402C8.33354 12.3607 9.07973 13.1069 10.0002 13.1069C10.9207 13.1069 11.6669 12.3607 11.6669 11.4402C11.6669 10.5197 10.9207 9.77352 10.0002 9.77352Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_181_8697">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default DeBoost;
