import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allSalaries: [],
  salarySubset: [],
  salarySetFilter: [],
  salarySetCompare: [],
  progressionSetFilter: [],
  progressionSetCompare: [],
  compareDistribution: false,
  compareProgression: false,
  options: [],
  progression: [],
  bottomSheetOpen: false,
  filterOptions: [],
  loading: true,
  primaryTab: true,
  filterCount: 0,
  filterCompanyCount: 0,
  filterJobFunctionCount: 0,
  filterLocationCount: 0,
  filterCompanyStaged: [],
  filterLocation: [],
  compareCompanyStaged: [],
  compareLocation: [],
  radioCompanyStaged: [],
  radioJobFunction: [],
  radioLocation: [],
  radioCompareCompanyStage: [],
  radioCompareJobFunction: [],
  radioCompareLocation: [],
  progressionCount: 0,
  radioCompanyCount: 0,
  radioJobFunctionCount: 0,
  radioLocationCount: 0,
  isNativeWebView: false,
  isHideSalaryHeader: false,
  jobFunctions: [],
  progressionSetOne: [],
  progressionSetTwo: [],
  progressionSetThree: [],
  distributionSetOne: [],
  distributionSetTwo: [],
  distributionSetThree: [],
  defaultLegend: '',
  legendOne: '',
  legendTwo: '',
  legendThree: '',
  experienceFilters: [],
  salaryFilters: [],
  experienceCount: 0,
  salaryCount: 0,
  highlightSalary: false,
  currentSalaryId: '',
  currentSalaryData: [],
  selectedQueue: [],
  yourSalary: [],
  compareSalary: [],
  showLoader: false,
  salaryBottomsheetOpen: false,
  companyStageFlag: true,
  selectedJobFunction: 'Software Engineering'
};

const salariesSlice = createSlice({
  name: 'salaryData',
  initialState,
  reducers: {
    setAllSalaryData(state, action) {
      state.allSalaries = action.payload;
    },
    setSalaryData(state, action) {
      state.salarySubset = action.payload;
    },
    setSalarySetFilter(state, action) {
      state.salarySetFilter = action.payload;
    },
    setChartOption(state, action) {
      state.options = action.payload;
    },
    setChartProgression(state, action) {
      state.progression = action.payload;
    },
    setChartLoading(state, action) {
      state.loading = action.payload;
    },
    setPrimaryTab(state, action) {
      state.primaryTab = action.payload;
    },
    setFilterOptions(state, action) {
      state.filterOptions = action.payload;
    },
    setFilterCount(state, action) {
      state.filterCount = action.payload;
    },
    setFilterCompanyCount(state, action) {
      state.filterCompanyCount = action.payload;
    },
    setFilterCompanyStage(state, action) {
      state.filterCompanyStaged = action.payload;
    },
    toggleFilterCompanyStage: (state, action) => {
      const { id, checked } = action.payload;
      const itemIndex = state.filterCompanyStaged.findIndex(
        item => item.id === id
      );

      if (itemIndex !== -1) {
        if (checked) {
          if (state.selectedQueue.length >= 3) {
            const earliestSelectedIndex = state.selectedQueue.shift();
            state.filterCompanyStaged[earliestSelectedIndex].checked = false;
          }

          state.selectedQueue.push(itemIndex);
          state.filterCompanyStaged[itemIndex].checked = true;
        } else {
          const indexToRemove = state.selectedQueue.indexOf(itemIndex);
          if (indexToRemove !== -1) {
            state.selectedQueue.splice(indexToRemove, 1);
          }

          state.filterCompanyStaged[itemIndex].checked = false;
        }
      }
    },
    toggleFilterCompanyAllCheckboxes(state, action) {
      const allChecked = state.filterCompanyStaged.every(item => item.checked);
      state.filterCompanyStaged.forEach(item => {
        item.checked = !allChecked;
      });
    },
    setFilterJobFunctionCount(state, action) {
      state.filterJobFunctionCount = action.payload;
    },
    setFilterLocationCount(state, action) {
      state.filterLocationCount = action.payload;
    },
    setJobFunctions(state, action) {
      state.jobFunctions = action.payload;
    },
    setBigTechCheckByDefault(state, action) {
      const itemIndex = state.filterCompanyStaged.findIndex(
        item => item.id === action.payload
      );
      if (itemIndex !== -1) {
        state.filterCompanyStaged[itemIndex].checked = true;
        state.selectedQueue.push(itemIndex);
      }
    },
    setSoftwareEngineerCheckByDefault(state, action) {
      const itemIndex = state.jobFunctions.findIndex(
        item => item.id === action.payload
      );
      if (itemIndex !== -1) {
        state.jobFunctions[itemIndex].checked = true;
      }
    },
    toggleFilterFunctionCheckbox: (state, action) => {
      const { id, checked } = action.payload;
      const itemIndex = state.filterJobFunction.findIndex(
        item => item.id === id
      );
      if (itemIndex !== -1) {
        state.filterJobFunction[itemIndex].checked = checked;
      }
    },
    toggleFilterFunctionAllCheckboxes(state, action) {
      const allChecked = state.filterJobFunction.every(item => item.checked);
      state.filterJobFunction.forEach(item => {
        item.checked = !allChecked;
      });
    },
    setFilterLocation(state, action) {
      state.filterLocation = action.payload;
    },
    toggleFilterLocation(state, action) {
      const { id, checked } = action.payload;
      const itemIndex = state.filterLocation.findIndex(item => item.id === id);
      if (itemIndex !== -1) {
        state.filterLocation[itemIndex].checked = checked;
      }
    },
    toggleFilterLocationAll(state, action) {
      const allChecked = state.filterLocation.every(item => item.checked);
      state.filterLocation.forEach(item => {
        item.checked = !allChecked;
      });
    },
    setRadioCompanyStaged(state, action) {
      state.radioCompanyStaged = action.payload;
    },
    toggleFilterRadioCompanyStaged(state, action) {
      const { id } = action.payload;
      state.radioCompanyStaged.forEach(radio => {
        radio.checked = radio.id === id;
      });
    },
    setRadioJobFunction(state, action) {
      state.radioJobFunction = action.payload;
    },
    toggleJobFunction(state, action) {
      const { id } = action.payload;
      state.jobFunctions.forEach(radio => {
        radio.checked = radio.id === id;
      });
    },
    setRadioLocation(state, action) {
      state.radioLocation = action.payload;
    },
    toggleFilterRadioLocation(state, action) {
      const { id } = action.payload;
      state.radioLocation.forEach(radio => {
        radio.checked = radio.id === id;
      });
    },
    setProgressionCount(state, action) {
      state.progressionCount = action.payload;
    },
    setRadioCompanyCount(state, action) {
      state.radioCompanyCount = action.payload;
    },
    setRadioJobFunctionCount(state, action) {
      state.radioJobFunctionCount = action.payload;
    },
    setRadioLocationCount(state, action) {
      state.radioLocationCount = action.payload;
    },
    setProgressionSetFilter(state, action) {
      state.progressionSetFilter = action.payload;
    },
    setIsNativeWebView(state, action) {
      state.isNativeWebView = action.payload;
    },
    setIsHideSalaryHeader(state, action) {
      state.isHideSalaryHeader = action.payload;
    },
    setProgressionSetOne(state, action) {
      state.progressionSetOne = action.payload;
    },
    setProgressionSetTwo(state, action) {
      state.progressionSetTwo = action.payload;
    },
    setProgressionSetThree(state, action) {
      state.progressionSetThree = action.payload;
    },
    setDistributionSetOne(state, action) {
      state.distributionSetOne = action.payload;
    },
    setDistributionSetTwo(state, action) {
      state.distributionSetTwo = action.payload;
    },
    setDistributionSetThree(state, action) {
      state.distributionSetThree = action.payload;
    },
    setExperienceFilters(state, action) {
      state.experienceFilters = action.payload;
    },
    setSalaryFilters(state, action) {
      state.salaryFilters = action.payload;
    },
    toggleExperienceFilters(state, action) {
      const { index, checked } = action.payload;
      state.experienceFilters[index].checked = !checked;
    },
    toggleSalaryFilters(state, action) {
      const { index, checked } = action.payload;
      state.salaryFilters[index].checked = !checked;
    },
    setSalaryCount(state, action) {
      state.salaryCount = action.payload;
    },
    setExperienceCount(state, action) {
      state.experienceCount = action.payload;
    },
    setHighlightSalary(state, action) {
      state.highlightSalary = action.payload;
    },
    setCurrentSalaryId(state, action) {
      state.currentSalaryId = action.payload;
    },
    setDefaultLegend(state, action) {
      state.defaultLegend = action.payload;
    },
    setLegendOne(state, action) {
      state.legendOne = action.payload;
    },
    setLegendTwo(state, action) {
      state.legendTwo = action.payload;
    },
    setLegendThree(state, action) {
      state.legendThree = action.payload;
    },
    setSelectedQueue(state, action) {
      state.selectedQueue = action.payload;
    },
    setCurrentSalaryData(state, action) {
      state.currentSalaryData = action.payload;
    },
    setBelowAverageLegend(state, action) {
      state.belowAverageLegend = action.payload;
    },
    setAverageLegend(state, action) {
      state.averageLegend = action.payload;
    },
    setAboveAverageLegend(state, action) {
      state.aboveAverageLegend = action.payload;
    },
    setYourSalary(state, action) {
      state.yourSalary = action.payload;
    },
    setShowLoader(state, action) {
      state.showLoader = action.payload;
    },
    setSalaryBottomsheetOpen(state, action) {
      state.salaryBottomsheetOpen = action.payload;
    },
    setCompanyStageFlag(state, action) {
      state.companyStageFlag = action.payload;
    },
    setSelectedJobFunction(state, action) {
      state.selectedJobFunction = action.payload;
    }
  }
});

export const {
  setAllSalaryData,
  setSalaryData,
  setSalarySetFilter,
  setChartLoading,
  setFilterOptions,
  setFilterCompanyCount,
  setFilterJobFunctionCount,
  setFilterLocationCount,
  setFilterCount,
  toggleFilterLocation,
  toggleFilterLocationAll,
  toggleFilterCompanyStage,
  setFilterCompanyStage,
  setBigTechCheckByDefault,
  toggleCompareLocation,
  setPrimaryTab,
  toggleFilterRadioLocation,
  toggleCompareRadioLocation,
  setProgressionCount,
  setRadioCompanyCount,
  setRadioLocationCount,
  setProgressionSetFilter,
  setSoftwareEngineerCheckByDefault,
  setIsNativeWebView,
  toggleJobFunction,
  setJobFunctions,
  setProgressionSetOne,
  setProgressionSetTwo,
  setProgressionSetThree,
  setDistributionSetOne,
  setDistributionSetTwo,
  setDistributionSetThree,
  setExperienceFilters,
  setSalaryFilters,
  toggleExperienceFilters,
  toggleSalaryFilters,
  setSalaryCount,
  setExperienceCount,
  setHighlightSalary,
  setCurrentSalaryId,
  setLegendOne,
  setLegendTwo,
  setLegendThree,
  setCurrentSalaryData,
  setDefaultLegend,
  setYourSalary,
  setShowLoader,
  setSalaryBottomsheetOpen,
  setCompanyStageFlag,
  setSelectedJobFunction,
  setIsHideSalaryHeader
} = salariesSlice.actions;
export default salariesSlice;
