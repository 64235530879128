import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { getCommunityAPI } from '../store/community/action';
import {
  communityListToObj,
  loadingCommunityState
} from '../store/community/reducer';
import classes from './style.module.css';
import Image from 'next/image';
import { AppContext } from '../context/AppProvider';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ROUTE, formatStringInURL } from '../utils';
import { styled } from '@mui/material/styles';
import { clearPostData } from '../store/home/Reducer';
import { CompanyPost, Home } from '../assets/Icons';
import { colors } from '../themes';

const LeftSide = props => {
  const { isSalaryPage, pageProps } = props;

  useEffect(() => {
    getCommunityAPI();
  }, []);

  const { isAuthenticated } = useContext(AppContext);

  return (
    <section
      className={`bg-white dark:bg-darkGrey-10 rounded-[12px] h-full grid border-grey-20 dark:border-darkGrey-20 border ${classes?.community_slider_tab}`}
    >
      <div className="px-6 pt-6 flex flex-col gap-3">
        <Navigation />
        <div className="w-full h-[1px] bg-grey-20 dark:bg-darkGrey-20 my-3" />
      </div>

      <div className="px-6 h-full overflow-auto hide-scroll-bar">
        <AccordionList pageProps={pageProps} />
      </div>

      {!isAuthenticated && <ExploreCompany />}
    </section>
  );
};

export default LeftSide;

const Navigation = () => {
  const router = useRouter();

  const { theme } = useContext(AppContext);
  const isDarkMode = theme === 'dark';

  const uiFlags = useSelector(state => state?.uiFlags?.uiFLags?.data);

  return (
    <Fragment>
      <Link href={ROUTE.HOME}>
        <div
          className={`flex gap-2 py-2 cursor-pointer text-btn text-grey-70 dark:text-darkGrey-70 ${classes?.home_feed_text_btn}`}
        >
          <Home
            stroke={isDarkMode ? colors.darkGrey[70] : undefined}
            isActive={router.pathname === ROUTE.HOME}
          />
          <span
            className={`title-large-MB  ${
              router.pathname === ROUTE.HOME ? 'text-Primary' : ''
            }`}
          >
            Home Feed
          </span>
        </div>
      </Link>

      {uiFlags?.intra_company_feed?.show_intra_company_tab &&
        uiFlags?.is_company_email_verified && (
          <Link href={`${ROUTE.COMPANY_DETAIL}`}>
            <div
              className={`flex gap-2 py-2 cursor-pointer text-btn text-grey-70 dark:text-darkGrey-70 ${classes?.home_feed_text_btn}`}
            >
              <CompanyPost
                fill={
                  router.pathname === ROUTE.COMPANY_DETAIL
                    ? colors.Primary.DEFAULT
                    : isDarkMode
                    ? colors.darkGrey[70]
                    : undefined
                }
                isActive={router.pathname === ROUTE.COMPANY_DETAIL}
              />
              <span
                className={`title-large-MB  ${
                  router.pathname === ROUTE.COMPANY_DETAIL ? 'text-Primary' : ''
                }`}
              >
                My Company
              </span>
            </div>
          </Link>
        )}
    </Fragment>
  );
};

const ExploreCompany = () => {
  return (
    <div className="py-4 px-6 border-t-solid border-grey-20 dark:border-darkGrey-20">
      <Link href={`/${ROUTE.COMPANY_LIST}`}>
        <span className="title-smallest-MB font-normal text-grey-60 dark:text-darkGrey-60 underline">
          Explore Companies
        </span>
      </Link>
    </div>
  );
};

const AccordionList = ({ pageProps }) => {
  const { state, data, error } = useSelector(
    state => state?.community?.communityList
  );

  const [selectedAccordion, setSelectedAccordion] = useState(undefined);

  const onHandleAccordionChange = (val, isExpanded) => {
    setSelectedAccordion(!isExpanded ? undefined : val);
  };

  useEffect(() => {
    if (state === loadingCommunityState.loaded) {
      setSelectedAccordion(Object.keys(data)[0]);
    }
  }, [state]);

  const communityObj = communityListToObj(pageProps?.communityData);

  const accordionObjValue =
    communityObj && Object.keys(communityObj).length ? communityObj : data;

  if (!accordionObjValue || !Object.keys(accordionObjValue).length) {
    return <div />;
  }

  return (
    <div className="h-full">
      {Object?.entries(accordionObjValue)?.map(([groupName, groupItems]) => (
        <Accordion
          expanded={selectedAccordion === groupName}
          key={groupName}
          onChange={(event, isExpanded) =>
            onHandleAccordionChange(groupName, isExpanded)
          }
        >
          <AccordionSummary
            aria-controls={`panel-${groupName}-content`}
            id={`panel-${groupName}-header`}
          >
            <span className="title-large-MB">{groupName}</span>
          </AccordionSummary>
          <AccordionDetails>
            {groupItems.map((item, index) => (
              <Tile data={item} key={item?.id} />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

const defaultProfileImg =
  'https://stagvinecip01.blob.core.windows.net/stc-gvine-blob-centralindia-prod-001-django/images/community/64709ca3-cfa9-4a3c-beae-d22c596e3c19/profile/20240222060353.png';

const Tile = ({ data }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const onLinkClicked = e => {
    if (router.query?.community_id === data?.display_name) {
      dispatch(clearPostData({}));
      router.back();
      return;
    }

    if (router.query?.community_id) {
      router.replace(
        `${ROUTE.COMMUNITY_POST}/${formatStringInURL(data?.display_name)}`
      );
      return;
    }

    router.push(
      `${ROUTE.COMMUNITY_POST}/${formatStringInURL(data?.display_name)}`
    );
  };

  return (
    <Link
      replace
      href={`${ROUTE.COMMUNITY_POST}/${formatStringInURL(data?.display_name)}`}
      className={`flex items-center gap-2 py-[8px] px-[10px] cursor-pointer hover:text-Primary ${
        router.query?.community_id === formatStringInURL(data?.display_name)
          ? 'bg-Primary-10 dark:bg-PrimaryDark-10 text-Primary-40 dark:text-PrimaryDark-40  rounded-[12px] border border-Primary-20 dark:border-PrimaryDark-20'
          : ''
      }`}
      onClick={onLinkClicked}
    >
      <Image
        src={data?.profile_image || defaultProfileImg}
        alt={`community-img-${data?.display_name}`}
        width={32}
        height={32}
      />
      <span className="title-medium-MB font-medium">{data?.display_name}</span>
    </Link>
  );
};

// ? custom components from material UI

const Accordion = styled(props => (
  <MuiAccordion disableGutters square elevation={0} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#0E0E0E' : '#FFFFFF',

  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#3C3C3C' }} />
    }
    {...props}
  />
))(({ theme }) => ({
  padding: '0px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)'
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? '#CECECE' : '#3C3C3C'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(270deg)'
  },
  '& .MuiAccordionSummary-content': {
    margin: '8px 0px'
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0
}));
