import * as React from 'react';

const Delete = ({ w, h, color, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 5.00024L4.64564 15.9761C4.77037 18.0965 4.83273 19.1566 5.28528 19.9612C5.68366 20.6695 6.28832 21.2396 7.01876 21.5957C7.84851 22.0002 8.91054 22.0002 11.0346 22.0002H12.9654C15.0895 22.0002 16.1515 22.0002 16.9812 21.5957C17.7117 21.2396 18.3163 20.6695 18.7147 19.9612C19.1673 19.1566 19.2296 18.0965 19.3544 15.9761L20 5.00024M4 5.00024H2M4 5.00024H20M20 5.00024H22M16 5.00024L15.547 3.6413C15.3503 3.05118 15.252 2.75612 15.0695 2.53798C14.9084 2.34534 14.7016 2.19624 14.4679 2.10433C14.2032 2.00024 13.8922 2.00024 13.2702 2.00024H10.7298C10.1078 2.00024 9.79677 2.00024 9.53213 2.10433C9.29844 2.19624 9.09156 2.34534 8.93047 2.53798C8.74804 2.75612 8.64969 3.05118 8.45298 3.6413L8 5.00024M10 10.0002V17.0002M14 10.0002V14.0002"
      stroke="#D93939"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Delete;
