import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  community: [],
  height: 0
};

const discoverSlice = createSlice({
  name: 'discoverData',
  initialState,
  reducers: {
    setCommunityList(state, action) {
      state.community = action.payload;
    },
    setSHeight(state, action) {
      state.height = action.payload;
    }
  }
});

export const { setCommunityList, setSHeight } = discoverSlice.actions;
export default discoverSlice;
