import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notif: [],
  height: 0
};

const notifSlice = createSlice({
  name: 'notifData',
  initialState,
  reducers: {
    setNotifList(state, action) {
      state.notif = action.payload;
    },
    setSHeight(state, action) {
      state.height = action.payload;
    }
  }
});

export const { setNotifList, setSHeight } = notifSlice.actions;
export default notifSlice;
