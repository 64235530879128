import React, { useContext, useEffect } from 'react';
import { AppContext } from '../context/AppProvider';
import { useSelector } from 'react-redux';
import PrimaryButton from './buttons/primaryButton';
import { getTrendingPostList } from '../store/trendingPost/Action';
import classes from './style.module.css';
import Link from 'next/link';
import { formatTitle, ROUTE } from '../utils';
import Image from 'next/image';
import images from '../assets/images';

const RightSide = ({ pageProps }) => {
  const context = useContext(AppContext);
  const { isAuthenticated } = useSelector(state => state.login);

  const postId = pageProps?.data?.id;

  useEffect(() => {
    getTrendingPostList(postId);
  }, [postId]);

  const _isLoginClick = () => {
    context.setCreatePostModal(true);
  };

  return (
    <div className="bg-white h-fit dark:bg-darkGrey-10 rounded-[12px] grid p-[24px] border-grey-20  dark:border-darkGrey-20 border">
      {isAuthenticated && (
        <div>
          <PrimaryButton
            className="w-full"
            style={{ padding: '12px 16px' }}
            onClick={_isLoginClick}
          >
            Create a Post
          </PrimaryButton>
        </div>
      )}

      {/* {!isAuthenticated && (
        <label className="title-large-XL dark:text-darkGrey-70">
          Discover More
        </label>
      )}

      {!isAuthenticated && (
        <div className="h-full overflow-auto">
          <TrendingList pageProps={pageProps} />
        </div>
      )} */}

      {isAuthenticated && <div className="mb-[24px]" />}

      <Footer />
    </div>
  );
};

const Footer = () => {
  const { theme } = useContext(AppContext);
  const isDarkMode = theme === 'dark';
  const { isAuthenticated } = useSelector(state => state.login);

  return (
    <div
      className={`${
        isAuthenticated
          ? isDarkMode
            ? classes?.community_slider_footer_dark
            : classes?.community_slider_footer
          : ''
      } ${isAuthenticated ? 'pt-[24px]' : ''}`}
    >
      <span className="title-medium-MB font-normal text-grey-60 dark:text-darkGrey-60">
        Download the Grapevine app.
      </span>

      <div className="flex justify-between my-[12px] mb-[16px]">
        <a
          href={'https://play.google.com/store/apps/details?id=com.app.gvine'}
          rel="noreferrer"
          target="_blank"
        >
          <Image
            src={images.googlePlayBadge}
            height={35}
            className="object-contain"
            alt="google-play-badge"
          />
        </a>

        <a
          href={
            'https://apps.apple.com/in/app/grapevine-100k-professionals/id1641708426'
          }
          rel="noreferrer"
          target="_blank"
        >
          <Image src={images.appStoreBadge} height={35} alt="app-store-badge" />
        </a>
      </div>

      <div className="flex justify-between">
        <a
          href="mailto:support@gvine.app"
          className="heading-smaller-L font-medium text-grey-50 dark:text-darkGrey-60"
          target="_blank"
          rel="noreferrer"
        >
          Help & Support
        </a>

        <a
          href="mailto:support@gvine.app"
          className="heading-smaller-L font-semibold text-grey-60 underline dark:text-darkGrey-60"
          target="_blank"
          rel="noreferrer"
        >
          support@gvine.app
        </a>
      </div>
      <div className="flex justify-between mt-2">
        <a
          href="/privacy"
          className="heading-smaller-L font-medium text-grey-50 dark:text-darkGrey-60"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>

        <a
          href="/guidelines"
          className="heading-smaller-L font-medium text-grey-50 dark:text-darkGrey-60"
          target="_blank"
          rel="noreferrer"
        >
          Community Guidelines
        </a>
      </div>

      <div className="mt-[12px]">
        <span className="heading-small-R  text-grey-40 dark:text-darkGrey-40">
          Grapevine™ 2024, All rights reserved
        </span>
      </div>
    </div>
  );
};

const TrendingList = ({ pageProps }) => {
  const { state, data, error } = useSelector(
    state => state?.trendingPost?.trendingPostList
  );

  const { theme } = useContext(AppContext);
  const isDarkMode = theme === 'dark';

  const trendingList = pageProps?.trendingData?.length
    ? pageProps?.trendingData
    : data;

  return (
    <div className="flex flex-col mt-[4px] h-full">
      {trendingList?.map(e => (
        <Link
          key={e?.id}
          href={`${ROUTE?.POST_DETAILS}/${formatTitle(e?.title)}${e?.id}`}
          className={`${
            isDarkMode
              ? classes.trending_post_tile_dark
              : classes.trending_post_tile
          } text-grey-70 dark:text-darkGrey-60 heading-medium-S font-medium`}
        >
          {e?.title}
        </Link>
      ))}
    </div>
  );

  return <div></div>;
};

export default RightSide;
