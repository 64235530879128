import { get, getWithOutToken } from '../../services/ApiServices';
import { getError, showError } from '../../utils';
import { store } from '../store';
import { communityAction } from './reducer';

export const getCommunityAPI = async () => {
  try {
    const { isAuthenticated } = store.getState()?.login;
    store.dispatch(communityAction.getCommunityList({}));
    let res = {};

    if (!isAuthenticated) {
      res = await getWithOutToken('/unauthorized/autocomplete/community/', {
        limit: 100,
        offset: 0
      });
    } else {
      res = await get('autocomplete/community/', {
        limit: 100,
        offset: 0
      });
    }

    store.dispatch(communityAction.setCommunityList(res?.data?.data?.results));
  } catch (error) {
    store.dispatch(communityAction.errorCommunityList(getError(error)));
    showError(error);
  }
};
