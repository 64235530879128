/* eslint-disable complexity */
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppProvider';
import { AppConstant, LINKING } from '../../constants/AppConstant';
import * as track from '../../utils/MixpanelTrackEvent';

const Auth = ({
  value,
  invalidPhNo,
  onChange,
  onKeyDownCapture,
  onClick,
  btnColor
}) => {
  const context = useContext(AppContext);

  const [IsLight, setIsLight] = useState(false);

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    if (context?.freePostCheck > 1) {
      track.trackLoginOpenWithCount(context?.freePostCheck);
    } else {
      track.trackLoginOpen(context?.freePostCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  const _on_href = link => {
    window.open(link, '_blank');
    // location.href = link;
  };

  return (
    <>
      <div className="flex h-[45px] mt-[13px] mb-[8px] mx-[22px] bg-Neutral200 dark:bg-Neutral200Dark justify-center items-center rounded-[12px]">
        {context?.freePostCheck === 0 ? (
          <p className="title-medium-MB text-Warning">
            {AppConstant?.YOU_HAVE_NO_FREE_POST}
          </p>
        ) : (
          <p className="title-medium-MB text-Neutral800">
            {AppConstant?.YOU_HAVE}{' '}
            <span className="text-Primary">{context?.freePostCheck}</span>{' '}
            {AppConstant?.MORE_S} {AppConstant?.FREE_POST_FOR_DAY}
          </p>
        )}
      </div>
      <div className="mt-[32px] mx-[27px] h-[68px]">
        <p className="text-Neutral900 dark:text-Neutral900Dark heading-small-XXL text-center">
          {AppConstant?.LOGIN}
        </p>
        <p className="heading-medium-M text-Neutral800 mt-[10px] text-center">
          {AppConstant?.YOUR_NUM_IS}
        </p>
      </div>

      <div className="mx-[22px] mt-[45px]">
        <div className="flex h-[38px] items-center px-[10px] border-b-[1px] border-Primary">
          <p className="text-Neutral900 dark:text-Neutral900Dark title-extra-large-B">
            {AppConstant?.IN_CODE}
          </p>
          <input
            type={'tel'}
            maxLength={10}
            value={value}
            placeholder="enter your phone number"
            className="no-input-arrow title-extra-large-B h-[38px] pl-[10px] placeholder-Neutral500 dark:placeholder-Neutral500Dark text-Neutral900 dark:text-Neutral900Dark border-0 border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full bg-transparent dark:border-gray-600 outline-none appearance-none"
            onChange={onChange}
            onKeyDownCapture={onKeyDownCapture}
          />
        </div>

        <div className="mt-[12px] h-[56px]">
          {invalidPhNo && (
            <p className="title-small-B text-Warning">
              {AppConstant?.INVALID_PHONE_NUMBER}
            </p>
          )}
          <p className="heading-small-R text-Neutral800 mt-[15px]">
            {AppConstant?.BY_ENTERING_YOUR_NUM}{' '}
            <span
              className="text-Action cursor-pointer"
              onClick={() => {
                _on_href(LINKING?.TERMS_CONDITION);
                track.trackLoginTermTap();
              }}
            >
              {AppConstant?.TERM_CONDITION}
            </span>{' '}
            {AppConstant?.AND_SIGN}
            <span
              className="text-Action cursor-pointer"
              onClick={() => {
                _on_href(LINKING?.PRIVACY_POLICY);
                track.trackLoginPrivacyTap();
              }}
            >
              {AppConstant?.PRIVACY_POLICY_}
            </span>
          </p>
        </div>
      </div>

      <div className="flex mt-[182px] justify-center">
        <button
          className="bg-Primary flex h-[53px] px-[45px] rounded-full items-center mb-[30px]"
          style={btnColor}
          onClick={onClick}
        >
          <p className="title-large-B text-White">{AppConstant?.CONTINUE}</p>
        </button>
      </div>
    </>
  );
};

export default Auth;
