import Home from './Home';
import HomeFilled from './HomeFilled';
import Search from './Search';
import Discover from './Discover';
import Notification from './Notification';
import Profile from './Profile';
import Jobs from './Jobs';
import More from './More';
import ChevronDown from './ChevronDown';
import ChevronDownDark from './ChevronDownDark';
import PlusCircle from './PlusCircle';
import Vector from './Vector';
import PlusWhite from './PlusWhite';
import QR from './QR';
import Like from './Like';
import Comment from './Comment';
import Share from './Share';
import ShareSmall from './ShareSmall';
import Verified from './Verified';
import LinkShare from './LinkShare';
import MoreVertical from './MoreVertical';
import SavePost from './SavePost';
import ReportPost from './ReportPost';
import ReportUser from './ReportUser';
import BlockUser from './BlockUser';
import Trash from './Trash';
import Rocket from './Rocket';
import DeBoost from './DeBoost';
import ShadowBanPost from './ShadowBanPost';
import Stars from './Stars';
import BookMark from './BookMark';
import Reply from './Reply';
import Back from './Back';
import Filter from './Filter';
import Fire from './Fire';
import New from './New';
import Watch from './Watch';
import Send from './Send';
import Link from './Link';
import AppTitle from './AppTitle';
import StarAction from './StarAction';
import AppStore from './AppStore';
import PlayStore from './PlayStore';
import ScanMode from './ScanMode';
import CopyLink from './CopyLink';
import Illustration from './Illustration';
import IllustrationDark from './IllustrationDark';
import Email from './Email';
import Logout from './Logout';
import Delete from './Delete';
import Slash from './Slash';
import Cross from './Cross';
import CircleCut from './CircleCut';
import TextIcon from './TextIcon';
import TextIconFilled from './TextIconFilled';
import PollIcon from './PollIcon';
import PollIconFilled from './PollIconFilled';
import ImageIcon from './ImageIcon';
import ImageIconFilled from './ImageIconFilled';
import LinkIcon from './LinkIcon';
import LinkIconFilled from './LinkIconFilled';
import Incognito from './Incognito';
import ArrowLeftCorner from './ArrowLeftCorner';
import Illustration404 from './Illustration404';
import Illustration404Dark from './Illustration404Dark';
import Check from './Check';
import DImage from './DImage';
import DPoll from './DPoll';
import DLink from './DLink';
import TypePopular from './TypePopular';
import TypeRecent from './TypeRecent';
import TypeWeek from './TypeWeek';
import TypeMonth from './TypeMonth';
import ArrowRightLong from './ArrowRightLong';
import Plus from './Plus';
import Warn from './Warn';
import CrossCircle from './CrossCircle';
import NoNotification from './NoNotification';
import NoCommunity from './NoCommunity';
import Salaries from './Salaries';
import FiftyGrapes from './FiftyGrapes';
import RightTickShield from './RightTickShield';
import Dot from './Dot';

const icons = {
  home: Home,
  homeFilled: HomeFilled,
  search: Search,
  discover: Discover,
  notification: Notification,
  profile: Profile,
  jobs: Jobs,
  more: More,
  chevronDown: ChevronDown,
  chevronDownDark: ChevronDownDark,
  PlusCircle: PlusCircle,
  vector: Vector,
  plusWhite: PlusWhite,
  qr: QR,
  like: Like,
  comment: Comment,
  share: Share,
  shareSmall: ShareSmall,
  reply: Reply,
  verified: Verified,
  linkShare: LinkShare,
  moreVertical: MoreVertical,
  savePost: SavePost,
  reportPost: ReportPost,
  reportUser: ReportUser,
  blockUser: BlockUser,
  trash: Trash,
  rocket: Rocket,
  deBoost: DeBoost,
  shadowBanPost: ShadowBanPost,
  stars: Stars,
  bookmark: BookMark,
  back: Back,
  filter: Filter,
  fire: Fire,
  new: New,
  watch: Watch,
  send: Send,
  link: Link,
  appTitle: AppTitle,
  starAction: StarAction,
  appStore: AppStore,
  playStore: PlayStore,
  scanMode: ScanMode,
  copyLink: CopyLink,
  illustration: Illustration,
  illustrationDark: IllustrationDark,
  email: Email,
  logout: Logout,
  delete: Delete,
  slash: Slash,
  cross: Cross,
  crossCircle: CrossCircle,
  circleCut: CircleCut,
  textIcon: TextIcon,
  textIconFilled: TextIconFilled,
  pollIcon: PollIcon,
  pollIconFilled: PollIconFilled,
  imageIcon: ImageIcon,
  imageIconFilled: ImageIconFilled,
  linkIcon: LinkIcon,
  linkIconFilled: LinkIconFilled,
  incognito: Incognito,
  arrowLeftCorner: ArrowLeftCorner,
  illustration404: Illustration404,
  illustration404Dark: Illustration404Dark,
  check: Check,
  dImage: DImage,
  dPoll: DPoll,
  dLink: DLink,
  typePopular: TypePopular,
  typeRecent: TypeRecent,
  typeWeek: TypeWeek,
  typeMonth: TypeMonth,
  arrowRightLong: ArrowRightLong,
  plus: Plus,
  warn: Warn,
  noNotification: NoNotification,
  noCommunity: NoCommunity,
  salaries: Salaries,
  fiftyGrapes: FiftyGrapes,
  rightTickShield: RightTickShield,
  dot: Dot
};

export default icons;
