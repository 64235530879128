import * as React from 'react';

const Home = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.7085 10.2917L4.38786 18.6885C4.75439 20.5212 4.93766 21.4375 5.41612 22.1229C5.83804 22.7274 6.41877 23.2035 7.0942 23.4987C7.86015 23.8334 8.79463 23.8334 10.6636 23.8334H15.3367C17.2057 23.8334 18.1402 23.8334 18.9061 23.4987C19.5816 23.2035 20.1623 22.7274 20.5842 22.1229C21.0627 21.4375 21.2459 20.5212 21.6125 18.6885L23.2918 10.2917M1.0835 11.9167L8.47468 4.52548C10.0587 2.94142 10.8508 2.14938 11.7641 1.85263C12.5675 1.5916 13.4329 1.5916 14.2362 1.85263C15.1495 2.14938 15.9416 2.94142 17.5256 4.52548L24.9168 11.9167M16.2502 13C16.2502 14.7949 14.7951 16.25 13.0002 16.25C11.2052 16.25 9.75016 14.7949 9.75016 13C9.75016 11.2051 11.2052 9.75 13.0002 9.75C14.7951 9.75 16.2502 11.2051 16.2502 13Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Home;
