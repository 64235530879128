import * as React from 'react';

const Slash = ({ w, h, color, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 19.0002L5 5.00024M22 12.0002C22 17.5231 17.5228 22.0002 12 22.0002C6.47715 22.0002 2 17.5231 2 12.0002C2 6.4774 6.47715 2.00024 12 2.00024C17.5228 2.00024 22 6.4774 22 12.0002Z"
      stroke="#D93939"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Slash;
