import React, { Fragment } from 'react';
import Header from './Header';
import { ROUTE } from '../utils';
import SalaryLayout from './layouts/SalaryLayout';
import BaseLayout from './layouts/BaseLayout';
import AddSalaryLayout from './layouts/AddSalaryLayout';
import { useRouter } from 'next/router';
import classes from './style.module.css';

const Layout = ({ children, pageProps }) => {
  const { pathname } = useRouter();

  const renderLayout = () => {
    switch (pathname) {
      case ROUTE.COMMUNITY_GUIDELINES:
        return (
          <Fragment>
            <Header />
            <AddSalaryLayout>{children}</AddSalaryLayout>
          </Fragment>
        );
      case ROUTE.SALARY:
        return (
          <Fragment>
            <AddSalaryLayout>{children}</AddSalaryLayout>
          </Fragment>
        );
      case ROUTE.SALARIES:
        return (
          <Fragment>
            <div className="hidden lg:block">
              <Header />
            </div>{' '}
            <SalaryLayout>{children}</SalaryLayout>
          </Fragment>
        );
      case ROUTE.ADD_SALARY:
        return (
          <Fragment>
            <AddSalaryLayout>{children}</AddSalaryLayout>
          </Fragment>
        );
      case ROUTE.SALARY_CARD:
        return (
          <Fragment>
            <AddSalaryLayout>{children}</AddSalaryLayout>
          </Fragment>
        );
      case ROUTE.SALARY_DISCLAIMER:
        return (
          <Fragment>
            <AddSalaryLayout>{children}</AddSalaryLayout>
          </Fragment>
        );
      case `${ROUTE.SHARE_SALARY}/[...data]`:
        return (
          <Fragment>
            <AddSalaryLayout>{children}</AddSalaryLayout>
          </Fragment>
        );
      case ROUTE.GRAPES:
        return (
          <Fragment>
            <AddSalaryLayout>{children}</AddSalaryLayout>
          </Fragment>
        );
      case `${ROUTE.POST_DETAILS}/[...data]`:
        return (
          <Fragment>
            <Header pageProps={pageProps} />
            <BaseLayout pageProps={pageProps}> {children} </BaseLayout>
          </Fragment>
        );

      case '/':
        return children;

      default:
        return (
          <Fragment>
            <Header />
            <BaseLayout pageProps={pageProps}>{children}</BaseLayout>
          </Fragment>
        );
    }
  };

  return (
    <div className={`grid h-full ${classes?.parent_container}`}>
      {renderLayout()}
    </div>
  );
};

export default Layout;
