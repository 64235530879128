import { getWithOutToken } from '../../services/ApiServices';
import { getError, showError } from '../../utils';
import { store } from '../store';
import { trendingPostAction } from './Reducer';

export const getTrendingPostList = async postId => {
  try {
    store.dispatch(trendingPostAction.getTrendingPostList({}));

    let url = '/unauthorized/trending/';
    if (postId) {
      url += `?post_id=${postId}`;
    }

    const res = await getWithOutToken(url);

    store.dispatch(trendingPostAction.setTrendingPostList(res?.data?.data));
  } catch (error) {
    store.dispatch(trendingPostAction.errorTrendingPostList(getError(error)));
    showError(error);
  }
};
