import * as React from 'react';

const TextIconFilled = ({ w, h, color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.98005 1.79497C5.04961 1.25 6.44974 1.25 9.25 1.25L14.75 1.25C17.5503 1.25 18.9504 1.25 20.02 1.79497C20.9608 2.27433 21.7257 3.03924 22.205 3.98005C22.75 5.0496 22.75 6.44974 22.75 9.25V14.75V14.75C22.75 17.5503 22.75 18.9504 22.205 20.02C21.7257 20.9608 20.9608 21.7257 20.02 22.205C18.9504 22.75 17.5503 22.75 14.75 22.75H9.25C6.44974 22.75 5.04961 22.75 3.98005 22.205C3.03924 21.7257 2.27434 20.9608 1.79497 20.02C1.25 18.9504 1.25 17.5503 1.25 14.75L1.25 9.25C1.25 6.44974 1.25 5.04961 1.79497 3.98005C2.27433 3.03924 3.03924 2.27434 3.98005 1.79497ZM7.00009 6.25015C6.58588 6.25015 6.25009 6.58594 6.25009 7.00015C6.25009 7.41437 6.58588 7.75015 7.00009 7.75015L17.0001 7.75015C17.4143 7.75015 17.7501 7.41437 17.7501 7.00015C17.7501 6.58594 17.4143 6.25015 17.0001 6.25015L7.00009 6.25015ZM7.00009 12.7502L17.0001 12.7502C17.4143 12.7502 17.7501 12.4144 17.7501 12.0002C17.7501 11.5859 17.4143 11.2502 17.0001 11.2502L7.00009 11.2502C6.58588 11.2502 6.25009 11.5859 6.25009 12.0002C6.25009 12.4144 6.58588 12.7502 7.00009 12.7502ZM7.00009 16.2502C6.58588 16.2502 6.25009 16.5859 6.25009 17.0002C6.25009 17.4144 6.58588 17.7502 7.00009 17.7502L17.0001 17.7502C17.4143 17.7502 17.7501 17.4144 17.7501 17.0002C17.7501 16.5859 17.4143 16.2502 17.0001 16.2502L7.00009 16.2502Z"
      fill="#1E92FF"
    />
  </svg>
);
export default TextIconFilled;
