import * as React from 'react';

const Comment = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.33325 9.0835C6.33325 9.33663 6.12805 9.54183 5.87492 9.54183C5.62179 9.54183 5.41659 9.33663 5.41659 9.0835M6.33325 9.0835C6.33325 8.83037 6.12805 8.62516 5.87492 8.62516C5.62179 8.62516 5.41659 8.83037 5.41659 9.0835M6.33325 9.0835H5.41659M10.4583 9.0835C10.4583 9.33663 10.253 9.54183 9.99992 9.54183C9.74679 9.54183 9.54158 9.33663 9.54158 9.0835M10.4583 9.0835C10.4583 8.83037 10.253 8.62516 9.99992 8.62516C9.74679 8.62516 9.54158 8.83037 9.54158 9.0835M10.4583 9.0835H9.54158M14.5833 9.0835C14.5833 9.33663 14.378 9.54183 14.1249 9.54183C13.8718 9.54183 13.6666 9.33663 13.6666 9.0835M14.5833 9.0835C14.5833 8.83037 14.378 8.62516 14.1249 8.62516C13.8718 8.62516 13.6666 8.83037 13.6666 9.0835M14.5833 9.0835H13.6666M16.1567 18.2776V18.2776C16.7973 18.6619 17.1176 18.8541 17.3724 18.9051C18.1023 19.0511 18.8289 18.6397 19.0792 17.9387C19.1666 17.694 19.1666 17.3205 19.1666 16.5734V8.8335C19.1666 6.03323 19.1666 4.6331 18.6216 3.56354C18.1423 2.62273 17.3773 1.85783 16.4365 1.37846C15.367 0.833496 13.9668 0.833496 11.1666 0.833496H8.83325C6.03299 0.833496 4.63286 0.833496 3.5633 1.37846C2.62249 1.85783 1.85759 2.62273 1.37822 3.56354C0.833252 4.6331 0.833252 6.03323 0.833252 8.8335V9.3335C0.833252 12.1338 0.833252 13.5339 1.37822 14.6034C1.85759 15.5443 2.62249 16.3092 3.5633 16.7885C4.63286 17.3335 6.03299 17.3335 8.83325 17.3335H12.7483C13.1948 17.3335 13.418 17.3335 13.6368 17.3528C14.2441 17.4062 14.8366 17.5703 15.3849 17.837C15.5825 17.933 15.7739 18.0479 16.1567 18.2776Z"
      stroke="#8B8B8B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Comment;
