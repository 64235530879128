import * as React from 'react';

const TypeWeek = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_577_72815)">
      <path
        d="M10.3327 1.66699L9.66601 1.66699C6.86575 1.66699 5.46562 1.66699 4.39606 2.21196C3.45525 2.69133 2.69035 3.45623 2.21098 4.39704C1.66602 5.4666 1.66602 6.86673 1.66602 9.667L1.66602 10.3337C1.66602 13.1339 1.66602 14.5341 2.21099 15.6036C2.69035 16.5444 3.45526 17.3093 4.39607 17.7887C5.46562 18.3337 6.86576 18.3337 9.66602 18.3337L10.3327 18.3337C13.1329 18.3337 14.5331 18.3337 15.6026 17.7887C16.5434 17.3093 17.3084 16.5444 17.7877 15.6036C18.3327 14.534 18.3327 13.1339 18.3327 10.3337L18.3327 9.66699C18.3327 6.86673 18.3327 5.4666 17.7877 4.39704C17.3083 3.45623 16.5434 2.69132 15.6026 2.21196C14.5331 1.66699 13.1329 1.66699 10.3327 1.66699Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.31185 13.2028C5.09796 13.2028 4.94518 13.1295 4.85352 12.9828C4.76185 12.8362 4.77102 12.6589 4.88102 12.4512L7.43852 7.64782L4.67935 7.64783C4.33713 7.64783 4.16602 7.48588 4.16602 7.16199C4.16602 6.83199 4.33713 6.66699 4.67935 6.66699L8.22685 6.66699C8.39185 6.66699 8.52629 6.70977 8.63018 6.79532C8.73407 6.88088 8.78602 7.0031 8.78602 7.16199C8.78602 7.29644 8.76768 7.4156 8.73102 7.51949C8.69435 7.61727 8.64546 7.72421 8.58435 7.84032L5.98102 12.8178C5.84657 13.0745 5.62352 13.2028 5.31185 13.2028Z"
        fill={color}
      />
      <path
        d="M10.5608 13.1295C10.1452 13.1295 9.93744 12.9217 9.93744 12.5062L9.93744 7.29032C9.93744 6.87477 10.1452 6.66699 10.5608 6.66699L12.3391 6.66699C13.4208 6.66699 14.2549 6.94505 14.8416 7.50116C15.4344 8.05727 15.7308 8.85477 15.7308 9.89366C15.7308 10.9264 15.4344 11.7239 14.8416 12.2862C14.2549 12.8484 13.4208 13.1295 12.3391 13.1295L10.5608 13.1295ZM11.1108 12.1578L12.2658 12.1578C13.7569 12.1578 14.5024 11.4031 14.5024 9.89366C14.5024 8.39032 13.7569 7.63866 12.2658 7.63866L11.1108 7.63866L11.1108 12.1578Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_577_72815">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default TypeWeek;
