import { MixpanelConstants } from '../constants/MixpanelConstants';
import MixpanelInstance from './mixpanel';

// // auth
// export const trackAppIntro = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_INTRO);
// };

// export const trackWelcomeTap = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_WELCOME_SUBMIT);
// };

// export const trackLoginSubmitTap = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_process.env.NEXT_PUBLIC_MIXPANEL_TOKENLOGIN_SUBMIT);
// };

// export const trackSetJoinUniversityOpen = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_SET_UNIVERSITY);
// };

// export const trackSetJoinUniversitySubmitTap = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_SET_UNIVERSITY_SUBMIT);
// };

// export const trackFeedSearchTap = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_SEARCH_ICON_TAP);
// };

// export const trackFeedHumBurgerTap = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_HAMBURGER_ICON_TAP);
// };

// // New Member
// export const trackFeedViewNewMember = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_NEW_MEMBER);
// };

// export const trackFeedNewMemberSwipe = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_NEW_MEMBER_SWIPE);
// };

// export const trackFeedNewMemberFullSwipe = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_NEW_MEMBER_FULL_SWIPE);
// };

// export const trackFeedNewMemberTap = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_NEW_MEMBER_MORE_TAP);
// };

// export const trackFeedJobWaitingBtnTap = username => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_JOB_WAITING_LIST_TAP, {
//     [MixpanelConstants.KEY_USERNAME]: username
//   });
// };

// export const trackNewMemberCardTap = (
//   postId,
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   source
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_NEW_MEMBER_CARD_TAP, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_SOURCE]: source
//   });
// };

// export const trackNewMemberCardWave = (
//   postId,
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   source
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_NEW_MEMBER_CARD_WAVE, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_SOURCE]: source
//   });
// };

// export const trackNewMemberCardDelete = (
//   postId,
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   source
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_NEW_MEMBER_CARD_DELETE, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_SOURCE]: source
//   });
// };

// export const trackNewMemberCardReport = (
//   postId,
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   source
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_NEW_MEMBER_CARD_REPORT, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_SOURCE]: source
//   });
// };

// // post
// export const trackPostDetailTap = (
//   postId,
//   postType,
//   source,
//   communityName,
//   companyId = '',
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   postViewType
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_TAP, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType
//   });
// };

// export const trackPostCommunityTap = (
//   postId,
//   postType,
//   communityName,
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   postViewType,
//   level
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_COMMUNITY_TAP, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
//     [MixpanelConstants.KEY_LEVEL]: level
//   });
// };

// export const trackPostLikeTap = (
//   postId,
//   postType,
//   source,
//   level,
//   communityName,
//   companyId = '',
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   userType,
//   postViewType
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_LIKE_ICON_TAP, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_LEVEL]: level,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_USERTYPE]: userType,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType
//   });
// };

// export const trackPostCommentTap = (
//   postId,
//   postType,
//   source,
//   communityName,
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   companyName,
//   level
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_COMMENT_ICON_TAP, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_COMPANY_NAME]: companyName,
//     [MixpanelConstants.KEY_LEVEL]: level
//   });
// };

// export const trackPostShareTap = (
//   postId,
//   postType,
//   source,
//   communityName,
//   companyId = '',
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   postViewType,
//   isShowWhatsappIcon,
//   level
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_SHARE, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
//     [MixpanelConstants.KEY_SHOW_WHATSAPP_ICON_SHARE]: isShowWhatsappIcon,
//     [MixpanelConstants.KEY_LEVEL]: level
//   });
// };

// export const trackScroll10Posts = (source, postViewType) => {
//   MixpanelInstance()?.track(MixpanelConstants.EVENT_POST_10_VIEW, {
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType
//   });
// };

// export const trackPostOptionTap = (
//   postId,
//   postType,
//   source,
//   level,
//   communityName,
//   companyId = '',
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   postViewType
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_OPTIONS_ICON_TAP, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_LEVEL]: level,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType
//   });
// };

// export const trackPostBanTap = (
//   postId,
//   postType,
//   source,
//   level,
//   communityName,
//   companyId = '',
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   actionTaker
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_BAN, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_LEVEL]: level,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_USERNAME_ACTION_TAKER]: actionTaker
//   });
// };

// export const trackPostDeleteTap = (
//   postId,
//   postType,
//   source,
//   level,
//   communityName,
//   companyId = '',
//   username,
//   creationDate,
//   likeCount,
//   commentCount
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_DELETE, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_LEVEL]: level,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount
//   });
// };

// export const trackPostReportTap = (
//   postId,
//   postType,
//   source,
//   level,
//   communityName,
//   companyId = '',
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   actionTaker
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_REPORT, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_LEVEL]: level,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_USERNAME_ACTION_TAKER]: actionTaker
//   });
// };

// export const trackPostDeleteWarnTap = (
//   postId,
//   postType,
//   source,
//   level,
//   communityName,
//   companyId = '',
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   actionTaker
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_DELETE_WARN, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_LEVEL]: level,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_USERNAME_ACTION_TAKER]: actionTaker
//   });
// };

// export const trackDetailPostOpen = (
//   type,
//   commentId,
//   postId,
//   postType,
//   communityName,
//   username,
//   creationDate,
//   likeCount,
//   postViewType,
//   isShowWhatsappIcon
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_POST_L2, {
//     [MixpanelConstants.KEY_TYPE]: type,
//     [MixpanelConstants.KEY_COMMUNITY_ID]: commentId,
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
//     [MixpanelConstants.KEY_SHOW_WHATSAPP_ICON_SHARE]: isShowWhatsappIcon
//   });
// };

// export const trackCommentBanTap = (
//   commentId,
//   postId,
//   postType,
//   communityName,
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   actionTaker
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_COMMENT_BAN, {
//     [MixpanelConstants.KEY_COMMUNITY_ID]: commentId,
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKES_COMMENTS]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_USERNAME_ACTION_TAKER]: actionTaker
//   });
// };

// export const trackCreatePostOpen = postType => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_CREATE, {
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postType
//   });
// };

// export const trackCreatePostSelectCommunityOpen = postType => {
//   MixpanelManager.trackEvent(
//     MixpanelConstants.EVENT_VIEW_CREATE_POST_SELECT_COMMUNITY,
//     {
//       [MixpanelConstants.KEY_POST_VIEW_TYPE]: postType
//     }
//   );
// };

// export const trackCreatePostTagCommuintyOpen = postType => {
//   MixpanelManager.trackEvent(
//     MixpanelConstants.EVENT_VIEW_CREATE_POST_TAG_COMMUNITY,
//     {
//       [MixpanelConstants.KEY_POST_VIEW_TYPE]: postType
//     }
//   );
// };

// export const trackCreatePost = (
//   postId,
//   postType,
//   username,
//   createrType,
//   communityName,
//   creatorCompany,
//   tag
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_CREATED, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_CREATOR_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATOR_TYPE]: createrType,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_CREATOR_COMPANY]: creatorCompany,
//     [MixpanelConstants.KEY_TAG]: tag
//   });
// };

// //poll
// export const trackPostPollAns = (
//   postId,
//   source,
//   communityName,
//   username,
//   creationDate,
//   likeCount,
//   commentCount,
//   postViewType,
//   level
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POLL_ANSWER, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
//     [MixpanelConstants.KEY_LEVEL]: level
//   });
// };

// // Profile
// export const trackProfileOpen = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_PROFILE_SCREEN);
// };

// export const trackProfileEdit = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_EDIT_PROFILE);
// };

// // Community
// export const trackCommunityOpen = (
//   communityId,
//   isShowWhatsappIcon,
//   communityName
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_COMMUNITY_SCREEN, {
//     [MixpanelConstants.KEY_COMMUNITY_ID]: communityId,
//     [MixpanelConstants.KEY_SHOW_WHATSAPP_ICON_SHARE]: isShowWhatsappIcon,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName
//   });
// };

// export const trackCommunityShareTap = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_COMMUNITY_SHARE);
// };

// export const trackCommunityJoinTap = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_COMMUNITY_JOIN);
// };

// export const trackCommunityLeaveTap = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_COMMUNITY_LEAVE);
// };

// export const trackCommunityTagFilter = (communityName, tagName) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_COMMUNITY_TAG_FILTER, {
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_TAG_NAME]: tagName
//   });
// };

// // Search
// export const trackSearch = searchText => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_SEARCH, {
//     [MixpanelConstants.KEY_SEARCH_TEXT]: searchText
//   });
// };

// // SnackBar
// export const trackSnackBarView = (snackbarText, screenName) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_SNACKBAR_DISPLAYED, {
//     [MixpanelConstants.KEY_SNACKBAR_TEXT]: snackbarText,
//     [MixpanelConstants.KEY_SCREEN_NAME]: screenName
//   });
// };

// export const trackNotificationAppOpen = (type, parentName) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_NOTIFICATION_APP_OPEN, {
//     [MixpanelConstants.KEY_TYPE]: type,
//     [MixpanelConstants.KEY_PARENT_NAME]: parentName
//   });
// };

// export const trackNotificationDelivered = (type, parentName) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_NOTIFICATION_DELIVERED, {
//     [MixpanelConstants.KEY_TYPE]: type,
//     [MixpanelConstants.KEY_PARENT_NAME]: parentName
//   });
// };

// //verification
// export const trackViewVerificationPopup = (source, companyName) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_VERIFICATION_POPUP, {
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyName
//   });
// };
// export const trackViewVerificationPopupContinue = (source, companyName) => {
//   MixpanelManager.trackEvent(
//     MixpanelConstants.EVENT_VERIFICATION_POPUP_CONTINUE,
//     {
//       [MixpanelConstants.KEY_SOURCE]: source,
//       [MixpanelConstants.KEY_COMPANY_ID]: companyName
//     }
//   );
// };
// export const trackViewVerificationPopupNotNow = (source, companyName) => {
//   MixpanelManager.trackEvent(
//     MixpanelConstants.EVENT_VERIFICATION_POPUP_NOT_NOW,
//     {
//       [MixpanelConstants.KEY_SOURCE]: source,
//       [MixpanelConstants.KEY_COMPANY_ID]: companyName
//     }
//   );
// };
// export const trackViewVerificationEmail = (source, companyName) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_VERIFICATION_EMAIL, {
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyName
//   });
// };
// export const trackViewVerificationEmailSubmit = (source, companyName) => {
//   MixpanelManager.trackEvent(
//     MixpanelConstants.EVENT_VERIFICATION_EMAIL_SUBMIT_TAP,
//     {
//       [MixpanelConstants.KEY_SOURCE]: source,
//       [MixpanelConstants.KEY_COMPANY_ID]: companyName
//     }
//   );
// };
// export const trackViewVerificationEmailError = (source, companyName, error) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VERIFICATION_EMAIL_ERROR, {
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyName,
//     [MixpanelConstants.KEY_ERROR]: error
//   });
// };
// export const trackViewVerificationEmailDismiss = (source, companyName) => {
//   MixpanelManager.trackEvent(
//     MixpanelConstants.EVENT_VERIFICATION_EMAIL_DISMISS,
//     {
//       [MixpanelConstants.KEY_SOURCE]: source,
//       [MixpanelConstants.KEY_COMPANY_ID]: companyName
//     }
//   );
// };
// export const trackViewVerificationOtp = (source, companyName) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_VERIFICATION_OTP, {
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyName
//   });
// };
// export const trackViewVerificationOtpSubmitTap = (source, companyName) => {
//   MixpanelManager.trackEvent(
//     MixpanelConstants.EVENT_VERIFICATION_OTP_SUBMIT_TAP,
//     {
//       [MixpanelConstants.KEY_SOURCE]: source,
//       [MixpanelConstants.KEY_COMPANY_ID]: companyName
//     }
//   );
// };
// export const trackViewVerificationOtpresendTap = (source, companyName) => {
//   MixpanelManager.trackEvent(
//     MixpanelConstants.EVENT_VERIFICATION_OTP_RESEND_TAP,
//     {
//       [MixpanelConstants.KEY_SOURCE]: source,
//       [MixpanelConstants.KEY_COMPANY_ID]: companyName
//     }
//   );
// };
// export const trackViewVerificationOtpDismiss = (source, companyName) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VERIFICATION_OTP_DISMISS, {
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyName
//   });
// };
// export const trackUserVerified = (source, companyName) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_USER_VERIFIED, {
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyName
//   });
// };
// export const trackViewVerificationFABTap = (source, companyName) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VERIFICATION_FAB_TAP, {
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMPANY_ID]: companyName
//   });
// };

// // Sort event
// export const trackFeedSort = type => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_SORT, {
//     [MixpanelConstants.KEY_POST_SORT]: type
//   });
// };

// //company events
// export const trackViewComapnyFeed = (companyId = '', isShowWhatsappIcon) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_COMAPNY_FEED, {
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//     [MixpanelConstants.KEY_SHOW_WHATSAPP_ICON_SHARE]: isShowWhatsappIcon
//   });
// };
// export const trackViewHomeFeed = isShowWhatsappIcon => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_HOME_FEED, {
//     [MixpanelConstants.KEY_SHOW_WHATSAPP_ICON_SHARE]: isShowWhatsappIcon
//   });
// };
// export const trackViewComapnyInvite = (companyId = '', numberOfMember) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_COMPANY_INVITE, {
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//     [MixpanelConstants.KEY_NO_OF_MEMBER_REQUIRED]: numberOfMember
//   });
// };
// export const trackViewShareColleagues = (companyId = '', numberOfMember) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_SHARE_COLLEAGUES, {
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//     [MixpanelConstants.KEY_NO_OF_MEMBER_REQUIRED]: numberOfMember
//   });
// };
// export const trackInviteCoworkersTap = (companyId = '') => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_INVITE_COWORKERS_TAP, {
//     [MixpanelConstants.KEY_COMPANY_ID]: companyId
//   });
// };
// export const trackViewCompanyVerification = (companyId = '', userName) => {
//   MixpanelManager.trackEvent(
//     MixpanelConstants.EVENT_VIEW_COMAPNY_VERIFICATION,
//     {
//       [MixpanelConstants.KEY_COMPANY_ID]: companyId,
//       [MixpanelConstants.KEY_USERNAME]: userName
//     }
//   );
// };

// // ------

// export const trackSignupStart = (signupType, referralUser, referralSource) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_SIGN_UP_START, {
//     [MixpanelConstants.KEY_SIGNUP_TYPE]: signupType,
//     [MixpanelConstants.KEY_REFERRAL_USER]: referralUser,
//     [MixpanelConstants.KEY_REFERRAL_SOURCE]: referralSource
//   });
// };

// export const trackSignup = (email, username, facebookId) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_SIGN_UP, {
//     [MixpanelConstants.RESERVED_KEY_EMAIL]: email,
//     [MixpanelConstants.RESERVED_KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_FACEBOOK_ID]: facebookId
//   });
// };

// export const trackProfileSetup = user => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_PROFILE_SET_UP, {
//     [MixpanelConstants.RESERVED_KEY_NAME]: user?.name,
//     [MixpanelConstants.KEY_BIRTHYEAR]: user?.meta?.dob,
//     [MixpanelConstants.KEY_GENDER]: user?.meta?.gender
//   });
// };

// export const trackAppOpen = user => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_APP_OPEN);
// };

// export const trackPostCtaTap = (
//   postId,
//   postType,
//   source,
//   communityName,
//   userName,
//   createdDate,
//   likeCount,
//   commentCount,
//   postViewType,
//   companyName = ''
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_CTA_TAP, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: userName,
//     [MixpanelConstants.KEY_CREATION_DATE]: createdDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
//     [MixpanelConstants.KEY_COMPANY_NAME]: companyName
//   });
// };

// export const trackPostUsernameTap = (
//   postId,
//   postType,
//   source,
//   communityName,
//   userName,
//   createdDate,
//   likeCount,
//   commentCount,
//   postViewType,
//   selectedPostUsername
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_USERNAME_TAP, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_SOURCE]: source,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: userName,
//     [MixpanelConstants.KEY_CREATION_DATE]: createdDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: selectedPostUsername
//   });
// };

// export const trackSearchResultTap = (serialNumber, postId, communityName) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_SEARCH_RESULT_TAP, {
//     [MixpanelConstants.KEY_SEARCH_SERIAL_NUMBER]: serialNumber,
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName
//   });
// };

// export const trackThemeEvent = theme => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_THEME, {
//     [MixpanelConstants.KEY_THEME]: theme
//   });
// };

// export const trackTackScreenShortEvent = (screenName, isShowWhatsappIcon) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_SCREEN_SHORT, {
//     [MixpanelConstants.KEY_SCREEN_NAME]: screenName,
//     [MixpanelConstants.KEY_SHOW_WHATSAPP_ICON_SHARE]: isShowWhatsappIcon
//   });
// };
// export const trackSeeRateUsDialogueEvent = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_RATE_US_SHOWN);
// };

// export const trackRatingEvent = rate => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_RATE_US_RATING, {
//     [MixpanelConstants.EVENT_RATING]: rate
//   });
// };

// export const trackRateUsButtonEvent = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_RATE_US_BUTTON);
// };

// export const trackFeedBackButtonEvent = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_RATE_US_FEED_BACK_BUTTON);
// };

// export const trackLessThenNoThanksButtonEvent = rate => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_RATE_US_LESS_THEN_THREE, {
//     [MixpanelConstants.EVENT_RATING]: rate
//   });
// };

// export const trackMoreThenNoThanksButtonEvent = rate => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_RATE_US_MORE_THEN_THREE, {
//     [MixpanelConstants.EVENT_RATING]: rate
//   });
// };

// export const trackShowForcedUpdateDialogueEvent = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_FORCED_UPDATE_SHOWN);
// };

// export const trackShowForcedUpdateDownloadEvent = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_FORCED_UPDATE__DOWNLOAD);
// };
// export const trackShowWhatsNewUpdateEvent = version => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_WHATS_NEW_SHOWN, {
//     [MixpanelConstants.KEY_VERSION_NUMBER]: version
//   });
// };

// export const trackShowWhatsNewUpdateDismissEvent = version => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_WHATS_NEW_SHOWN, {
//     [MixpanelConstants.KEY_VERSION_NUMBER]: version
//   });
// };

// export const trackTapOnPostBellIcon = (
//   postId,
//   type,
//   communityName,
//   userName,
//   creationDate,
//   likeCount,
//   postViewType,
//   profile
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_NOTIFICATION_BELL_TAP, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_TYPE]: type,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: userName,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKES_COMMENTS]: likeCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
//     [MixpanelConstants.KEY_PROFILE]: profile
//   });
// };
// export const trackPostNotificationOn = (
//   postId,
//   type,
//   communityName,
//   userName,
//   creationDate,
//   likeCount,
//   postViewType,
//   profile
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_NOTIFICATION_ON, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_TYPE]: type,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: userName,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKES_COMMENTS]: likeCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
//     [MixpanelConstants.KEY_PROFILE]: profile
//   });
// };
// export const trackPostNotificationOff = (
//   postId,
//   type,
//   communityName,
//   userName,
//   creationDate,
//   likeCount,
//   postViewType,
//   profile
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_NOTIFICATION_OFF, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_TYPE]: type,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: userName,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKES_COMMENTS]: likeCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
//     [MixpanelConstants.KEY_PROFILE]: profile
//   });
// };

// export const trackUsernameTapL2 = (
//   postId,
//   type,
//   communityName,
//   userName,
//   creationDate,
//   likeCount,
//   postViewType,
//   profile
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_POST_L2_USERNAME_TAP, {
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_TYPE]: type,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: userName,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKES_COMMENTS]: likeCount,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
//     [MixpanelConstants.KEY_PROFILE]: profile
//   });
// };

// export const trackPostDetailShareNudgeDismiss = (
//   postId,
//   postType,
//   communityName,
//   userName,
//   creationDate,
//   likeCount
// ) => {
//   MixpanelManager.trackEvent(
//     MixpanelConstants.EVENT_POST_L2_SHARE_NUDGE_DISMISS,
//     {
//       [MixpanelConstants.KEY_POST_ID]: postId,
//       [MixpanelConstants.KEY_POST_TYPE]: postType,
//       [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//       [MixpanelConstants.KEY_USERNAME]: userName,
//       [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//       [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount
//     }
//   );
// };

// export const trackViewBookmark = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_BOOKMARK);
// };
// export const trackappOnBookmark = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_BOOKMARK_TAPPED);
// };
// export const trackViewJobs = () => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_VIEW_JOBS);
// };

// // bottom sheet comment
// export const trackExpandCommentBar = (
//   expandAction,
//   type,
//   commentId,
//   postId,
//   postType,
//   communityName,
//   username,
//   creationDate,
//   likeCount,
//   userType,
//   postViewType
// ) => {
//   MixpanelManager.trackEvent(MixpanelConstants.EVENT_COMMENT_BAR_EXPAND, {
//     [MixpanelConstants.KEY_EXPAND_ACTION]: expandAction,
//     [MixpanelConstants.KEY_TYPE]: type,
//     [MixpanelConstants.KEY_COMMENT_ID]: commentId,
//     [MixpanelConstants.KEY_POST_ID]: postId,
//     [MixpanelConstants.KEY_POST_TYPE]: postType,
//     [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
//     [MixpanelConstants.KEY_USERNAME]: username,
//     [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
//     [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
//     [MixpanelConstants.KEY_USERTYPE]: userType,
//     [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType
//   });
// };

// web ---------->
// home
export const trackFeedOpen = (
  postViewType,
  isCompanyShow,
  isShowWhatsappIcon,
  isAuthenticated
) => {
  MixpanelInstance()?.track(MixpanelConstants?.EVENT_VIEW_HOME, {
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_COMPANY]: isCompanyShow,
    [MixpanelConstants.KEY_SHOW_WHATSAPP_ICON_SHARE]: isShowWhatsappIcon,
    [MixpanelConstants.KEY_IS_WEBSITE]: true,
    [MixpanelConstants.KEY_IS_AUTHENTICATED]: isAuthenticated
  });
};

export const trackHomeSortTap = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_HOME_SORT_TAP, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackHomeSorted = type => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_HOME_SORTED, {
    [MixpanelConstants.KEY_SORT_ALONG_NAME]: type,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackScroll10Posts = (source, postViewType) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_POST_10_VIEW, {
    [MixpanelConstants.KEY_SOURCE]: source,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType
  });
};

export const trackDetailPostOpen = (
  communityId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount,
  postViewType,
  isShowWhatsappIcon,
  isAuthenticated
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_POST_L2, {
    // [MixpanelConstants.KEY_TYPE]: type,
    [MixpanelConstants.KEY_COMMUNITY_ID]: communityId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_SHOW_WHATSAPP_ICON_SHARE]: isShowWhatsappIcon,
    [MixpanelConstants.KEY_IS_WEBSITE]: true,
    [MixpanelConstants.KEY_IS_AUTHENTICATED]: isAuthenticated
  });
};

export const trackAddCommentIconTap = (
  type,
  commentId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount,
  userType,
  postViewType
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_ADD_COMMENT_START, {
    [MixpanelConstants.KEY_TYPE]: type,
    [MixpanelConstants.KEY_COMMENT_ID]: commentId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_USERTYPE]: userType,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackAddComment = (
  type,
  commentId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount,
  userType,
  postViewType,
  mentions
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_ADD_COMMENT, {
    [MixpanelConstants.KEY_TYPE]: type,
    [MixpanelConstants.KEY_COMMENT_ID]: commentId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_USERTYPE]: userType,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_POST_MONTIONS]: mentions,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackOptionIconTap = (
  type,
  commentId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_COMMENT_OPTIONS_TAP, {
    [MixpanelConstants.KEY_TYPE]: type,
    [MixpanelConstants.KEY_COMMENT_ID]: commentId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKES_COMMENTS]: likeCount,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackCommentReportTap = (
  commentId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount,
  commentCount,
  actionTaker
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_COMMENT_REPORT, {
    [MixpanelConstants.KEY_COMMENT_ID]: commentId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKES_COMMENTS]: likeCount,
    [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
    [MixpanelConstants.KEY_USERNAME_ACTION_TAKER]: actionTaker,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackCommentDeleteTap = (
  commentId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount,
  commentCount,
  actionTaker
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_COMMENT_DELETE, {
    [MixpanelConstants.KEY_COMMENT_ID]: commentId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKES_COMMENTS]: likeCount,
    [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
    [MixpanelConstants.KEY_USERNAME_ACTION_TAKER]: actionTaker,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackCommentDeleteWarnTap = (
  commentId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount,
  commentCount,
  actionTaker
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_COMMENT_DELETE_WARN, {
    [MixpanelConstants.KEY_COMMUNITY_ID]: commentId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKES_COMMENTS]: likeCount,
    [MixpanelConstants.KEY_NUMBER_OF_COMMENTS]: commentCount,
    [MixpanelConstants.KEY_USERNAME_ACTION_TAKER]: actionTaker,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackShowUserMentionMenu = (
  type,
  commentId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount,
  userType,
  postViewType,
  mentions
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_TAG_MENU, {
    [MixpanelConstants.KEY_TYPE]: type,
    [MixpanelConstants.KEY_COMMENT_ID]: commentId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_USERTYPE]: userType,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_POST_MONTIONS]: mentions,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackSelectUserMentionMenu = (
  type,
  commentId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount,
  userType,
  postViewType,
  mentions
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_TAG_USER, {
    [MixpanelConstants.KEY_TYPE]: type,
    [MixpanelConstants.KEY_COMMENT_ID]: commentId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_USERTYPE]: userType,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_POST_MONTIONS]: mentions,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackPostDetailCommentSortTap = (
  postId,
  postType,
  communityName,
  userName,
  creationDate,
  likeCount
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_COMMENT_SORT_TAP, {
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: userName,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackPostDetailCommentsSorted = (
  sortName,
  postId,
  postType,
  communityName,
  userName,
  creationDate,
  likeCount
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_COMMENT_SORTED, {
    [MixpanelConstants.KEY_SORT_ALONG_NAME]: sortName,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: userName,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackPostDetailBookmarked = (
  postId,
  postType,
  communityName,
  userName,
  creationDate,
  likeCount,
  postViewType
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_BOOKMARKED, {
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: userName,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackPostDetailBookmarkRemoved = (
  postId,
  postType,
  communityName,
  userName,
  creationDate,
  likeCount,
  postViewType
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_BOOKMARK_REMOVED, {
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: userName,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackL2CommentShare = (
  type,
  commentId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount,
  userType,
  postViewType,
  mentions
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_COMMENT_SHARE, {
    [MixpanelConstants.KEY_TYPE]: type,
    [MixpanelConstants.KEY_COMMENT_ID]: commentId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_USERTYPE]: userType,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_POST_MONTIONS]: mentions,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackL2CommentReplyTap = (
  type,
  commentId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount,
  userType,
  postViewType,
  mentions
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_COMMENT_REPLY_TAP, {
    [MixpanelConstants.KEY_TYPE]: type,
    [MixpanelConstants.KEY_COMMENT_ID]: commentId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_USERTYPE]: userType,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_POST_MONTIONS]: mentions,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackL2CommentLike = (
  type,
  commentId,
  postId,
  postType,
  communityName,
  username,
  creationDate,
  likeCount,
  userType,
  postViewType,
  mentions
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_COMMENT_LIKE, {
    [MixpanelConstants.KEY_TYPE]: type,
    [MixpanelConstants.KEY_COMMENT_ID]: commentId,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_POST_TYPE]: postType,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: username,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKE]: likeCount,
    [MixpanelConstants.KEY_USERTYPE]: userType,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_POST_MONTIONS]: mentions,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackL2Comment5View = (screen = '') => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_COMMENT_5_VIEW, {
    [MixpanelConstants.KEY_SOURCE]: screen,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackLoginOpenWithCount = noOfPost => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_LOGIN, {
    [MixpanelConstants.KEY_NO_OF_POST_LEFT]: noOfPost,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackLoginOpen = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_LOGIN, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};
export const trackLoginSubmit = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_LOGIN_SUBMIT, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackLoginTermTap = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_LOGIN_TERM_TAP, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackLoginPrivacyTap = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_LOGIN_PRIVACY_TAP, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackOtpOpen = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_OTP, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackOtpSubmitTap = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_OTP_SUBMIT, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackSetUsernameOpen = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_SET_USERNAME, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

// export const trackSetUsernameSubmitTap = () => {
//   MixpanelInstance()?.track(MixpanelConstants.EVENT_SET_USERNAME_SUBMIT);
// };

export const trackSetUsernameSubmitTap = user => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_SET_USERNAME_SUBMIT, {
    [MixpanelConstants.KEY_USERNAME_OF_USER]: user,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackSetCompanynameOpen = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_SET_COMPANYNAME, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackSetCompanynameSubmitTap = company => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_SET_COMPANYNAME_SUBMIT, {
    [MixpanelConstants.KEY_COMPANY_NAME]: company,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackSetFieldWorkOpen = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_SET_FIELDWORK, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackSetFieldWorkSubmitTap = workfield => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_SET_FIELDWORK_SUBMIT, {
    [MixpanelConstants.KEY_WORK_FIELD]: workfield,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackSetBioOpen = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_SET_BIO, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackSetBioSubmitTap = bio => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_SET_BIO_SUBMIT, {
    [MixpanelConstants.KEY_BIO_OF_THE_USER]: bio,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackSetBioSkipTap = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_SET_BIO_SKIP_TAP, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackSetJoinCommunityOpen = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_SET_JOINCOMMUNITY, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackSetJoinCommunitySubmitTap = community => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_SET_JOINCOMMUNITY_SUBMIT, {
    [MixpanelConstants.KEY_COMMUNITIES_JOINED]: community,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackOtpResendTap = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_OTP_RESEND_SUBMIT, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackUsernameTapL2 = (
  postId,
  type,
  communityName,
  userName,
  creationDate,
  likeCount,
  postViewType,
  profile
) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_POST_L2_USERNAME_TAP, {
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_TYPE]: type,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_USERNAME]: userName,
    [MixpanelConstants.KEY_CREATION_DATE]: creationDate,
    [MixpanelConstants.KEY_NUMBER_OF_LIKES_COMMENTS]: likeCount,
    [MixpanelConstants.KEY_POST_VIEW_TYPE]: postViewType,
    [MixpanelConstants.KEY_PROFILE]: profile,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

// Profile
export const trackProfileOpen = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_PROFILE_SCREEN, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};
export const trackViewBookmark = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_BOOKMARK, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};
export const trackappOnBookmark = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_BOOKMARK_TAPPED, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

// Search
export const trackSearch = searchText => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_SEARCH, {
    [MixpanelConstants.KEY_SEARCH_TEXT]: searchText,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};
export const trackSearchResultTap = (serialNumber, postId, communityName) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_SEARCH_RESULT_TAP, {
    [MixpanelConstants.KEY_SEARCH_SERIAL_NUMBER]: serialNumber,
    [MixpanelConstants.KEY_POST_ID]: postId,
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

// Explore
export const trackExploreOpen = () => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_EXPLORE_SCREEN, {
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackCommunityTap = (communityName, communityId) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_EXPLORE_COMMUNITY_TAP, {
    [MixpanelConstants.KEY_COMMUNITY_NAME]: communityName,
    [MixpanelConstants.KEY_COMMUNITY_ID]: communityId,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

// Notification
export const trackNotificationOpen = count => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_VIEW_NOTIFICATIONS_SCREEN, {
    [MixpanelConstants.KEY_NUMBER_UNOPENED_NOTIFICATION]: count,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};

export const trackNotificationTap = (id, text) => {
  MixpanelInstance()?.track(MixpanelConstants.EVENT_NOTIFICATION_TAP, {
    [MixpanelConstants.KEY_NOTIFICATION_ID]: id,
    [MixpanelConstants.KEY_NOTIFICATION_TEXT]: text,
    [MixpanelConstants.KEY_IS_WEBSITE]: true
  });
};
