import * as React from 'react';

const PlayStore = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2476 6.5L4.74756 16.25M4.74756 2.75L12.2476 12.5M13.4641 12.2694L8.80087 14.9691C7.22489 15.8815 6.4369 16.3378 5.79017 16.2703C5.22607 16.2115 4.71347 15.916 4.37995 15.4573C3.99756 14.9313 3.99756 14.0208 3.99756 12.1998V6.80023C3.99756 4.97918 3.99756 4.06866 4.37995 3.54274C4.71347 3.08403 5.22607 2.78849 5.79017 2.72968C6.4369 2.66225 7.22489 3.11846 8.80087 4.03087L13.4641 6.73064C15.0344 7.63977 15.8196 8.09434 16.0833 8.6874C16.3133 9.20472 16.3133 9.79528 16.0833 10.3126C15.8196 10.9057 15.0344 11.3602 13.4641 12.2694Z"
      stroke="#1E92FF"
      strokeLinejoin="round"
    />
  </svg>
);
export default PlayStore;
