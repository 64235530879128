import * as React from 'react';

const CopyLink = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1227 6.5V6.5C14.4033 6.5 15.0435 6.5 15.5544 6.69228C16.363 6.9966 17.0011 7.6347 17.3054 8.44325C17.4977 8.95413 17.4977 9.59442 17.4977 10.875V12.2C17.4977 13.8802 17.4977 14.7202 17.1707 15.362C16.8831 15.9265 16.4241 16.3854 15.8597 16.673C15.2179 17 14.3778 17 12.6977 17H11.3727C10.0921 17 9.45181 17 8.94093 16.8077C8.13239 16.5034 7.49428 15.8653 7.18996 15.0568C6.99768 14.5459 6.99768 13.9056 6.99768 12.625V12.625M7.29768 12.5H8.19768C9.87784 12.5 10.7179 12.5 11.3597 12.173C11.9241 11.8854 12.3831 11.4265 12.6707 10.862C12.9977 10.2202 12.9977 9.38016 12.9977 7.7V6.8C12.9977 5.11984 12.9977 4.27976 12.6707 3.63803C12.3831 3.07354 11.9241 2.6146 11.3597 2.32698C10.7179 2 9.87784 2 8.19768 2H7.29768C5.61752 2 4.77744 2 4.13571 2.32698C3.57122 2.6146 3.11228 3.07354 2.82466 3.63803C2.49768 4.27976 2.49768 5.11984 2.49768 6.8V7.7C2.49768 9.38016 2.49768 10.2202 2.82466 10.862C3.11228 11.4265 3.57122 11.8854 4.13571 12.173C4.77744 12.5 5.61752 12.5 7.29768 12.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CopyLink;
