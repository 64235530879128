import * as React from 'react';

const ReportPost = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5619 2.16036C13.7094 2.84511 14.6434 4.51298 16.5115 7.84873C18.3072 11.0554 19.205 12.6587 19.2022 13.9691C19.1983 15.7712 18.225 17.4317 16.6546 18.3156C15.5127 18.9583 13.6751 18.9583 9.99986 18.9583C6.32465 18.9583 4.48705 18.9583 3.34507 18.3156C1.77466 17.4317 0.801376 15.7712 0.797492 13.9691C0.794667 12.6587 1.69253 11.0554 3.48824 7.84873L3.48824 7.84873C5.35626 4.51298 6.29027 2.84511 7.43786 2.16036C9.01606 1.21868 10.9837 1.21868 12.5619 2.16036ZM10.7498 7.50003C10.7498 7.08581 10.414 6.75003 9.99983 6.75003C9.58562 6.75003 9.24983 7.08581 9.24983 7.50003V10.8334C9.24983 11.2476 9.58562 11.5834 9.99983 11.5834C10.414 11.5834 10.7498 11.2476 10.7498 10.8334V7.50003ZM9.99983 12.5834C9.3555 12.5834 8.83316 13.1057 8.83316 13.75C8.83316 14.3944 9.3555 14.9167 9.99983 14.9167C10.6442 14.9167 11.1665 14.3944 11.1665 13.75C11.1665 13.1057 10.6442 12.5834 9.99983 12.5834Z"
      fill={color}
    />
  </svg>
);
export default ReportPost;
