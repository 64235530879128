import * as React from 'react';

const DImage = ({ w, h, color, ...props }) => (
  <svg
    width="22.8"
    height="22.8"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.45 17.7L7.03192 14.1181C7.77437 13.3756 8.1456 13.0044 8.57457 12.8629C8.95197 12.7384 9.35891 12.7354 9.73812 12.8543C10.1691 12.9895 10.5458 13.3552 11.2991 14.0866L14.3618 17.0601M21.025 17.4031L19.6715 16.0496C18.9191 15.2972 18.5428 14.921 18.109 14.78C17.7274 14.656 17.3164 14.656 16.9347 14.78C16.5009 14.921 16.1245 15.2973 15.3718 16.0501C14.7976 16.6243 14.3618 17.0601 14.3618 17.0601M18.175 20.8733L14.3618 17.0601M17.7 8.2C17.7 9.24934 16.8493 10.1 15.8 10.1C14.7507 10.1 13.9 9.24934 13.9 8.2C13.9 7.15066 14.7507 6.3 15.8 6.3C16.8493 6.3 17.7 7.15066 17.7 8.2ZM11.62 21.5H12.38C15.5723 21.5 17.1684 21.5 18.3877 20.8787C19.4603 20.3323 20.3323 19.4603 20.8787 18.3877C21.5 17.1684 21.5 15.5723 21.5 12.38V11.62C21.5 8.4277 21.5 6.83155 20.8787 5.61225C20.3323 4.53973 19.4603 3.66774 18.3877 3.12126C17.1684 2.5 15.5723 2.5 12.38 2.5H11.62C8.4277 2.5 6.83155 2.5 5.61225 3.12126C4.53973 3.66774 3.66774 4.53973 3.12126 5.61225C2.5 6.83155 2.5 8.4277 2.5 11.62V12.38C2.5 15.5723 2.5 17.1684 3.12126 18.3877C3.66774 19.4603 4.53973 20.3323 5.61225 20.8787C6.83155 21.5 8.4277 21.5 11.62 21.5Z"
      stroke={color}
      strokeWidth="1.425"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DImage;
