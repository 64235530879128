/* eslint-disable react/display-name */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import classes from './style.module.css';
import Image from 'next/image';
import icons from '../../assets/Icons';
import PrimaryButton from '../buttons/primaryButton';
import { useRouter } from 'next/router';
import Link from 'next/link';
import buildLink from '../../services/firebaseDynamicLink';
import { AppConstant } from '../../constants/AppConstant';
import { firebaseEvent } from '../../services/firebaseAnalytics';

const OpenAppModal = forwardRef((props, ref) => {
  const [isShow, setIsShow] = useState(false);
  const router = useRouter();
  const { query } = router;
  const [appLink, setAppLink] = useState('https://app.grapevine.in/join');

  const postShareLink = props?.pageProps?.data?.item?.share_link;

  const onLinkClick = e => {
    e.stopPropagation();
    firebaseEvent('mobile_web_open_app_footer_btn_clicked', {
      postId: query?.data?.length ? query?.data[0] : query?.post_id
    });
  };

  const show = () => {
    firebaseEvent('mobile_web_open_app_footer_shown', {
      postId: query?.data?.length ? query?.data[0] : query?.post_id
    });

    setIsShow(true);
  };

  const hide = () => {
    setIsShow(false);
  };

  const appOpenClick = async () => {
    if (query?.post_id) {
      let payload = {
        postId: props?.pageProps?.data?.id || query?.post_id,
        type: AppConstant?.POST
      };

      const link = await buildLink(payload, '', true);
      setAppLink(link);
      return;
    }

    if (query?.data?.length) {
      let payload = {
        postId: props?.pageProps?.data?.id || query?.data[0],
        type: AppConstant?.POST
      };

      const link = await buildLink(payload, '', true);
      setAppLink(link);
      return;
    }
    setAppLink('https://app.grapevine.in/join');
  };

  useEffect(() => {
    // if (isMobileDevice()) {
      appOpenClick();
    // }
  }, [query, postShareLink]);

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide
  }));

  if (!isShow) {
    return null;
  }
  

  return (
    <div
      className={classes.modalOverlay}
      onClick={e => {
        e.stopPropagation();
        hide();
      }}
    >
      <div className="bg-White dark:bg-darkGrey-10 px-6 py-8 rounded-t-[16px] flex flex-col">
        <span className="heading-small-XXL text-center text-grey-70 dark:text-grey-70">
          Get the full experience on the app
        </span>
        <p className="para-inter pt-[2px] text-center text-grey50 dark:text-darkGrey-50">
          Connect with your co-workers anonymously, get industry news, compare
          salaries, and more.
        </p>

        <section className="bg-primary10 dark:bg-Primary-10 p-4 mt-4 rounded-[12px] ">
          <div className="flex">
            <Image src={icons.appNewIcon} height={56} width={56} />
            <div className="ml-3 flex justify-center flex-col gap-2">
              <span className="title-large-XL text-grey-70 dark:text-grey-70">
                Grapevine: Work, Salary, News
              </span>
              <div className="flex gap-[10px]">
                <Image src={icons.starFull} height={14} width={14} />
                <Image src={icons.starFull} height={14} width={14} />
                <Image src={icons.starFull} height={14} width={14} />
                <Image src={icons.starFull} height={14} width={14} />
                <Image src={icons.starHalf} height={14} width={14} />
                <div className="flex items-center gap-1">
                  <span className="heading-medium-M text-grey-50 dark:text-grey-50">
                    4.6
                  </span>
                  <div className="h-[2px] w-[2px] bg-grey-60 dark:bg-grey-60"></div>
                  <span className="heading-medium-M text-grey-60 dark:text-grey-60">
                    Free
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 gap-1 flex items-center justify-center">
            <Image src={icons.upwardsBadge} height={17} width={17} />
            <span className="heading-medium-S text-grey-60 dark:text-grey-60">
              25k+ installs & 10k+ posts this week
            </span>
          </div>
        </section>

        <div className="flex justify-center">
          <div className="mt-[16px] w-fit">
            <Link
              // href={postShareLink || appLink}
              href={appLink}
              target="_blank"
              onClick={onLinkClick}
            >
              <PrimaryButton ariaLabel={'open in app'}>Open in App</PrimaryButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});

export default OpenAppModal;
