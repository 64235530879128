/* eslint-disable complexity */
import React, { useContext, useEffect, useRef, useState } from 'react';
import icons from '../../assets/leftSide';
import { colors } from '../../themes';
import { AppContext } from '../../context/AppProvider';
import Image from 'next/image';
import image from '../../assets/Icons';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import API_CONSTANT from '../../constants/ApiConstant';
import {
  getCommunityAPI,
  getJoinAPI,
  getLeaveAPI
} from '../../store/login/Action';
import { useRouter } from 'next/router';
import { AppConstant, ASYNC_KEY } from '../../constants/AppConstant';
import * as track from '../../utils/MixpanelTrackEvent';
import { toast } from 'react-toastify';
import { REDIRECT_URI, setInAsyncStorage } from '../../utils';

let page = -1;
let apiCall = false;

const Career = ({ btnColor }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const context = useContext(AppContext);

  const ref = useRef();

  const [IsLight, setIsLight] = useState(false);
  const [communityArr, setCommunityArr] = useState([]);
  const [communityName, setCommunityName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    track.trackSetJoinCommunityOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  useEffect(() => {
    setIsLoading(false);
    communityApiFun({
      pageCount: 0,
      offset: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const communityApiFun = ({ pageCount, offset }) => {
    apiCall = true;
    const limit = API_CONSTANT.LIMIT;
    const offsets = offset;
    const queryParams = `?limit=${limit}&offset=${offsets}&display_name=${''}`;

    getCommunityAPI(dispatch, queryParams)
      .then(res => {
        setIsLoading(false);
        if (res?.data?.data?.results?.length) {
          page = pageCount;
          setCommunityArr([...communityArr, ...res?.data?.data?.results]);
          const filter = res?.data?.data?.results.filter(e => e?.subscribed);
          if (filter.length) {
            setCommunityName([
              ...communityName,
              ...filter.map(e => e.display_name)
            ]);
          }
        }
      })
      .catch(err => {
        // showError(err);
      })
      .finally(() => {
        apiCall = false;
        setIsLoading(false);
      });
  };

  const onWheel = () => {
    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    if (scrollTop + clientHeight === scrollHeight && apiCall === false) {
      communityApiFun({
        pageCount: page + 1,
        offset: (page + 1) * 10
      });
    }
  };

  const _onCommunityClick = item => {
    let data = {
      community_id: item?.id
    };
    let API = !item?.subscribed ? getJoinAPI : getLeaveAPI;
    API(dispatch, data)
      .then(res => {
        const temp = [];
        if (!item?.subscribed) {
          temp.push(item.display_name);
        }
        setCommunityArr(e =>
          e.map(e => {
            if (e.id === item?.id) {
              return { ...e, subscribed: !item?.subscribed };
            } else {
              if (e?.subscribed) {
                temp.push(e?.display_name);
              }
              return e;
            }
          })
        );
        setCommunityName(temp);
      })
      .catch(err => {
        // showError(err);
      });
  };

  return (
    <>
      <div className="mt-[32px] mx-[27px]">
        <p className="text-Neutral900 dark:text-Neutral900Dark heading-small-XXL text-center">
          {AppConstant?.JOIN_COMMUNITY}
        </p>
      </div>

      <div className="flex mt-[22px] items-center">
        <p className="text-Neutral900 dark:text-Neutral900Dark title-large-B px-[22px] text-left w-full break-all overflow-hidden text-ellipsis whitespace-nowrap">
          {AppConstant?.CAREER_FIELDS}
        </p>
        <p className="text-Neutral800 title-medium-B px-[22px] text-left whitespace-nowrap">
          {AppConstant?.JOIN_ALL}
        </p>
      </div>

      <div
        className="mx-[22px] mt-[8px] max-h-auto sm:max-h-[337px] overflow-y-auto [&::-webkit-scrollbar]:hidden"
        ref={ref}
        onWheel={onWheel}
        onTouchEnd={onWheel}
      >
        {communityArr.map((d, i) => {
          return (
            <div
              key={i}
              className="flex items-center h-[66px] mb-[15px] bg-Neutral200 dark:bg-Neutral200Dark rounded-[12px]"
              onClick={() => _onCommunityClick(d)}
            >
              <Image
                priority
                className="w-[40px] h-[40px] rounded-full ml-[15px] mr-[10px]"
                src={image?.communitylogo}
                alt="img"
                width={40}
                height={40}
              />
              <p className="text-Neutral900 dark:text-Neutral900Dark title-large-B text-left w-full break-all overflow-hidden text-ellipsis whitespace-nowrap">
                {d?.display_name}
              </p>
              <div className="mr-[25px]">
                {d?.subscribed ? (
                  <icons.check w={23} h={23} />
                ) : (
                  <icons.PlusCircle w={23} h={23} color={colors.Action} />
                )}
              </div>
            </div>
          );
        })}
        {isLoading && (
          <div className="flex items-center justify-center h-[60px]">
            <CircularProgress sx={{}} />
          </div>
        )}
      </div>

      <div className="w-full text-center">
        <button
          className="bg-Primary h-[53px] mt-[15px] mb-[20px] px-[45px] rounded-full items-center"
          style={btnColor}
          onClick={() => {
            if (communityName?.length > 4) {
              setInAsyncStorage(ASYNC_KEY.LOGIN_FLOW, REDIRECT_URI.HOME_FEED);
              track.trackSetJoinCommunitySubmitTap(communityName?.join(', '));
              router?.reload();
            } else {
              // toast.error(AppConstant?.JOIN_COMMUNITY);
            }
          }}
        >
          <p className="title-large-B text-White">{AppConstant?.DONE}</p>
        </button>
      </div>
    </>
  );
};

export default Career;
