import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  post: [],
  comments: [],
  feed: '',
  height: 0,
  communityId: ''
};

const intraSlice = createSlice({
  name: 'intraData',
  initialState,
  reducers: {
    setIntraPostData(state, action) {
      state.post = action.payload;
    },
    setCommentData(state, action) {
      state.comments = action.payload;
    },
    setFeedType(state, action) {
      state.feed = action.payload;
    },
    setSHeight(state, action) {
      state.height = action.payload;
    },
    setCommunityId(state, action) {
      state.communityId = action.payload;
    }
  }
});

export const {
  setIntraPostData,
  setCommunityId,
  setCommentData,
  setFeedType,
  setSHeight
} = intraSlice.actions;
export default intraSlice;
