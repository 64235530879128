import * as React from 'react';

const MoreVertical = ({ w, h, color, ...props }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66667 5.16658C8.58333 5.16658 9.33333 4.41659 9.33333 3.49992C9.33333 2.58325 8.58333 1.83325 7.66667 1.83325C6.75 1.83325 6 2.58325 6 3.49992C6 4.41659 6.75 5.16658 7.66667 5.16658Z"
      fill="#8B8B8B"
    />
    <path
      d="M7.66667 6.83325C6.75 6.83325 6 7.58325 6 8.49992C6 9.41658 6.75 10.1666 7.66667 10.1666C8.58333 10.1666 9.33333 9.41658 9.33333 8.49992C9.33333 7.58325 8.58333 6.83325 7.66667 6.83325Z"
      fill="#8B8B8B"
    />
    <path
      d="M6 13.4999C6 12.5833 6.75 11.8333 7.66667 11.8333C8.58333 11.8333 9.33333 12.5833 9.33333 13.4999C9.33333 14.4166 8.58333 15.1666 7.66667 15.1666C6.75 15.1666 6 14.4166 6 13.4999Z"
      fill="#8B8B8B"
    />
  </svg>
);
export default MoreVertical;
