import { createSlice } from '@reduxjs/toolkit';

export const searchLoadingState = {
  initial: 'INITIAL',
  loading: 'LOADING',
  loaded: 'LOADED',
  error: 'ERROR',
  empty: 'EMPTY'
};

const initialState = {
  searchPost: [],
  height: 0,
  searchValue: '',
  searchState: searchLoadingState.initial
};

const searchPostSlice = createSlice({
  name: 'searchPostData',
  initialState,
  reducers: {
    setSearchPostList(state, action) {
      state.searchPost = action.payload;
    },
    setSHeight(state, action) {
      state.height = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    searchState(state, action) {
      state.searchState = action.payload;
    }
  }
});

export const { setSearchPostList, setSHeight, setSearchValue, searchState } =
  searchPostSlice.actions;
export default searchPostSlice;
