import * as React from 'react';

const Like = ({ w, h, color, className }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill={color?.fill}
      id="icon"
      d="M7.27195 1.47608C7.67228 0.975677 8.43337 0.975681 8.83369 1.47609L14.753 8.87531C15.2768 9.53007 14.8106 10.5 13.9721 10.5H11.0001L11.0002 16.5C11.0002 17.0523 10.5524 17.5 10.0002 17.5H6.00016C5.44787 17.5 5.00016 17.0523 5.00016 16.5L5.00012 10.5H2.13339C1.29488 10.5 0.828709 9.53006 1.35252 8.8753L7.27195 1.47608Z"
      stroke={color?.stroke}
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
  </svg>
);
export default Like;
