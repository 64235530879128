import * as React from 'react';

const Profile = ({ w, h, color, ...props }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 10C17.5 11.3807 16.3807 12.5 15 12.5C13.6193 12.5 12.5 11.3807 12.5 10C12.5 8.61929 13.6193 7.5 15 7.5C16.3807 7.5 17.5 8.61929 17.5 10Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6667 16.25H13.3333C11.4924 16.25 10 17.7424 10 19.5833C10 20.5038 10.7462 21.25 11.6667 21.25H18.3333C19.2538 21.25 20 20.5038 20 19.5833C20 17.7424 18.5076 16.25 16.6667 16.25Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Profile;
