import * as React from 'react';

const Search = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7501 21.7498L17.811 17.8108M17.811 17.8108C19.5754 16.0464 20.6667 13.6089 20.6667 10.9165C20.6667 5.53173 16.3015 1.1665 10.9167 1.1665C5.53197 1.1665 1.16675 5.53173 1.16675 10.9165C1.16675 16.3013 5.53197 20.6665 10.9167 20.6665C13.6091 20.6665 16.0466 19.5752 17.811 17.8108Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
export default Search;
