/* eslint-disable complexity */
import React, { useContext, useEffect, useState } from 'react';
import icons from '../../assets/leftSide';
import { colors } from '../../themes';
import { AppContext } from '../../context/AppProvider';
import { hasValue } from '../../utils';
import Image from 'next/image';
import image from '../../assets/Icons';
import { AppConstant } from '../../constants/AppConstant';
import * as track from '../../utils/MixpanelTrackEvent';

const User = ({
  randomUser,
  onSelectUser,
  value,
  checkUserName,
  onChange,
  onClick,
  btnColor
}) => {
  const context = useContext(AppContext);

  const [IsLight, setIsLight] = useState(false);
  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    track.trackSetUsernameOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  return (
    <>
      <div className="flex items-center justify-center mt-[51px]">
        <Image
          priority
          src={image.dummyUser}
          alt={'logo'}
          className="w-[83.29px] h-[84px]"
        />
        <div className="mx-[20px]">
          <icons.arrowRightLong
            color={hasValue(
              IsLight,
              colors?.Neutral900,
              colors?.Neutral900Dark
            )}
          />
        </div>
        <Image
          priority
          src={image.dummyUser1}
          alt={'logo'}
          className="w-[83.29px] h-[84px]"
        />
      </div>

      <div className="mt-[15px] mx-[27px]">
        <p className="text-Neutral900 dark:text-Neutral900Dark heading-small-XXL text-center">
          {AppConstant?.CHOOSE_A_USERNAME}
        </p>
        <p className="heading-medium-M text-Neutral800 mt-[10px] text-center">
          {AppConstant?.STAYING_ANONYMOUS}
        </p>
      </div>

      <div className="mt-[45px]">
        <p className="heading-medium-M text-Neutral800 mt-[10px] mx-[22px]">
          {AppConstant?.YOU_CAN_CHOOSE}
        </p>

        <div className="flex flex-wrap mx-[17px] mt-[10px]">
          {randomUser?.map((d, i) => {
            return (
              <div
                key={i}
                className="flex h-[33px] px-[15px] m-[5px] bg-Neutral200 dark:bg-Neutral200Dark items-center rounded-full cursor-pointer"
                onClick={() => onSelectUser(d)}
              >
                <p className="text-Action title-medium-MB">{d}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mx-[22px] mt-[45px]">
        <div className="flex h-[38px] items-center px-[10px] border-b-[1px] border-Primary">
          <input
            maxLength={20}
            value={value}
            placeholder="or enter a username"
            className="title-extra-large-B h-[38px] placeholder-Neutral500 dark:placeholder-Neutral500Dark text-Neutral900 dark:text-Neutral900Dark border-0 border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full bg-transparent dark:border-gray-600 outline-none appearance-none"
            onChange={onChange}
          />
        </div>

        <div className="mt-[12px]">
          {checkUserName ? (
            <p className="heading-small-R text-Warning mt-[15px]">
              {AppConstant?.USERNAME_NOT_AVAILABLE}
            </p>
          ) : (
            <p className="heading-small-R text-Action mt-[15px]">
              {AppConstant?.USERNAME_AVAILABLE}
            </p>
          )}
        </div>
      </div>

      <div className="flex mt-[80px] justify-center">
        <button
          className="bg-Primary flex h-[53px] px-[45px] rounded-full items-center mb-[30px]"
          style={btnColor}
          onClick={onClick}
        >
          <p className="title-large-B text-White">{AppConstant?.CONTINUE}</p>
        </button>
      </div>
    </>
  );
};

export default User;
