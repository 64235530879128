import * as React from 'react';

const PlusCircle = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 16.9998H17M17 16.9998H23.3333M17 16.9998V10.6665M17 16.9998V23.3332M32.8333 16.9998C32.8333 25.7443 25.7445 32.8332 17 32.8332C8.25549 32.8332 1.16666 25.7443 1.16666 16.9998C1.16666 8.25533 8.25549 1.1665 17 1.1665C25.7445 1.1665 32.8333 8.25533 32.8333 16.9998Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PlusCircle;
