import * as React from 'react';

const ScanMode = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.31006 3.875H4.93506C4.6244 3.875 4.37256 4.12684 4.37256 4.4375V7.8125C4.37256 8.12316 4.6244 8.375 4.93506 8.375H8.31006C8.62072 8.375 8.87256 8.12316 8.87256 7.8125V4.4375C8.87256 4.12684 8.62072 3.875 8.31006 3.875Z"
      stroke="#1E92FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.31006 10.625H4.93506C4.6244 10.625 4.37256 10.8768 4.37256 11.1875V14.5625C4.37256 14.8732 4.6244 15.125 4.93506 15.125H8.31006C8.62072 15.125 8.87256 14.8732 8.87256 14.5625V11.1875C8.87256 10.8768 8.62072 10.625 8.31006 10.625Z"
      stroke="#1E92FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0601 3.875H11.6851C11.3744 3.875 11.1226 4.12684 11.1226 4.4375V7.8125C11.1226 8.12316 11.3744 8.375 11.6851 8.375H15.0601C15.3707 8.375 15.6226 8.12316 15.6226 7.8125V4.4375C15.6226 4.12684 15.3707 3.875 15.0601 3.875Z"
      stroke="#1E92FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1226 10.625V12.875"
      stroke="#1E92FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1226 15.125H13.3726V10.625"
      stroke="#1E92FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3726 11.75H15.6226"
      stroke="#1E92FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6226 14V15.125"
      stroke="#1E92FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ScanMode;
