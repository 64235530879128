import { useEffect, useRef, useContext } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import LeftSide from '../LeftSide';
import RightSide from '../RightSide';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { setTokenData } from '../../store/login/Reducer';
import { getFromAsync } from '../../utils';
import { ASYNC_KEY } from '../../constants/AppConstant';
import { setScreenHeight, setScrollHeight } from '../../store/store';
import { AppContext } from '../../../src/context/AppProvider';

const BaseLayout = ({ children, pageProps }) => {
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const { global } = useSelector(state => state);
  const context = useContext(AppContext);
  const { asPath } = useRouter();

  const localData = () => {
    let tokenData = getFromAsync(ASYNC_KEY.ACCESS_TOKEN);
    context.setToken(tokenData);
  };

  useEffect(() => {
    localData();
  }, []);

  useEffect(() => {
    dispatch(setTokenData(getFromAsync(ASYNC_KEY.ACCESS_TOKEN)));
    document.activeElement.blur();
    rootRef.current.tabIndex = '-1';
    rootRef.current.focus();
  }, []);

  useEffect(() => {
    rootRef.current.scrollTop = global.screenHeight[asPath] || 0;
  }, [asPath]);

  useEffect(() => {
    rootRef.current?.scrollHeight &&
      dispatch(
        setScrollHeight({
          [asPath]: Number(
            rootRef.current?.scrollHeight - rootRef.current?.clientHeight
          )
        })
      );
  }, [rootRef.current?.scrollHeight]);

  const setHeight = e => {
    const scrollTop = e.target.scrollTop;

    if (scrollTop) {
      dispatch(setScreenHeight({ [asPath]: scrollTop }));
    }
  };

  return (
    <div
      id="root"
      className="pt-[32px] xl:container w-full px-3 xl:px-0 grid grid-cols-12 gap-8 bg-Neutral200 dark:bg-Black justify-center overflow-auto"
    >
      <div className="hidden md:block col-span-5 tablet:col-span-4 xl:col-span-3 overflow-auto pb-5">
        <LeftSide pageProps={pageProps} />
      </div>

      <div
        className="col-span-12 md:col-span-7 tablet:col-span-8 xl:col-span-6 overflow-x-hidden hide-scroll-bar rounded-[12px]"
        ref={rootRef}
        id='base-layout-main-content'
        onScroll={e => setHeight(e)}
      >
        {children}
      </div>

      <div className="col-span-3 hidden xl:block pb-5 overflow-auto">
        <RightSide pageProps={pageProps} />
      </div>
    </div>
  );
};

export default BaseLayout;
