import * as React from 'react';

const StarAction = ({ w, h, color }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.30121 5.80251C6.17271 4.93102 6.60287 3.67313 6.9988 1.81548C7.39482 3.67314 7.82504 4.93102 8.69652 5.80251C9.56803 6.67402 10.8259 7.10424 12.6837 7.50025C10.8259 7.89628 9.56803 8.32649 8.69652 9.198C7.82504 10.0695 7.39482 11.3274 6.9988 13.185C6.60287 11.3274 6.17271 10.0695 5.30121 9.198C4.42971 8.32649 3.17175 7.89628 1.31395 7.50025C3.17175 7.10424 4.42971 6.67402 5.30121 5.80251Z"
      stroke={color}
    />
  </svg>
);
export default StarAction;
