import * as React from 'react';

const LinkIcon = ({ w, h, color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1929 16.4989L11.7191 16.9877C10.4108 18.3374 8.28956 18.3374 6.98124 16.9877C5.67292 15.638 5.67292 13.4497 6.98124 12.1L8.11104 10.9344C9.31872 9.68853 11.2768 9.68853 12.4844 10.9344L12.6667 11.1224M11.8073 7.50106L12.2811 7.01229C13.5894 5.66257 15.7106 5.66257 17.0189 7.01229C18.3272 8.36201 18.3272 10.5503 17.0189 11.9L15.8891 13.0656C14.6814 14.3115 12.7234 14.3115 11.5157 13.0656L11.3335 12.8776M10 22L14 22C16.8003 22 18.2004 22 19.2699 21.455C20.2108 20.9757 20.9757 20.2108 21.455 19.27C22 18.2004 22 16.8003 22 14L22 10C22 7.19974 22 5.79961 21.455 4.73005C20.9757 3.78924 20.2108 3.02434 19.27 2.54497C18.2004 2 16.8003 2 14 2L10 2C7.19974 2 5.79961 2 4.73005 2.54497C3.78924 3.02433 3.02433 3.78924 2.54497 4.73005C2 5.79961 2 7.19974 2 10L2 14C2 16.8003 2 18.2004 2.54496 19.27C3.02433 20.2108 3.78923 20.9757 4.73004 21.455C5.7996 22 7.19973 22 10 22Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LinkIcon;
