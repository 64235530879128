import * as React from 'react';

const ChevronDown = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 9 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 1.625L4.5 5.375L8.25 1.625"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ChevronDown;
