import React from 'react';
import classes from './style.module.css';

const PrimaryButton = ({ children, className, style, onClick, ariaLabel }) => {
  return (
    <button
      className={`${classes?.primaryButton} bg-Primary dark:bg-PrimaryDark-40 text-Primary-10 dark:text-PrimaryDark-10 w-full hover:bg-Primary-50 `}
      style={style}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
