import * as React from 'react';

const Logout = ({ w, h, color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 16.0005L13.9999 16.5V16.5C14 17.896 14 18.594 13.8276 19.1619C13.4398 20.4401 12.4396 21.4403 11.1614 21.8281C10.5935 22.0005 9.89549 22.0005 8.49951 22.0005H7.99997C6.13623 22.0005 5.20436 22.0005 4.46929 21.696C3.48916 21.29 2.71045 20.5113 2.30447 19.5312C2 18.7961 2 17.8643 2 16.0005L2 8.00049C2 6.13672 2 5.20484 2.30448 4.46975C2.71046 3.48964 3.48916 2.71095 4.46927 2.30497C5.20435 2.00049 6.13624 2.00049 8 2.00049L8.50049 2.00049C9.8956 2.00049 10.5931 2.00049 11.1608 2.17262C12.4395 2.56037 13.4401 3.56101 13.8279 4.83972C14 5.40734 14 6.10489 14 7.5V7.5V8.00049M9 12L22 12M22 12L18 8M22 12L18 16"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Logout;
