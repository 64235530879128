import { createSlice } from '@reduxjs/toolkit';

export const loadingTrendingPostState = {
  initial: 'INITIAL',
  loading: 'LOADING',
  loaded: 'LOADED',
  error: 'ERROR'
};

const initialState = {
  trendingPostList: {
    state: loadingTrendingPostState.initial,
    data: [],
    error: undefined
  }
};

const getTrendingPostList = (state, action) => {
  state = {
    ...state,
    trendingPostList: {
      state: loadingTrendingPostState.loading
    }
  };

  return state;
};

const setTrendingPostList = (state, action) => {
  state = {
    ...state,
    trendingPostList: {
      state: loadingTrendingPostState.loaded,
      data: action?.payload
    }
  };

  return state;
};

const errorTrendingPostList = (state, action) => {
  state = {
    ...state,
    trendingPostList: {
      state: loadingTrendingPostState.error,
      error: action?.payload
    }
  };

  return state;
};

const trendingPostSlice = createSlice({
  name: 'trendingPost',
  initialState,
  reducers: {
    getTrendingPostList,
    setTrendingPostList,
    errorTrendingPostList
  }
});

export const trendingPostAction = trendingPostSlice.actions;

export default trendingPostSlice;
