const ROUTE = {
  HOME: '/home',
  INTRA: '/intra',
  SEARCH: '/search',
  MORE: '/more',
  DISCOVER: '/discover',
  POST_DETAILS: '/post',
  INBOX: '/inbox',
  YOU: '/you',
  JOBS: '/jobs',
  SALARY_DISCLAIMER: '/salaries/disclaimer',
  COMMUNITY_GUIDELINES: '/guidelines',
  COMPANY: '/company',
  SALARY: '/salary',
  SALARIES: '/salaries',
  SALARY_CARD: '/salaries/salarycard',
  ADD_SALARY: '/salaries/add',
  SHARE_SALARY: '/salaries/share',
  GRAPES: '/grapes',
  CUSTOM_404: '/404',
  COMMUNITY_POST: '/community',
  PRIVATE: '/private',
  COMPANY_LIST: 'company-list',
  COMPANY_DETAIL : '/company-detail',
  VINE_DETAIL: '/vines'
};

export { ROUTE };
