import { createSlice } from '@reduxjs/toolkit';

export const uiFlagsState = {
  initial: 'INITIAL',
  loading: 'LOADING',
  loaded: 'loaded',
  error: 'ERROR'
};

const initialState = {
  uiFLags: {
    state: uiFlagsState.initial,
    data: {},
    error: undefined
  }
};

const getUiFlags = (state, action) => {
  return {
    ...state,
    uiFLags: {
      ...state.uiFLags,
      state: uiFlagsState.loading
    }
  };
};

const setUiFlags = (state, action) => {
  return {
    ...state,
    uiFLags: {
      ...state.uiFLags,
      state: uiFlagsState.loaded,
      data: action?.payload
    }
  };
};

const errorUiFlags = (state, action) => {
  state = {
    ...state,
    uiFLags: {
      ...state.uiFLags,
      state: uiFlagsState.error,
      error: action?.payload
    }
  };
};

const clearUiFlags = (state, action) => {
  return {
    uiFLags: {
      state: uiFlagsState.initial,
      data: {},
      error: undefined
    }
  };
};

const uiFlagsSlice = createSlice({
  name: 'uiFlags',
  initialState,
  reducers: {
    getUiFlags,
    setUiFlags,
    errorUiFlags,
    clearUiFlags
  }
});

export const uiFlagsAction = uiFlagsSlice.actions;

export default uiFlagsSlice;
