import * as React from 'react';

const HomeFilled = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0557 3.99504L23.8137 9.753C23.8189 9.75808 23.8241 9.76324 23.8292 9.76847L25.4469 11.3862C25.7398 11.6791 25.7398 12.154 25.4469 12.4469C25.154 12.7398 24.6791 12.7398 24.3863 12.4469L23.7522 11.8128L22.3477 18.8355L22.3419 18.8643L22.3419 18.8644C22.1635 19.7563 22.0215 20.4663 21.8626 21.037C21.6994 21.623 21.5055 22.1129 21.1989 22.5521C20.6979 23.2699 20.0083 23.8352 19.2062 24.1858C18.7154 24.4003 18.197 24.4943 17.5904 24.5394C16.9996 24.5833 16.2755 24.5833 15.3659 24.5833H15.3365H10.6633H10.634C9.72435 24.5833 9.00027 24.5833 8.40943 24.5394C7.80281 24.4943 7.2844 24.4003 6.79361 24.1858C5.99153 23.8352 5.30191 23.2699 4.80088 22.5521C4.49431 22.1129 4.30044 21.623 4.13726 21.037C3.97832 20.4663 3.83632 19.7562 3.65793 18.8642L3.65218 18.8355L2.24765 11.8128L1.61358 12.4469C1.32069 12.7398 0.845815 12.7398 0.552922 12.4469C0.260029 12.154 0.260029 11.6791 0.552922 11.3862L2.17068 9.76847C2.17575 9.76324 2.18091 9.75808 2.18614 9.753L7.9441 3.99504L7.96744 3.9717L7.96746 3.97168C8.73971 3.19942 9.3517 2.58743 9.88529 2.13444C10.4309 1.67121 10.9438 1.33038 11.5321 1.13922C12.4861 0.829244 13.5137 0.829244 14.4677 1.13922C15.0561 1.33038 15.5689 1.67121 16.1145 2.13444C16.6481 2.58743 17.2601 3.19943 18.0324 3.9717L18.0324 3.97174L18.0557 3.99504ZM16.2499 12.9999C16.2499 14.7948 14.7948 16.2499 12.9999 16.2499C11.205 16.2499 9.74992 14.7948 9.74992 12.9999C9.74992 11.205 11.205 9.74988 12.9999 9.74988C14.7948 9.74988 16.2499 11.205 16.2499 12.9999Z"
      fill={color}
    />
  </svg>
);
export default HomeFilled;
