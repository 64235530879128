import React, { Fragment } from 'react';
import LeftSide from '../LeftSide';
import { AnimatePresence, motion } from 'framer-motion';

const SalaryLayout = ({ children }) => {
  return (
    <Fragment>
      <AnimatePresence>
        <motion.div
          id="root"
          className="md:h-[calc(100vh_-_50px)] h-[100vh] w-screen bg-White dark:bg-[#151515] overflow-hidden max-w-full"
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="flex mt-[0.3px]">
            <div className="hidden md:block flex-none min-h-full pt-10">
              <LeftSide isSalaryPage />
            </div>
            {children}
          </div>
        </motion.div>
      </AnimatePresence>
    </Fragment>
  );
};

export default SalaryLayout;
