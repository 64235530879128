import * as React from 'react';

const Discover = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.63024 12.0135C9.90587 11.4071 10.0437 11.1039 10.2378 10.8443C10.4099 10.614 10.6142 10.4096 10.8445 10.2375C11.1042 10.0434 11.4073 9.90563 12.0137 9.62999L16.3334 7.6665L14.3699 11.9862C14.0943 12.5926 13.9565 12.8958 13.7624 13.1554C13.5903 13.3857 13.3859 13.59 13.1556 13.7622C12.896 13.9562 12.5928 14.094 11.9864 14.3697L7.66675 16.3332L9.63024 12.0135Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.8334 11.9998C22.8334 17.9829 17.9832 22.8332 12.0001 22.8332C6.017 22.8332 1.16675 17.9829 1.16675 11.9998C1.16675 6.01675 6.017 1.1665 12.0001 1.1665C17.9832 1.1665 22.8334 6.01675 22.8334 11.9998Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Discover;
