import React from 'react';
const RightTickShield = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 9.99998L9.16667 11.6666L12.9167 7.91665M7.40073 1.98854L5.35055 2.89972C4.31875 3.35829 3.80286 3.58758 3.42457 3.95001C3.09019 4.27038 2.83451 4.6638 2.67756 5.09948C2.5 5.59236 2.5 6.15691 2.5 7.28602L2.5 10.7414C2.5 11.9937 2.5 12.6199 2.6726 13.1882C2.82541 13.6914 3.07592 14.1595 3.40982 14.5657C3.78697 15.0246 4.30796 15.3719 5.34992 16.0666L7.33744 17.3916C8.29971 18.0331 8.78085 18.3539 9.30089 18.4785C9.76046 18.5886 10.2395 18.5886 10.6991 18.4785C11.2192 18.3539 11.7003 18.0331 12.6626 17.3916L14.6501 16.0666C15.692 15.3719 16.213 15.0246 16.5902 14.5657C16.9241 14.1595 17.1746 13.6914 17.3274 13.1882C17.5 12.6199 17.5 11.9937 17.5 10.7414L17.5 7.28602C17.5 6.15691 17.5 5.59236 17.3224 5.09948C17.1655 4.6638 16.9098 4.27038 16.5754 3.95001C16.1971 3.58758 15.6813 3.35829 14.6495 2.89972L12.5993 1.98854C11.6421 1.56313 11.1635 1.35043 10.666 1.26642C10.2251 1.19196 9.77487 1.19196 9.33396 1.26642C8.83649 1.35043 8.3579 1.56313 7.40073 1.98854Z"
      stroke="#18B892"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RightTickShield;
