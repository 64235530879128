const title = 'Grapevine: Salary & Career Discussions - Join 300K+ members';
const description =
  'A platform for honest Salary and Career Discussions - Join 300K+ members from top tech companies and startups on Grapevine';

const seo = {
  title,
  description,
  openGraph: {
    type: 'website',
    // locale: 'en_IN',
    //  url: 'https://grapevine.in',
    siteName: 'Grapevine: Salary & Career Discussions - Join 300K+ members',
    site_name: 'Grapevine: Salary & Career Discussions - Join 300K+ members',
    title,
    description,
    images: [
      {
        url: 'https://stagvinecip01.blob.core.windows.net/stc-gvine-blob-centralindia-prod-001-django/images/assets/seo-preview.png',
        alt: 'Grapevine - Salaries & Career'
      }
    ]
  },
  twitter: {
    site: '@join_grapevine',
    cardType: 'summary_large_image',
    handle: '@join_grapevine'
  },
  robotsProps: {
    noarchive: true
    //noimageindex: true
  }
};

export default seo;
