import * as React from 'react';

const Jobs = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.70841 10.9165C1.70841 10.9165 4.23274 12.0384 7.66675 12.6676M7.66675 12.6676C9.00491 12.9128 10.4812 13.0832 12.0001 13.0832C13.519 13.0832 14.9953 12.9128 16.3334 12.6676M7.66675 12.6676V10.9165M7.66675 12.6676V14.1665M16.3334 12.6676C19.7674 12.0384 22.2917 10.9165 22.2917 10.9165M16.3334 12.6676V10.9165M16.3334 12.6676V14.1665M16.3334 5.49984C16.3334 3.1066 14.3933 1.1665 12.0001 1.1665C9.60685 1.1665 7.66675 3.1066 7.66675 5.49984M7.56674 22.8332H16.4334C18.6736 22.8332 19.7937 22.8332 20.6494 22.3972C21.402 22.0137 22.0139 21.4018 22.3974 20.6491C22.8334 19.7935 22.8334 18.6734 22.8334 16.4332V11.8998C22.8334 9.65963 22.8334 8.53952 22.3974 7.68388C22.0139 6.93123 21.402 6.3193 20.6494 5.93581C19.7937 5.49984 18.6736 5.49984 16.4334 5.49984H7.56675C5.32654 5.49984 4.20643 5.49984 3.35079 5.93581C2.59814 6.3193 1.98622 6.93123 1.60272 7.68388C1.16675 8.53952 1.16675 9.65963 1.16675 11.8998V16.4332C1.16675 18.6734 1.16675 19.7935 1.60272 20.6491C1.98622 21.4018 2.59814 22.0137 3.35079 22.3972C4.20643 22.8332 5.32654 22.8332 7.56674 22.8332Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Jobs;
