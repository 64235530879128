import * as React from 'react';

const Stars = ({ w, h, color, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.96687 5.84322C4.96687 5.60228 5.12195 5.38622 5.35698 5.29972L6.84891 4.75067C7.23499 4.60859 7.32734 4.57004 7.40031 4.52039C7.47793 4.46758 7.54575 4.40269 7.60094 4.32842C7.65283 4.25859 7.69312 4.17023 7.84161 3.80081L8.41543 2.37327C8.50583 2.14839 8.73163 2 8.98344 2C9.23525 2 9.46105 2.14839 9.55144 2.37327L10.1253 3.80081C10.2738 4.17023 10.314 4.25859 10.3659 4.32842C10.4211 4.40269 10.4889 4.46758 10.5666 4.52039C10.6395 4.57004 10.7319 4.60859 11.118 4.75067L12.6099 5.29972C12.8449 5.38622 13 5.60227 13 5.84322C13 6.08416 12.8449 6.30022 12.6099 6.38671L11.118 6.93576C10.7319 7.07785 10.6395 7.11639 10.5666 7.16604C10.4889 7.21885 10.4211 7.28374 10.3659 7.35801C10.314 7.42784 10.2738 7.51621 10.1253 7.88562L9.55144 9.31316C9.46105 9.53804 9.23525 9.68643 8.98344 9.68643C8.73163 9.68643 8.50583 9.53804 8.41543 9.31316L7.84161 7.88562C7.69312 7.51621 7.65283 7.42784 7.60094 7.35802C7.54575 7.28374 7.47793 7.21885 7.40031 7.16604C7.32734 7.11639 7.23499 7.07785 6.84891 6.93576L5.35698 6.38671C5.12196 6.30022 4.96687 6.08416 4.96687 5.84322Z"
      fill="#FF7800"
    />
    <path
      d="M3.03386 12.5114C2.96153 12.3223 3.00707 12.1103 3.1515 11.9638L3.6866 11.4211C3.88218 11.2228 3.92794 11.1731 3.96154 11.1222C3.99722 11.0681 4.02548 11.0098 4.04561 10.9488C4.06455 10.8914 4.07471 10.8259 4.10679 10.5549L4.19457 9.81357C4.21826 9.61346 4.35808 9.44334 4.55572 9.37413C4.75337 9.30491 4.97492 9.34849 5.12801 9.48669L5.69518 9.99869C5.90248 10.1858 5.95446 10.2296 6.00765 10.2618C6.06417 10.2959 6.12504 10.323 6.18878 10.3422C6.24877 10.3603 6.31727 10.3701 6.60047 10.4008L7.37526 10.4847C7.58439 10.5074 7.76218 10.6412 7.83452 10.8303C7.90685 11.0194 7.86131 11.2314 7.71688 11.3779L7.18179 11.9206C6.9862 12.1189 6.94044 12.1687 6.90685 12.2196C6.87116 12.2737 6.8429 12.3319 6.82278 12.3929C6.80383 12.4503 6.79367 12.5158 6.76159 12.7868L6.67382 13.5282C6.65012 13.7283 6.51031 13.8984 6.31266 13.9676C6.11501 14.0368 5.89346 13.9932 5.74037 13.855L5.1732 13.343C4.9659 13.1559 4.91392 13.1121 4.86073 13.08C4.80422 13.0458 4.74334 13.0188 4.6796 12.9995C4.61961 12.9814 4.55111 12.9717 4.26791 12.941L3.49312 12.857C3.28399 12.8343 3.1062 12.7005 3.03386 12.5114Z"
      fill="#FF7800"
    />
  </svg>
);
export default Stars;
