import React from 'react';
import classes from './style.module.css';

const SecondaryButton = ({ children, onClick, className, disable }) => {
  return (
    <button
      className={`${classes?.secondaryButton} text-grey-60 dark:text-darkGrey-60 border border-solid border-grey-30 hover:border-Primary-40 hover:text-Primary-40 ${className} ${disable ? 'cursor-not-allowed opacity-40' : ''}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
