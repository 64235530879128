import API_CONSTANT from '../../constants/ApiConstant';
import { get, post } from '../../services/ApiServices';
import { getError, showError } from '../../utils';
import * as track from '../../utils/MixpanelTrackEvent';
import { store } from '../store';
import {
  searchLoadingState,
  searchState,
  setSearchPostList,
  setSearchValue
} from './Reducer';

const getSearchPostAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.SEARCH_POST}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const searchPost = async (
  search,
  { limit = API_CONSTANT.LIMIT, offsets = 0 } = {}
) => {
  try {
    track.trackSearch(search);
    store.dispatch(setSearchValue(search));
    store.dispatch(searchState(searchLoadingState.loading));

    const res = await get(`${API_CONSTANT.SEARCH_POST}`, {
      limit: limit,
      offset: offsets,
      has: search
    });

    if (!res?.data?.data?.results?.length) {
      store.dispatch(searchState(searchLoadingState.empty));
      store.dispatch(setSearchPostList(res?.data?.data?.results));
      return;
    }

    store.dispatch(searchState(searchLoadingState.loaded));
    store.dispatch(setSearchPostList(res?.data?.data?.results));
  } catch (error) {
    store.dispatch(searchState(getError(searchLoadingState.error)));

    showError(error);
  }
};

export const postClear = () => {
  store.dispatch(setSearchValue(''));
  store.dispatch(setSearchPostList([]));
  store.dispatch(searchState(searchLoadingState.initial));
};

export { getSearchPostAPI };
