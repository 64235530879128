/**
 * Mixpanel Event and Property Name define here..
 */
const MixpanelConstants = {
  // <-- EVENTS -->
  //AUTH
  EVENT_VIEW_INTRO: 'view_intro',
  EVENT_WELCOME_SUBMIT: 'intro_submit_tap',
  EVENT_VIEW_LOGIN: 'view_login',
  EVENT_LOGIN_SUBMIT: 'login_submit_tap',
  EVENT_LOGIN_TERM_TAP: 'login_terms_tap',
  EVENT_LOGIN_PRIVACY_TAP: 'login_privacy_tap',
  EVENT_VIEW_OTP: 'view_otp',
  EVENT_OTP_SUBMIT: 'otp_submit_tap',
  EVENT_OTP_RESEND_SUBMIT: 'otp_resend_tap',
  //NEW USER
  EVENT_VIEW_SET_USERNAME: 'view_set_username',
  EVENT_SET_USERNAME_SUBMIT: 'set_username_submit_tap',
  EVENT_VIEW_SET_COMPANYNAME: 'view_set_company_name',
  EVENT_SET_COMPANYNAME_SUBMIT: 'set_company_name_submit_tap',
  EVENT_VIEW_SET_FIELDWORK: 'view_set_field_work',
  EVENT_SET_FIELDWORK_SUBMIT: 'set_field_work_submit_tap',
  EVENT_VIEW_SET_BIO: 'view_set_bio',
  EVENT_SET_BIO_SKIP_TAP: 'set_bio_skip_tap',
  EVENT_SET_BIO_SUBMIT: 'set_bio_submit_tap',
  EVENT_VIEW_SET_JOINCOMMUNITY: 'view_join_community',
  EVENT_SET_JOINCOMMUNITY_SUBMIT: 'join_community_submit_tap',
  EVENT_VIEW_SET_UNIVERSITY: 'view_join_university',
  EVENT_SET_UNIVERSITY_SUBMIT: 'join_university_submit_tap',
  //HOME

  EVENT_SEARCH_ICON_TAP: 'search_icon_tap',
  EVENT_HAMBURGER_ICON_TAP: 'hamburger_icon_tap',
  EVENT_VIEW_NEW_MEMBER: 'View_New_member',
  EVENT_NEW_MEMBER_SWIPE: 'New_member_swipe',
  EVENT_NEW_MEMBER_FULL_SWIPE: 'New_member_full_swipe',
  EVENT_NEW_MEMBER_MORE_TAP: 'New_member_more_tap',
  EVENT_NEW_MEMBER_CARD_SEE_MORE_CARD_TAP: 'New_member_card_see_more_card_tap',
  //POST
  EVENT_POST_TAP: 'post_tap',
  EVENT_POST_COMMUNITY_TAP: 'post_community_tap',
  EVENT_POST_LIKE_ICON_TAP: 'post_like_icon_tap',
  EVENT_POST_COMMENT_ICON_TAP: 'post_comment_icon_tap',
  EVENT_POST_SHARE: 'post_share',
  EVENT_POST_10_VIEW: 'post_10_view',
  EVENT_POST_OPTIONS_ICON_TAP: 'post_options_icon_tap',
  EVENT_POST_BAN: 'post_ban',
  EVENT_POST_DELETE: 'post_delete',
  EVENT_POST_REPORT: 'post_report',
  EVENT_POST_DELETE_WARN: 'post_deletewarn',
  EVENT_POLL_ANSWER: 'poll_answer',
  EVENT_NEW_MEMBER_CARD_TAP: 'New_member_card_tap',
  EVENT_NEW_MEMBER_CARD_WAVE: 'New_member_card_wave',
  EVENT_NEW_MEMBER_CARD_MORE: 'New_member_card_more',
  EVENT_NEW_MEMBER_CARD_DELETE: 'New_member_card_delete',
  EVENT_NEW_MEMBER_CARD_REPORT: 'New_member_card_report',
  EVENT_POST_FIVE_VIEW: 'post_5_view',
  EVENT_POST_CTA_TAP: 'Post_CTA_Tap',
  EVENT_POST_USERNAME_TAP: 'Post_username_tap',
  //L2 POST PAGE (detail post screen)
  EVENT_VIEW_POST_L2: 'View_Post_L2',
  EVENT_ADD_COMMENT_START: 'Add_comment_start',
  EVENT_ADD_COMMENT: 'Add_comment',
  EVENT_COMMENT_OPTIONS_TAP: 'comment_options_tap',
  EVENT_COMMENT_BAN: 'comment_ban',
  EVENT_COMMENT_DELETE: 'comment_delete',
  EVENT_COMMENT_REPORT: 'comment_report',
  EVENT_COMMENT_DELETE_WARN: 'comment_deletewarn',
  EVENT_NOTIFICATION_BELL_TAP: 'Notification_bell_tap',
  EVENT_POST_NOTIFICATION_ON: 'Post_Notifications_On',
  EVENT_POST_NOTIFICATION_OFF: 'Post_Notifications_Off',
  EVENT_POST_L2_USERNAME_TAP: 'Post_L2_username_tap',
  EVENT_POST_L2_SHARE_NUDGE_SEEN: 'L2_share_nudge_seen',
  EVENT_POST_L2_SHARE_NUDGE_DISMISS: 'L2_share_nudge_dismiss',
  EVENT_COMMENT_5_VIEW: 'comment_5_view',
  EVENT_COMMENT_LIKE: 'comment_like',
  EVENT_COMMENT_SHARE: 'comment_share',
  EVENT_COMMENT_REPLY_TAP: 'comment_reply_tap',
  // COMMENT SORT
  EVENT_COMMENT_SORT_TAP: 'comment_sort_tap',
  EVENT_COMMENT_SORTED: 'comments_sorted',
  // BOOKMARK
  EVENT_BOOKMARKED: 'Bookmarked',
  EVENT_BOOKMARK_REMOVED: 'Bookmark_removed',
  EVENT_VIEW_BOOKMARK: 'View_bookmarks',
  EVENT_BOOKMARK_TAPPED: 'bookmark_tapped',

  //CREATE POSTS
  EVENT_VIEW_CREATE: 'View_create_screen',
  EVENT_VIEW_CREATE_POST_SELECT_COMMUNITY:
    'View_create_post_select_community_screen',
  EVENT_VIEW_CREATE_POST_TAG_COMMUNITY: 'View_create_post_tag_community_screen',
  EVENT_POST_CREATED: 'post_created',
  //NOTIFICATION
  EVENT_VIEW_NOTIFICATIONS_SCREEN: 'View_notifications_screen',
  EVENT_NOTIFICATION_TAP: 'Notification_tap',
  EVENT_NOTIFICATION_APP_OPEN: 'Push_Notification_app_open',
  EVENT_NOTIFICATION_DELIVERED: 'Push_Notification_Delivered',
  //EXPLORE
  EVENT_VIEW_EXPLORE_SCREEN: 'View_Explore_screen',
  EVENT_EXPLORE_COMMUNITY_TAP: 'Explore_community_tap',
  //PROFILE
  EVENT_VIEW_PROFILE_SCREEN: 'View_profile_screen',
  EVENT_EDIT_PROFILE: 'edit_profile',
  //COMMUNITY
  EVENT_VIEW_COMMUNITY_SCREEN: 'View_community_screen',
  EVENT_COMMUNITY_SHARE: 'community_share',
  EVENT_COMMUNITY_JOIN: 'community_join',
  EVENT_COMMUNITY_LEAVE: 'community_leave',
  EVENT_COMMUNITY_TAG_FILTER: 'community_tag_filter',
  //SEARCH
  EVENT_SEARCH: 'search',
  EVENT_SEARCH_RESULT_TAP: 'search_result_tap',
  //HAMBURGER
  EVENT_SWITCH_TO_CARD_VIEW: 'Switch_to_Card_view',
  EVENT_SWITCH_TO_FEED_VIEW: 'Switch_to_feed_view',
  EVENT_THEME: 'Theme',
  //SNACKBAR
  EVENT_SNACKBAR_DISPLAYED: 'snackbar_displayed',
  //JOB
  EVENT_JOB_WAITING_LIST_TAP: 'Job_waiting_list_tap',
  EVENT_VIEW_JOBS: 'View_jobs',
  //USER PROPERTIES
  EVENT_USERNAME: 'username',
  EVENT_USERID: 'userId',
  EVENT_COMPANY: 'company',
  EVENT_INDUSTRY: 'industry',
  EVENT_BIO: 'bio',
  EVENT_JOIN_DATE: 'join date',
  EVENT_NUMBER_OF_POSTS_CREATED: 'no of posts created',
  EVENT_NUMBER_OF_COMMENTS: 'no of comments',
  EVENT_NUMBER_OF_POSTS_LIKED: 'no of posts liked',
  EVENT_POST_VIEW_TYPE: 'Post View type',
  EVENT_POST_SORT: 'post_sort',
  //VERIFICATION
  EVENT_VIEW_VERIFICATION_POPUP: 'view_verification_popup',
  EVENT_VERIFICATION_POPUP_CONTINUE: 'verification_popup_continue',
  EVENT_VERIFICATION_POPUP_NOT_NOW: 'verification_popup_not_now',
  EVENT_VIEW_VERIFICATION_EMAIL: 'view_verification_email',
  EVENT_VERIFICATION_EMAIL_SUBMIT_TAP: 'verification_email_submit_tap',
  EVENT_VERIFICATION_EMAIL_ERROR: 'verification_email_error',
  EVENT_VERIFICATION_EMAIL_DISMISS: 'verification_email_dismiss',
  EVENT_VIEW_VERIFICATION_OTP: 'view_verification_otp',
  EVENT_VERIFICATION_OTP_SUBMIT_TAP: 'verification_otp_submit_tap',
  EVENT_VERIFICATION_OTP_RESEND_TAP: 'verification_otp_resend_tap',
  EVENT_VERIFICATION_OTP_DISMISS: 'verification_otp_dismiss',
  EVENT_USER_VERIFIED: 'user_verified',
  EVENT_VERIFICATION_FAB_TAP: 'Verification_FAB_tap',
  // COMPANY
  EVENT_VIEW_COMAPNY_FEED: 'View_company_feed',
  EVENT_VIEW_HOME_FEED: 'View_home_feed',
  EVENT_VIEW_COMPANY_INVITE: 'View_company_invite',
  EVENT_VIEW_SHARE_COLLEAGUES: 'View_Share_with_colleagues',
  EVENT_INVITE_COWORKERS_TAP: 'Invite_coworkers_tap',
  EVENT_VIEW_COMAPNY_VERIFICATION: 'View_company_verification',

  //<-- REFERENCES -->
  REF_INTRO_SCREEN: 'Intro Screen',

  // App OPEN
  EVENT_APP_OPEN: 'App open',

  // SCREEN SHORT EVENT
  EVENT_SCREEN_SHORT: 'Screenshot',

  // RATE US DIALOSGUE EVENT
  EVENT_RATE_US_SHOWN: 'Rate_us_shown',
  EVENT_RATE_US_RATING: 'Rate_us_rating',
  EVENT_RATE_US_BUTTON: 'Rate_us_give_rating',
  EVENT_RATE_US_FEED_BACK_BUTTON: 'Rate_us_give_feedback',
  EVENT_RATE_US_LESS_THEN_THREE: 'Rate_us_detractor_dismiss',
  EVENT_RATE_US_MORE_THEN_THREE: 'Rate_us_promoter_dismiss',

  // RATE US DIALOSGUE KEY

  EVENT_RATING: 'Rating (1-5)',

  // UPDATE DIALOGUE
  EVENT_FORCED_UPDATE_SHOWN: 'Forced_update_shown',
  EVENT_FORCED_UPDATE__DOWNLOAD: 'Forced_update_download',

  // WHATS NEW UPDATE EVENT
  EVENT_WHATS_NEW_SHOWN: 'Whats_new_shown',
  EVENT_WHATS_NEW_SHOWN_DISMISS: 'Whats_new_dismissed',

  // WHEN OPEN USER SELECTION MENU FOR MENTION
  EVENT_TAG_MENU: 'tag_menu',
  EVENT_TAG_USER: 'user_tagged',

  //Bottom sheet comment
  EVENT_COMMENT_BAR_EXPAND: 'comment_bar_expand',

  // web -----------

  // home
  EVENT_VIEW_HOME: 'view_home',
  EVENT_HOME_SORT_TAP: 'Home_sort_tap',
  EVENT_HOME_SORTED: 'Home_sorted',

  //<-- KEY -->
  //POST
  KEY_VERSION_NUMBER: 'Version number',
  KEY_SCREEN_NAME: 'screen name',
  KEY_POST_ID: 'post ID',
  KEY_POST_VIEW_TYPE: 'post view type',
  KEY_POST_MONTIONS: 'mentions',
  KEY_POST_TYPE: 'post type',
  KEY_USERTYPE: 'usertype',
  KEY_SOURCE: 'source',
  KEY_COMMUNITY_NAME: 'community name',
  KEY_COMMUNITY_ID: 'community Id',
  KEY_USERNAME: 'username',
  KEY_CREATION_DATE: 'creation date',
  KEY_NUMBER_OF_LIKE: 'number of likes',
  KEY_NUMBER_OF_WAVES: 'number of waves',
  KEY_NUMBER_OF_COMMENTS: 'number of comments',
  KEY_NUMBER_OF_LIKES_COMMENTS: 'no of likes on comment',
  KEY_USERNAME_ACTION_TAKER: 'username of action taker',
  KEY_CREATOR_COMPANY: 'creator company',
  KEY_CREATOR_USERNAME: 'creator username',
  KEY_CREATOR_TYPE: 'creator type',
  KEY_TAG: 'tag',
  KEY_TAG_NAME: 'tag name',
  KEY_TYPE: 'Type',
  KEY_NUMBER_UNOPENED_NOTIFICATION: 'number of unopened notification',
  KEY_NOTIFICATION_ID: 'notification id',
  KEY_NOTIFICATION_TEXT: 'notification text',
  KEY_LEVEL: 'level',
  KEY_SEARCH_TEXT: 'search text',
  KEY_SEARCH_SERIAL_NUMBER: 'serial number',
  KEY_SNACKBAR_TEXT: 'snackbar text',
  KEY_NEW_MEMBER: 'New member',
  KEY_POST_SORT: 'sort type',
  KEY_THEME: 'Theme',
  KEY_PROFILE: 'profile',
  KEY_PARENT_NAME: 'Parent name',
  KEY_COMMENT_ID: 'comment ID',
  KEY_HOME_POPUP: 'home_pop_up',
  KEY_COMPANY: 'company',
  KEY_COMPANY_ID: 'company id',
  KEY_COMPANY_NAME: 'Company name',
  KEY_FAB: 'FAB',
  KEY_ERROR: 'error',
  KEY_NO_OF_MEMBER_REQUIRED: 'No of members required',
  KEY_SORT_ALONG_NAME: 'sort along name',
  KEY_SHOW_WHATSAPP_ICON_SHARE: 'Show Whatsapp Icon for share',
  KEY_EXPAND_ACTION: 'expand action',
  KEY_USERNAME_OF_USER: 'Username of the user',
  KEY_WORK_FIELD: 'Work field',
  KEY_BIO_OF_THE_USER: 'Bio of the user',
  KEY_COMMUNITIES_JOINED: 'Communities joined',
  KEY_NO_OF_POST_LEFT: 'No of posts left',
  KEY_IS_WEBSITE: 'IsWebsite',
  KEY_IS_AUTHENTICATED: 'isAuthenticated',

  // SALARIES
  PLATFORM: 'platform',
  GRAPH_NAME: 'graph_name',
  ACTIVE_TAB: 'active_tab',
  TAB_NAME: 'tab_name',
  FILTER_TAB_NAME: 'filter_tab_name',
  COMPANY_STAGE: 'company_stage',
  JOB_FUNCTION: 'job_function',
  EXPERIENCE: 'experience',
  SALARY: 'salary',
  COMPANY_NAMES: 'company_names',
  SPEACILIZATION: 'specialization',
  SENIORITY: 'seniority',
  YEARS_OF_EXPERIENCE: 'years_of_experience',
  EVENT_VIEW_SALARIES: 'view_salaries',
  EVENT_SALARY_DISTRIBUTION_TAP: 'salary_distribution_tap',
  EVENT_SALARY_PROGRESSION_TAP: 'salary_progression_tap',
  EVENT_ADD_SALARY: 'add_salary_tap',
  EVENT_FILTER_GRAPH: 'filter_graph',
  EVENT_COMPANY_FILTER_TAP: 'company_filter_tap',
  EVENT_FUNCTION_FILTER_TAP: 'function_filter_tap',
  EVENT_EXPERIENCE_FILTER_TAP: 'experience_filte_tap',
  EVENT_SALARY_FILTER_TAP: 'salary_filter_tap',
  EVENT_FILTER_OPTIONS_APPLIED: 'filter_options_applied',
  EVENT_BOTTOMSHEET_OPEN: 'bottom_sheet_open',
  EVENT_BOTTOMSHEET_DATA_POINT_TAP_OPEN: 'bottom_sheet_data_point_tap_open',
  COMPANY_NAME: 'company_name',
  ROLE_NAME: 'role_name',
  TOTAL_SALARY: 'total_salary',
  FIXED_SALARY: 'fixed_salary',
  VARIABLE_SALARY: 'variable_salary',
  YEARLY_ESOP: 'yearly_esop',
  YEARLY_RSU: 'yearly_rsu',
  EVENT_VIEW_MY_SALARY_CARD: 'view_my_salary_card',
  EVENT_VIEW_ANALYSE_SALARY: 'view_analyse_salary',
  EVENT_CREATE_SALARY_CARD_TAP: 'create_salary_card_tap',
  EVENT_SEE_MY_SALARY_CHART_TAP: 'see_my_salary_chart_tap',
  EVENT_SEE_SALARY_CARD_TAP: 'see_salary_card_tap',
  EVENT_VIEW_ADD_SALARY: 'view_add_salary',
  EVENT_SALARY_ADDED: 'salary_added',
  EVENT_SALARY_SHARE_TAP: 'salary_share_tap',
  EVENT_VIEW_SALARY_DISCLAIMER: 'view_salary_disclaimer',
  EVENT_TAP_TO_CONTINUE_VIEW_SALARY: 'view_continue_salary_tap',
  EVENT_SALARY_SEARCH: 'salary_search'
};

export { MixpanelConstants };
