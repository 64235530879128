import API_CONSTANT from '../../constants/ApiConstant';
import { get, getWithOutToken } from '../../services/ApiServices';

export const getDiscoverPostsAPI = (postId, limit, page) => {
  return get(`${API_CONSTANT.DISCOVER_POSTS}/?post_id=${postId}&limit=${limit}&offset=${page * limit}`);
};

export const getDiscoverPostsUnauthorizedAPI = (postId, limit, page) => {
  return getWithOutToken(`${API_CONSTANT.DISCOVER_POSTS_UNAUTHORIZED}/?post_id=${postId}&limit=${limit}&offset=${page * limit}`);
};
