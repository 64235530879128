import * as React from 'react';

const Vector = ({ w, h, color, ...props }) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.75C9.53587 0.75 9.09075 0.934375 8.76256 1.26256C8.43437 1.59075 8.25 2.03587 8.25 2.5V9.5C8.25 9.96413 8.43437 10.4092 8.76256 10.7374C9.09075 11.0656 9.53587 11.25 10 11.25C10.4641 11.25 10.9092 11.0656 11.2374 10.7374C11.5656 10.4092 11.75 9.96413 11.75 9.5C11.75 9.03587 11.5656 8.59075 11.2374 8.26256C10.9092 7.93437 10.4641 7.75 10 7.75H3C2.53587 7.75 2.09075 7.93437 1.76256 8.26256C1.43437 8.59075 1.25 9.03587 1.25 9.5C1.25 9.96413 1.43437 10.4092 1.76256 10.7374C2.09075 11.0656 2.53587 11.25 3 11.25C3.46413 11.25 3.90925 11.0656 4.23744 10.7374C4.56563 10.4092 4.75 9.96413 4.75 9.5V2.5C4.75 2.03587 4.56563 1.59075 4.23744 1.26256C3.90925 0.934375 3.46413 0.75 3 0.75C2.53587 0.75 2.09075 0.934375 1.76256 1.26256C1.43437 1.59075 1.25 2.03587 1.25 2.5C1.25 2.96413 1.43437 3.40925 1.76256 3.73744C2.09075 4.06563 2.53587 4.25 3 4.25H10C10.4641 4.25 10.9092 4.06563 11.2374 3.73744C11.5656 3.40925 11.75 2.96413 11.75 2.5C11.75 2.03587 11.5656 1.59075 11.2374 1.26256C10.9092 0.934375 10.4641 0.75 10 0.75Z"
      stroke="#8B8B8B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Vector;
