import * as React from 'react';

const QR = ({ w, h, color, ...props }) => (
  <svg
    width={'111' || w}
    height={'110' || w}
    viewBox="0 0 111 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.0354 3.03254C36.6639 3.03254 37.1734 2.52305 37.1734 1.89457C37.1734 1.26608 36.6639 0.756592 36.0354 0.756592C35.4069 0.756592 34.8975 1.26608 34.8975 1.89457C34.8975 2.52305 35.4069 3.03254 36.0354 3.03254Z"
      fill={color}
    />
    <path
      d="M39.8294 3.03254C40.4579 3.03254 40.9674 2.52305 40.9674 1.89457C40.9674 1.26608 40.4579 0.756592 39.8294 0.756592C39.2009 0.756592 38.6914 1.26608 38.6914 1.89457C38.6914 2.52305 39.2009 3.03254 39.8294 3.03254Z"
      fill={color}
    />
    <path
      d="M47.4153 3.03254C48.0438 3.03254 48.5533 2.52305 48.5533 1.89457C48.5533 1.26608 48.0438 0.756592 47.4153 0.756592C46.7868 0.756592 46.2773 1.26608 46.2773 1.89457C46.2773 2.52305 46.7868 3.03254 47.4153 3.03254Z"
      fill={color}
    />
    <path
      d="M55.0022 3.03254C55.6307 3.03254 56.1402 2.52305 56.1402 1.89457C56.1402 1.26608 55.6307 0.756592 55.0022 0.756592C54.3737 0.756592 53.8643 1.26608 53.8643 1.89457C53.8643 2.52305 54.3737 3.03254 55.0022 3.03254Z"
      fill={color}
    />
    <path
      d="M66.3821 3.03254C67.0106 3.03254 67.5201 2.52305 67.5201 1.89457C67.5201 1.26608 67.0106 0.756592 66.3821 0.756592C65.7536 0.756592 65.2441 1.26608 65.2441 1.89457C65.2441 2.52305 65.7536 3.03254 66.3821 3.03254Z"
      fill={color}
    />
    <path
      d="M70.1751 3.03254C70.8036 3.03254 71.3131 2.52305 71.3131 1.89457C71.3131 1.26608 70.8036 0.756592 70.1751 0.756592C69.5466 0.756592 69.0371 1.26608 69.0371 1.89457C69.0371 2.52305 69.5466 3.03254 70.1751 3.03254Z"
      fill={color}
    />
    <path
      d="M73.9681 3.03254C74.5965 3.03254 75.106 2.52305 75.106 1.89457C75.106 1.26608 74.5965 0.756592 73.9681 0.756592C73.3396 0.756592 72.8301 1.26608 72.8301 1.89457C72.8301 2.52305 73.3396 3.03254 73.9681 3.03254Z"
      fill={color}
    />
    <path
      d="M77.762 3.03254C78.3905 3.03254 78.9 2.52305 78.9 1.89457C78.9 1.26608 78.3905 0.756592 77.762 0.756592C77.1335 0.756592 76.624 1.26608 76.624 1.89457C76.624 2.52305 77.1335 3.03254 77.762 3.03254Z"
      fill={color}
    />
    <path
      d="M36.0354 6.82575C36.6639 6.82575 37.1734 6.31627 37.1734 5.68778C37.1734 5.05929 36.6639 4.5498 36.0354 4.5498C35.4069 4.5498 34.8975 5.05929 34.8975 5.68778C34.8975 6.31627 35.4069 6.82575 36.0354 6.82575Z"
      fill={color}
    />
    <path
      d="M39.8294 6.82575C40.4579 6.82575 40.9674 6.31627 40.9674 5.68778C40.9674 5.05929 40.4579 4.5498 39.8294 4.5498C39.2009 4.5498 38.6914 5.05929 38.6914 5.68778C38.6914 6.31627 39.2009 6.82575 39.8294 6.82575Z"
      fill={color}
    />
    <path
      d="M62.5882 6.82575C63.2167 6.82575 63.7261 6.31627 63.7261 5.68778C63.7261 5.05929 63.2167 4.5498 62.5882 4.5498C61.9597 4.5498 61.4502 5.05929 61.4502 5.68778C61.4502 6.31627 61.9597 6.82575 62.5882 6.82575Z"
      fill={color}
    />
    <path
      d="M70.1751 6.82575C70.8036 6.82575 71.3131 6.31627 71.3131 5.68778C71.3131 5.05929 70.8036 4.5498 70.1751 4.5498C69.5466 4.5498 69.0371 5.05929 69.0371 5.68778C69.0371 6.31627 69.5466 6.82575 70.1751 6.82575Z"
      fill={color}
    />
    <path
      d="M77.762 6.82575C78.3905 6.82575 78.9 6.31627 78.9 5.68778C78.9 5.05929 78.3905 4.5498 77.762 4.5498C77.1335 4.5498 76.624 5.05929 76.624 5.68778C76.624 6.31627 77.1335 6.82575 77.762 6.82575Z"
      fill={color}
    />
    <path
      d="M36.0354 10.619C36.6639 10.619 37.1734 10.1095 37.1734 9.48099C37.1734 8.85251 36.6639 8.34302 36.0354 8.34302C35.4069 8.34302 34.8975 8.85251 34.8975 9.48099C34.8975 10.1095 35.4069 10.619 36.0354 10.619Z"
      fill={color}
    />
    <path
      d="M43.6223 10.619C44.2508 10.619 44.7603 10.1095 44.7603 9.48099C44.7603 8.85251 44.2508 8.34302 43.6223 8.34302C42.9939 8.34302 42.4844 8.85251 42.4844 9.48099C42.4844 10.1095 42.9939 10.619 43.6223 10.619Z"
      fill={color}
    />
    <path
      d="M47.4153 10.619C48.0438 10.619 48.5533 10.1095 48.5533 9.48099C48.5533 8.85251 48.0438 8.34302 47.4153 8.34302C46.7868 8.34302 46.2773 8.85251 46.2773 9.48099C46.2773 10.1095 46.7868 10.619 47.4153 10.619Z"
      fill={color}
    />
    <path
      d="M55.0022 10.619C55.6307 10.619 56.1402 10.1095 56.1402 9.48099C56.1402 8.85251 55.6307 8.34302 55.0022 8.34302C54.3737 8.34302 53.8643 8.85251 53.8643 9.48099C53.8643 10.1095 54.3737 10.619 55.0022 10.619Z"
      fill={color}
    />
    <path
      d="M58.7952 10.619C59.4237 10.619 59.9332 10.1095 59.9332 9.48099C59.9332 8.85251 59.4237 8.34302 58.7952 8.34302C58.1667 8.34302 57.6572 8.85251 57.6572 9.48099C57.6572 10.1095 58.1667 10.619 58.7952 10.619Z"
      fill={color}
    />
    <path
      d="M66.3821 10.619C67.0106 10.619 67.5201 10.1095 67.5201 9.48099C67.5201 8.85251 67.0106 8.34302 66.3821 8.34302C65.7536 8.34302 65.2441 8.85251 65.2441 9.48099C65.2441 10.1095 65.7536 10.619 66.3821 10.619Z"
      fill={color}
    />
    <path
      d="M73.9681 10.619C74.5965 10.619 75.106 10.1095 75.106 9.48099C75.106 8.85251 74.5965 8.34302 73.9681 8.34302C73.3396 8.34302 72.8301 8.85251 72.8301 9.48099C72.8301 10.1095 73.3396 10.619 73.9681 10.619Z"
      fill={color}
    />
    <path
      d="M36.0354 14.4122C36.6639 14.4122 37.1734 13.9027 37.1734 13.2742C37.1734 12.6457 36.6639 12.1362 36.0354 12.1362C35.4069 12.1362 34.8975 12.6457 34.8975 13.2742C34.8975 13.9027 35.4069 14.4122 36.0354 14.4122Z"
      fill={color}
    />
    <path
      d="M47.4153 14.4122C48.0438 14.4122 48.5533 13.9027 48.5533 13.2742C48.5533 12.6457 48.0438 12.1362 47.4153 12.1362C46.7868 12.1362 46.2773 12.6457 46.2773 13.2742C46.2773 13.9027 46.7868 14.4122 47.4153 14.4122Z"
      fill={color}
    />
    <path
      d="M51.2093 14.4122C51.8378 14.4122 52.3472 13.9027 52.3472 13.2742C52.3472 12.6457 51.8378 12.1362 51.2093 12.1362C50.5808 12.1362 50.0713 12.6457 50.0713 13.2742C50.0713 13.9027 50.5808 14.4122 51.2093 14.4122Z"
      fill={color}
    />
    <path
      d="M62.5882 14.4122C63.2167 14.4122 63.7261 13.9027 63.7261 13.2742C63.7261 12.6457 63.2167 12.1362 62.5882 12.1362C61.9597 12.1362 61.4502 12.6457 61.4502 13.2742C61.4502 13.9027 61.9597 14.4122 62.5882 14.4122Z"
      fill={color}
    />
    <path
      d="M70.1751 14.4122C70.8036 14.4122 71.3131 13.9027 71.3131 13.2742C71.3131 12.6457 70.8036 12.1362 70.1751 12.1362C69.5466 12.1362 69.0371 12.6457 69.0371 13.2742C69.0371 13.9027 69.5466 14.4122 70.1751 14.4122Z"
      fill={color}
    />
    <path
      d="M36.0354 18.2054C36.6639 18.2054 37.1734 17.6959 37.1734 17.0674C37.1734 16.4389 36.6639 15.9294 36.0354 15.9294C35.4069 15.9294 34.8975 16.4389 34.8975 17.0674C34.8975 17.6959 35.4069 18.2054 36.0354 18.2054Z"
      fill={color}
    />
    <path
      d="M43.6223 18.2054C44.2508 18.2054 44.7603 17.6959 44.7603 17.0674C44.7603 16.4389 44.2508 15.9294 43.6223 15.9294C42.9939 15.9294 42.4844 16.4389 42.4844 17.0674C42.4844 17.6959 42.9939 18.2054 43.6223 18.2054Z"
      fill={color}
    />
    <path
      d="M47.4153 18.2054C48.0438 18.2054 48.5533 17.6959 48.5533 17.0674C48.5533 16.4389 48.0438 15.9294 47.4153 15.9294C46.7868 15.9294 46.2773 16.4389 46.2773 17.0674C46.2773 17.6959 46.7868 18.2054 47.4153 18.2054Z"
      fill={color}
    />
    <path
      d="M66.3821 18.2054C67.0106 18.2054 67.5201 17.6959 67.5201 17.0674C67.5201 16.4389 67.0106 15.9294 66.3821 15.9294C65.7536 15.9294 65.2441 16.4389 65.2441 17.0674C65.2441 17.6959 65.7536 18.2054 66.3821 18.2054Z"
      fill={color}
    />
    <path
      d="M70.1751 18.2054C70.8036 18.2054 71.3131 17.6959 71.3131 17.0674C71.3131 16.4389 70.8036 15.9294 70.1751 15.9294C69.5466 15.9294 69.0371 16.4389 69.0371 17.0674C69.0371 17.6959 69.5466 18.2054 70.1751 18.2054Z"
      fill={color}
    />
    <path
      d="M32.2425 21.9986C32.871 21.9986 33.3804 21.4891 33.3804 20.8606C33.3804 20.2321 32.871 19.7227 32.2425 19.7227C31.614 19.7227 31.1045 20.2321 31.1045 20.8606C31.1045 21.4891 31.614 21.9986 32.2425 21.9986Z"
      fill={color}
    />
    <path
      d="M39.8294 21.9986C40.4579 21.9986 40.9674 21.4891 40.9674 20.8606C40.9674 20.2321 40.4579 19.7227 39.8294 19.7227C39.2009 19.7227 38.6914 20.2321 38.6914 20.8606C38.6914 21.4891 39.2009 21.9986 39.8294 21.9986Z"
      fill={color}
    />
    <path
      d="M58.7952 21.9986C59.4237 21.9986 59.9332 21.4891 59.9332 20.8606C59.9332 20.2321 59.4237 19.7227 58.7952 19.7227C58.1667 19.7227 57.6572 20.2321 57.6572 20.8606C57.6572 21.4891 58.1667 21.9986 58.7952 21.9986Z"
      fill={color}
    />
    <path
      d="M62.5882 21.9986C63.2167 21.9986 63.7261 21.4891 63.7261 20.8606C63.7261 20.2321 63.2167 19.7227 62.5882 19.7227C61.9597 19.7227 61.4502 20.2321 61.4502 20.8606C61.4502 21.4891 61.9597 21.9986 62.5882 21.9986Z"
      fill={color}
    />
    <path
      d="M66.3821 21.9986C67.0106 21.9986 67.5201 21.4891 67.5201 20.8606C67.5201 20.2321 67.0106 19.7227 66.3821 19.7227C65.7536 19.7227 65.2441 20.2321 65.2441 20.8606C65.2441 21.4891 65.7536 21.9986 66.3821 21.9986Z"
      fill={color}
    />
    <path
      d="M73.9681 21.9986C74.5965 21.9986 75.106 21.4891 75.106 20.8606C75.106 20.2321 74.5965 19.7227 73.9681 19.7227C73.3396 19.7227 72.8301 20.2321 72.8301 20.8606C72.8301 21.4891 73.3396 21.9986 73.9681 21.9986Z"
      fill={color}
    />
    <path
      d="M77.762 21.9986C78.3905 21.9986 78.9 21.4891 78.9 20.8606C78.9 20.2321 78.3905 19.7227 77.762 19.7227C77.1335 19.7227 76.624 20.2321 76.624 20.8606C76.624 21.4891 77.1335 21.9986 77.762 21.9986Z"
      fill={color}
    />
    <path
      d="M32.2425 25.7921C32.871 25.7921 33.3804 25.2826 33.3804 24.6541C33.3804 24.0256 32.871 23.5161 32.2425 23.5161C31.614 23.5161 31.1045 24.0256 31.1045 24.6541C31.1045 25.2826 31.614 25.7921 32.2425 25.7921Z"
      fill={color}
    />
    <path
      d="M39.8294 25.7921C40.4579 25.7921 40.9674 25.2826 40.9674 24.6541C40.9674 24.0256 40.4579 23.5161 39.8294 23.5161C39.2009 23.5161 38.6914 24.0256 38.6914 24.6541C38.6914 25.2826 39.2009 25.7921 39.8294 25.7921Z"
      fill={color}
    />
    <path
      d="M47.4153 25.7921C48.0438 25.7921 48.5533 25.2826 48.5533 24.6541C48.5533 24.0256 48.0438 23.5161 47.4153 23.5161C46.7868 23.5161 46.2773 24.0256 46.2773 24.6541C46.2773 25.2826 46.7868 25.7921 47.4153 25.7921Z"
      fill={color}
    />
    <path
      d="M55.0022 25.7921C55.6307 25.7921 56.1402 25.2826 56.1402 24.6541C56.1402 24.0256 55.6307 23.5161 55.0022 23.5161C54.3737 23.5161 53.8643 24.0256 53.8643 24.6541C53.8643 25.2826 54.3737 25.7921 55.0022 25.7921Z"
      fill={color}
    />
    <path
      d="M62.5882 25.7921C63.2167 25.7921 63.7261 25.2826 63.7261 24.6541C63.7261 24.0256 63.2167 23.5161 62.5882 23.5161C61.9597 23.5161 61.4502 24.0256 61.4502 24.6541C61.4502 25.2826 61.9597 25.7921 62.5882 25.7921Z"
      fill={color}
    />
    <path
      d="M70.1751 25.7921C70.8036 25.7921 71.3131 25.2826 71.3131 24.6541C71.3131 24.0256 70.8036 23.5161 70.1751 23.5161C69.5466 23.5161 69.0371 24.0256 69.0371 24.6541C69.0371 25.2826 69.5466 25.7921 70.1751 25.7921Z"
      fill={color}
    />
    <path
      d="M77.762 25.7921C78.3905 25.7921 78.9 25.2826 78.9 24.6541C78.9 24.0256 78.3905 23.5161 77.762 23.5161C77.1335 23.5161 76.624 24.0256 76.624 24.6541C76.624 25.2826 77.1335 25.7921 77.762 25.7921Z"
      fill={color}
    />
    <path
      d="M36.0354 29.5853C36.6639 29.5853 37.1734 29.0758 37.1734 28.4473C37.1734 27.8188 36.6639 27.3093 36.0354 27.3093C35.4069 27.3093 34.8975 27.8188 34.8975 28.4473C34.8975 29.0758 35.4069 29.5853 36.0354 29.5853Z"
      fill={color}
    />
    <path
      d="M39.8294 29.5853C40.4579 29.5853 40.9674 29.0758 40.9674 28.4473C40.9674 27.8188 40.4579 27.3093 39.8294 27.3093C39.2009 27.3093 38.6914 27.8188 38.6914 28.4473C38.6914 29.0758 39.2009 29.5853 39.8294 29.5853Z"
      fill={color}
    />
    <path
      d="M47.4153 29.5853C48.0438 29.5853 48.5533 29.0758 48.5533 28.4473C48.5533 27.8188 48.0438 27.3093 47.4153 27.3093C46.7868 27.3093 46.2773 27.8188 46.2773 28.4473C46.2773 29.0758 46.7868 29.5853 47.4153 29.5853Z"
      fill={color}
    />
    <path
      d="M51.2093 29.5853C51.8378 29.5853 52.3472 29.0758 52.3472 28.4473C52.3472 27.8188 51.8378 27.3093 51.2093 27.3093C50.5808 27.3093 50.0713 27.8188 50.0713 28.4473C50.0713 29.0758 50.5808 29.5853 51.2093 29.5853Z"
      fill={color}
    />
    <path
      d="M58.7952 29.5853C59.4237 29.5853 59.9332 29.0758 59.9332 28.4473C59.9332 27.8188 59.4237 27.3093 58.7952 27.3093C58.1667 27.3093 57.6572 27.8188 57.6572 28.4473C57.6572 29.0758 58.1667 29.5853 58.7952 29.5853Z"
      fill={color}
    />
    <path
      d="M62.5882 29.5853C63.2167 29.5853 63.7261 29.0758 63.7261 28.4473C63.7261 27.8188 63.2167 27.3093 62.5882 27.3093C61.9597 27.3093 61.4502 27.8188 61.4502 28.4473C61.4502 29.0758 61.9597 29.5853 62.5882 29.5853Z"
      fill={color}
    />
    <path
      d="M66.3821 29.5853C67.0106 29.5853 67.5201 29.0758 67.5201 28.4473C67.5201 27.8188 67.0106 27.3093 66.3821 27.3093C65.7536 27.3093 65.2441 27.8188 65.2441 28.4473C65.2441 29.0758 65.7536 29.5853 66.3821 29.5853Z"
      fill={color}
    />
    <path
      d="M73.9681 29.5853C74.5965 29.5853 75.106 29.0758 75.106 28.4473C75.106 27.8188 74.5965 27.3093 73.9681 27.3093C73.3396 27.3093 72.8301 27.8188 72.8301 28.4473C72.8301 29.0758 73.3396 29.5853 73.9681 29.5853Z"
      fill={color}
    />
    <path
      d="M77.762 29.5853C78.3905 29.5853 78.9 29.0758 78.9 28.4473C78.9 27.8188 78.3905 27.3093 77.762 27.3093C77.1335 27.3093 76.624 27.8188 76.624 28.4473C76.624 29.0758 77.1335 29.5853 77.762 29.5853Z"
      fill={color}
    />
    <path
      d="M1.89676 33.3785C2.52525 33.3785 3.03474 32.869 3.03474 32.2405C3.03474 31.612 2.52525 31.1025 1.89676 31.1025C1.26828 31.1025 0.758789 31.612 0.758789 32.2405C0.758789 32.869 1.26828 33.3785 1.89676 33.3785Z"
      fill={color}
    />
    <path
      d="M13.2766 33.3785C13.9051 33.3785 14.4146 32.869 14.4146 32.2405C14.4146 31.612 13.9051 31.1025 13.2766 31.1025C12.6482 31.1025 12.1387 31.612 12.1387 32.2405C12.1387 32.869 12.6482 33.3785 13.2766 33.3785Z"
      fill={color}
    />
    <path
      d="M20.8626 33.3785C21.4911 33.3785 22.0006 32.869 22.0006 32.2405C22.0006 31.612 21.4911 31.1025 20.8626 31.1025C20.2341 31.1025 19.7246 31.612 19.7246 32.2405C19.7246 32.869 20.2341 33.3785 20.8626 33.3785Z"
      fill={color}
    />
    <path
      d="M24.6565 33.3785C25.285 33.3785 25.7945 32.869 25.7945 32.2405C25.7945 31.612 25.285 31.1025 24.6565 31.1025C24.028 31.1025 23.5186 31.612 23.5186 32.2405C23.5186 32.869 24.028 33.3785 24.6565 33.3785Z"
      fill={color}
    />
    <path
      d="M32.2425 33.3785C32.871 33.3785 33.3804 32.869 33.3804 32.2405C33.3804 31.612 32.871 31.1025 32.2425 31.1025C31.614 31.1025 31.1045 31.612 31.1045 32.2405C31.1045 32.869 31.614 33.3785 32.2425 33.3785Z"
      fill={color}
    />
    <path
      d="M36.0354 33.3785C36.6639 33.3785 37.1734 32.869 37.1734 32.2405C37.1734 31.612 36.6639 31.1025 36.0354 31.1025C35.4069 31.1025 34.8975 31.612 34.8975 32.2405C34.8975 32.869 35.4069 33.3785 36.0354 33.3785Z"
      fill={color}
    />
    <path
      d="M39.8294 33.3785C40.4579 33.3785 40.9674 32.869 40.9674 32.2405C40.9674 31.612 40.4579 31.1025 39.8294 31.1025C39.2009 31.1025 38.6914 31.612 38.6914 32.2405C38.6914 32.869 39.2009 33.3785 39.8294 33.3785Z"
      fill={color}
    />
    <path
      d="M47.4153 33.3785C48.0438 33.3785 48.5533 32.869 48.5533 32.2405C48.5533 31.612 48.0438 31.1025 47.4153 31.1025C46.7868 31.1025 46.2773 31.612 46.2773 32.2405C46.2773 32.869 46.7868 33.3785 47.4153 33.3785Z"
      fill={color}
    />
    <path
      d="M55.0022 33.3785C55.6307 33.3785 56.1402 32.869 56.1402 32.2405C56.1402 31.612 55.6307 31.1025 55.0022 31.1025C54.3737 31.1025 53.8643 31.612 53.8643 32.2405C53.8643 32.869 54.3737 33.3785 55.0022 33.3785Z"
      fill={color}
    />
    <path
      d="M62.5882 33.3785C63.2167 33.3785 63.7261 32.869 63.7261 32.2405C63.7261 31.612 63.2167 31.1025 62.5882 31.1025C61.9597 31.1025 61.4502 31.612 61.4502 32.2405C61.4502 32.869 61.9597 33.3785 62.5882 33.3785Z"
      fill={color}
    />
    <path
      d="M73.9681 33.3785C74.5965 33.3785 75.106 32.869 75.106 32.2405C75.106 31.612 74.5965 31.1025 73.9681 31.1025C73.3396 31.1025 72.8301 31.612 72.8301 32.2405C72.8301 32.869 73.3396 33.3785 73.9681 33.3785Z"
      fill={color}
    />
    <path
      d="M81.555 33.3785C82.1835 33.3785 82.6929 32.869 82.6929 32.2405C82.6929 31.612 82.1835 31.1025 81.555 31.1025C80.9265 31.1025 80.417 31.612 80.417 32.2405C80.417 32.869 80.9265 33.3785 81.555 33.3785Z"
      fill={color}
    />
    <path
      d="M89.1409 33.3785C89.7694 33.3785 90.2789 32.869 90.2789 32.2405C90.2789 31.612 89.7694 31.1025 89.1409 31.1025C88.5124 31.1025 88.0029 31.612 88.0029 32.2405C88.0029 32.869 88.5124 33.3785 89.1409 33.3785Z"
      fill={color}
    />
    <path
      d="M1.89676 37.1717C2.52525 37.1717 3.03474 36.6622 3.03474 36.0337C3.03474 35.4052 2.52525 34.8958 1.89676 34.8958C1.26828 34.8958 0.758789 35.4052 0.758789 36.0337C0.758789 36.6622 1.26828 37.1717 1.89676 37.1717Z"
      fill={color}
    />
    <path
      d="M9.4827 37.1717C10.1112 37.1717 10.6207 36.6622 10.6207 36.0337C10.6207 35.4052 10.1112 34.8958 9.4827 34.8958C8.85422 34.8958 8.34473 35.4052 8.34473 36.0337C8.34473 36.6622 8.85422 37.1717 9.4827 37.1717Z"
      fill={color}
    />
    <path
      d="M13.2766 37.1717C13.9051 37.1717 14.4146 36.6622 14.4146 36.0337C14.4146 35.4052 13.9051 34.8958 13.2766 34.8958C12.6482 34.8958 12.1387 35.4052 12.1387 36.0337C12.1387 36.6622 12.6482 37.1717 13.2766 37.1717Z"
      fill={color}
    />
    <path
      d="M17.0696 37.1717C17.6981 37.1717 18.2076 36.6622 18.2076 36.0337C18.2076 35.4052 17.6981 34.8958 17.0696 34.8958C16.4411 34.8958 15.9316 35.4052 15.9316 36.0337C15.9316 36.6622 16.4411 37.1717 17.0696 37.1717Z"
      fill={color}
    />
    <path
      d="M20.8626 37.1717C21.4911 37.1717 22.0006 36.6622 22.0006 36.0337C22.0006 35.4052 21.4911 34.8958 20.8626 34.8958C20.2341 34.8958 19.7246 35.4052 19.7246 36.0337C19.7246 36.6622 20.2341 37.1717 20.8626 37.1717Z"
      fill={color}
    />
    <path
      d="M28.4495 37.1717C29.078 37.1717 29.5875 36.6622 29.5875 36.0337C29.5875 35.4052 29.078 34.8958 28.4495 34.8958C27.821 34.8958 27.3115 35.4052 27.3115 36.0337C27.3115 36.6622 27.821 37.1717 28.4495 37.1717Z"
      fill={color}
    />
    <path
      d="M32.2425 37.1717C32.871 37.1717 33.3804 36.6622 33.3804 36.0337C33.3804 35.4052 32.871 34.8958 32.2425 34.8958C31.614 34.8958 31.1045 35.4052 31.1045 36.0337C31.1045 36.6622 31.614 37.1717 32.2425 37.1717Z"
      fill={color}
    />
    <path
      d="M36.0354 37.1717C36.6639 37.1717 37.1734 36.6622 37.1734 36.0337C37.1734 35.4052 36.6639 34.8958 36.0354 34.8958C35.4069 34.8958 34.8975 35.4052 34.8975 36.0337C34.8975 36.6622 35.4069 37.1717 36.0354 37.1717Z"
      fill={color}
    />
    <path
      d="M51.2093 37.1717C51.8378 37.1717 52.3472 36.6622 52.3472 36.0337C52.3472 35.4052 51.8378 34.8958 51.2093 34.8958C50.5808 34.8958 50.0713 35.4052 50.0713 36.0337C50.0713 36.6622 50.5808 37.1717 51.2093 37.1717Z"
      fill={color}
    />
    <path
      d="M58.7952 37.1717C59.4237 37.1717 59.9332 36.6622 59.9332 36.0337C59.9332 35.4052 59.4237 34.8958 58.7952 34.8958C58.1667 34.8958 57.6572 35.4052 57.6572 36.0337C57.6572 36.6622 58.1667 37.1717 58.7952 37.1717Z"
      fill={color}
    />
    <path
      d="M62.5882 37.1717C63.2167 37.1717 63.7261 36.6622 63.7261 36.0337C63.7261 35.4052 63.2167 34.8958 62.5882 34.8958C61.9597 34.8958 61.4502 35.4052 61.4502 36.0337C61.4502 36.6622 61.9597 37.1717 62.5882 37.1717Z"
      fill={color}
    />
    <path
      d="M77.762 37.1717C78.3905 37.1717 78.9 36.6622 78.9 36.0337C78.9 35.4052 78.3905 34.8958 77.762 34.8958C77.1335 34.8958 76.624 35.4052 76.624 36.0337C76.624 36.6622 77.1335 37.1717 77.762 37.1717Z"
      fill={color}
    />
    <path
      d="M81.555 37.1717C82.1835 37.1717 82.6929 36.6622 82.6929 36.0337C82.6929 35.4052 82.1835 34.8958 81.555 34.8958C80.9265 34.8958 80.417 35.4052 80.417 36.0337C80.417 36.6622 80.9265 37.1717 81.555 37.1717Z"
      fill={color}
    />
    <path
      d="M85.3479 37.1717C85.9764 37.1717 86.4859 36.6622 86.4859 36.0337C86.4859 35.4052 85.9764 34.8958 85.3479 34.8958C84.7194 34.8958 84.21 35.4052 84.21 36.0337C84.21 36.6622 84.7194 37.1717 85.3479 37.1717Z"
      fill={color}
    />
    <path
      d="M96.7278 37.1717C97.3563 37.1717 97.8658 36.6622 97.8658 36.0337C97.8658 35.4052 97.3563 34.8958 96.7278 34.8958C96.0993 34.8958 95.5898 35.4052 95.5898 36.0337C95.5898 36.6622 96.0993 37.1717 96.7278 37.1717Z"
      fill={color}
    />
    <path
      d="M108.108 37.1717C108.736 37.1717 109.246 36.6622 109.246 36.0337C109.246 35.4052 108.736 34.8958 108.108 34.8958C107.479 34.8958 106.97 35.4052 106.97 36.0337C106.97 36.6622 107.479 37.1717 108.108 37.1717Z"
      fill={color}
    />
    <path
      d="M24.6565 40.9649C25.285 40.9649 25.7945 40.4554 25.7945 39.8269C25.7945 39.1985 25.285 38.689 24.6565 38.689C24.028 38.689 23.5186 39.1985 23.5186 39.8269C23.5186 40.4554 24.028 40.9649 24.6565 40.9649Z"
      fill={color}
    />
    <path
      d="M28.4495 40.9649C29.078 40.9649 29.5875 40.4554 29.5875 39.8269C29.5875 39.1985 29.078 38.689 28.4495 38.689C27.821 38.689 27.3115 39.1985 27.3115 39.8269C27.3115 40.4554 27.821 40.9649 28.4495 40.9649Z"
      fill={color}
    />
    <path
      d="M36.0354 40.9649C36.6639 40.9649 37.1734 40.4554 37.1734 39.8269C37.1734 39.1985 36.6639 38.689 36.0354 38.689C35.4069 38.689 34.8975 39.1985 34.8975 39.8269C34.8975 40.4554 35.4069 40.9649 36.0354 40.9649Z"
      fill={color}
    />
    <path
      d="M39.8294 40.9649C40.4579 40.9649 40.9674 40.4554 40.9674 39.8269C40.9674 39.1985 40.4579 38.689 39.8294 38.689C39.2009 38.689 38.6914 39.1985 38.6914 39.8269C38.6914 40.4554 39.2009 40.9649 39.8294 40.9649Z"
      fill={color}
    />
    <path
      d="M47.4153 40.9649C48.0438 40.9649 48.5533 40.4554 48.5533 39.8269C48.5533 39.1985 48.0438 38.689 47.4153 38.689C46.7868 38.689 46.2773 39.1985 46.2773 39.8269C46.2773 40.4554 46.7868 40.9649 47.4153 40.9649Z"
      fill={color}
    />
    <path
      d="M51.2093 40.9649C51.8378 40.9649 52.3472 40.4554 52.3472 39.8269C52.3472 39.1985 51.8378 38.689 51.2093 38.689C50.5808 38.689 50.0713 39.1985 50.0713 39.8269C50.0713 40.4554 50.5808 40.9649 51.2093 40.9649Z"
      fill={color}
    />
    <path
      d="M55.0022 40.9649C55.6307 40.9649 56.1402 40.4554 56.1402 39.8269C56.1402 39.1985 55.6307 38.689 55.0022 38.689C54.3737 38.689 53.8643 39.1985 53.8643 39.8269C53.8643 40.4554 54.3737 40.9649 55.0022 40.9649Z"
      fill={color}
    />
    <path
      d="M58.7952 40.9649C59.4237 40.9649 59.9332 40.4554 59.9332 39.8269C59.9332 39.1985 59.4237 38.689 58.7952 38.689C58.1667 38.689 57.6572 39.1985 57.6572 39.8269C57.6572 40.4554 58.1667 40.9649 58.7952 40.9649Z"
      fill={color}
    />
    <path
      d="M66.3821 40.9649C67.0106 40.9649 67.5201 40.4554 67.5201 39.8269C67.5201 39.1985 67.0106 38.689 66.3821 38.689C65.7536 38.689 65.2441 39.1985 65.2441 39.8269C65.2441 40.4554 65.7536 40.9649 66.3821 40.9649Z"
      fill={color}
    />
    <path
      d="M73.9681 40.9649C74.5965 40.9649 75.106 40.4554 75.106 39.8269C75.106 39.1985 74.5965 38.689 73.9681 38.689C73.3396 38.689 72.8301 39.1985 72.8301 39.8269C72.8301 40.4554 73.3396 40.9649 73.9681 40.9649Z"
      fill={color}
    />
    <path
      d="M81.555 40.9649C82.1835 40.9649 82.6929 40.4554 82.6929 39.8269C82.6929 39.1985 82.1835 38.689 81.555 38.689C80.9265 38.689 80.417 39.1985 80.417 39.8269C80.417 40.4554 80.9265 40.9649 81.555 40.9649Z"
      fill={color}
    />
    <path
      d="M96.7278 40.9649C97.3563 40.9649 97.8658 40.4554 97.8658 39.8269C97.8658 39.1985 97.3563 38.689 96.7278 38.689C96.0993 38.689 95.5898 39.1985 95.5898 39.8269C95.5898 40.4554 96.0993 40.9649 96.7278 40.9649Z"
      fill={color}
    />
    <path
      d="M100.521 40.9649C101.149 40.9649 101.659 40.4554 101.659 39.8269C101.659 39.1985 101.149 38.689 100.521 38.689C99.8923 38.689 99.3828 39.1985 99.3828 39.8269C99.3828 40.4554 99.8923 40.9649 100.521 40.9649Z"
      fill={color}
    />
    <path
      d="M104.315 40.9649C104.943 40.9649 105.453 40.4554 105.453 39.8269C105.453 39.1985 104.943 38.689 104.315 38.689C103.686 38.689 103.177 39.1985 103.177 39.8269C103.177 40.4554 103.686 40.9649 104.315 40.9649Z"
      fill={color}
    />
    <path
      d="M13.2766 44.7581C13.9051 44.7581 14.4146 44.2486 14.4146 43.6202C14.4146 42.9917 13.9051 42.4822 13.2766 42.4822C12.6482 42.4822 12.1387 42.9917 12.1387 43.6202C12.1387 44.2486 12.6482 44.7581 13.2766 44.7581Z"
      fill={color}
    />
    <path
      d="M28.4495 44.7581C29.078 44.7581 29.5875 44.2486 29.5875 43.6202C29.5875 42.9917 29.078 42.4822 28.4495 42.4822C27.821 42.4822 27.3115 42.9917 27.3115 43.6202C27.3115 44.2486 27.821 44.7581 28.4495 44.7581Z"
      fill={color}
    />
    <path
      d="M32.2425 44.7581C32.871 44.7581 33.3804 44.2486 33.3804 43.6202C33.3804 42.9917 32.871 42.4822 32.2425 42.4822C31.614 42.4822 31.1045 42.9917 31.1045 43.6202C31.1045 44.2486 31.614 44.7581 32.2425 44.7581Z"
      fill={color}
    />
    <path
      d="M36.0354 44.7581C36.6639 44.7581 37.1734 44.2486 37.1734 43.6202C37.1734 42.9917 36.6639 42.4822 36.0354 42.4822C35.4069 42.4822 34.8975 42.9917 34.8975 43.6202C34.8975 44.2486 35.4069 44.7581 36.0354 44.7581Z"
      fill={color}
    />
    <path
      d="M39.8294 44.7581C40.4579 44.7581 40.9674 44.2486 40.9674 43.6202C40.9674 42.9917 40.4579 42.4822 39.8294 42.4822C39.2009 42.4822 38.6914 42.9917 38.6914 43.6202C38.6914 44.2486 39.2009 44.7581 39.8294 44.7581Z"
      fill={color}
    />
    <path
      d="M43.6223 44.7581C44.2508 44.7581 44.7603 44.2486 44.7603 43.6202C44.7603 42.9917 44.2508 42.4822 43.6223 42.4822C42.9939 42.4822 42.4844 42.9917 42.4844 43.6202C42.4844 44.2486 42.9939 44.7581 43.6223 44.7581Z"
      fill={color}
    />
    <path
      d="M51.2093 44.7581C51.8378 44.7581 52.3472 44.2486 52.3472 43.6202C52.3472 42.9917 51.8378 42.4822 51.2093 42.4822C50.5808 42.4822 50.0713 42.9917 50.0713 43.6202C50.0713 44.2486 50.5808 44.7581 51.2093 44.7581Z"
      fill={color}
    />
    <path
      d="M62.5882 44.7581C63.2167 44.7581 63.7261 44.2486 63.7261 43.6202C63.7261 42.9917 63.2167 42.4822 62.5882 42.4822C61.9597 42.4822 61.4502 42.9917 61.4502 43.6202C61.4502 44.2486 61.9597 44.7581 62.5882 44.7581Z"
      fill={color}
    />
    <path
      d="M70.1751 44.7581C70.8036 44.7581 71.3131 44.2486 71.3131 43.6202C71.3131 42.9917 70.8036 42.4822 70.1751 42.4822C69.5466 42.4822 69.0371 42.9917 69.0371 43.6202C69.0371 44.2486 69.5466 44.7581 70.1751 44.7581Z"
      fill={color}
    />
    <path
      d="M73.9681 44.7581C74.5965 44.7581 75.106 44.2486 75.106 43.6202C75.106 42.9917 74.5965 42.4822 73.9681 42.4822C73.3396 42.4822 72.8301 42.9917 72.8301 43.6202C72.8301 44.2486 73.3396 44.7581 73.9681 44.7581Z"
      fill={color}
    />
    <path
      d="M77.762 44.7581C78.3905 44.7581 78.9 44.2486 78.9 43.6202C78.9 42.9917 78.3905 42.4822 77.762 42.4822C77.1335 42.4822 76.624 42.9917 76.624 43.6202C76.624 44.2486 77.1335 44.7581 77.762 44.7581Z"
      fill={color}
    />
    <path
      d="M89.1409 44.7581C89.7694 44.7581 90.2789 44.2486 90.2789 43.6202C90.2789 42.9917 89.7694 42.4822 89.1409 42.4822C88.5124 42.4822 88.0029 42.9917 88.0029 43.6202C88.0029 44.2486 88.5124 44.7581 89.1409 44.7581Z"
      fill={color}
    />
    <path
      d="M92.9348 44.7581C93.5633 44.7581 94.0728 44.2486 94.0728 43.6202C94.0728 42.9917 93.5633 42.4822 92.9348 42.4822C92.3064 42.4822 91.7969 42.9917 91.7969 43.6202C91.7969 44.2486 92.3064 44.7581 92.9348 44.7581Z"
      fill={color}
    />
    <path
      d="M100.521 44.7581C101.149 44.7581 101.659 44.2486 101.659 43.6202C101.659 42.9917 101.149 42.4822 100.521 42.4822C99.8923 42.4822 99.3828 42.9917 99.3828 43.6202C99.3828 44.2486 99.8923 44.7581 100.521 44.7581Z"
      fill={color}
    />
    <path
      d="M104.315 44.7581C104.943 44.7581 105.453 44.2486 105.453 43.6202C105.453 42.9917 104.943 42.4822 104.315 42.4822C103.686 42.4822 103.177 42.9917 103.177 43.6202C103.177 44.2486 103.686 44.7581 104.315 44.7581Z"
      fill={color}
    />
    <path
      d="M1.89676 48.5516C2.52525 48.5516 3.03474 48.0421 3.03474 47.4136C3.03474 46.7851 2.52525 46.2756 1.89676 46.2756C1.26828 46.2756 0.758789 46.7851 0.758789 47.4136C0.758789 48.0421 1.26828 48.5516 1.89676 48.5516Z"
      fill={color}
    />
    <path
      d="M5.68973 48.5516C6.31822 48.5516 6.82771 48.0421 6.82771 47.4136C6.82771 46.7851 6.31822 46.2756 5.68973 46.2756C5.06125 46.2756 4.55176 46.7851 4.55176 47.4136C4.55176 48.0421 5.06125 48.5516 5.68973 48.5516Z"
      fill={color}
    />
    <path
      d="M9.4827 48.5516C10.1112 48.5516 10.6207 48.0421 10.6207 47.4136C10.6207 46.7851 10.1112 46.2756 9.4827 46.2756C8.85422 46.2756 8.34473 46.7851 8.34473 47.4136C8.34473 48.0421 8.85422 48.5516 9.4827 48.5516Z"
      fill={color}
    />
    <path
      d="M20.8626 48.5516C21.4911 48.5516 22.0006 48.0421 22.0006 47.4136C22.0006 46.7851 21.4911 46.2756 20.8626 46.2756C20.2341 46.2756 19.7246 46.7851 19.7246 47.4136C19.7246 48.0421 20.2341 48.5516 20.8626 48.5516Z"
      fill={color}
    />
    <path
      d="M24.6565 48.5516C25.285 48.5516 25.7945 48.0421 25.7945 47.4136C25.7945 46.7851 25.285 46.2756 24.6565 46.2756C24.028 46.2756 23.5186 46.7851 23.5186 47.4136C23.5186 48.0421 24.028 48.5516 24.6565 48.5516Z"
      fill={color}
    />
    <path
      d="M28.4495 48.5516C29.078 48.5516 29.5875 48.0421 29.5875 47.4136C29.5875 46.7851 29.078 46.2756 28.4495 46.2756C27.821 46.2756 27.3115 46.7851 27.3115 47.4136C27.3115 48.0421 27.821 48.5516 28.4495 48.5516Z"
      fill={color}
    />
    <path
      d="M32.2425 48.5516C32.871 48.5516 33.3804 48.0421 33.3804 47.4136C33.3804 46.7851 32.871 46.2756 32.2425 46.2756C31.614 46.2756 31.1045 46.7851 31.1045 47.4136C31.1045 48.0421 31.614 48.5516 32.2425 48.5516Z"
      fill={color}
    />
    <path
      d="M47.4153 48.5516C48.0438 48.5516 48.5533 48.0421 48.5533 47.4136C48.5533 46.7851 48.0438 46.2756 47.4153 46.2756C46.7868 46.2756 46.2773 46.7851 46.2773 47.4136C46.2773 48.0421 46.7868 48.5516 47.4153 48.5516Z"
      fill={color}
    />
    <path
      d="M55.0022 48.5516C55.6307 48.5516 56.1402 48.0421 56.1402 47.4136C56.1402 46.7851 55.6307 46.2756 55.0022 46.2756C54.3737 46.2756 53.8643 46.7851 53.8643 47.4136C53.8643 48.0421 54.3737 48.5516 55.0022 48.5516Z"
      fill={color}
    />
    <path
      d="M58.7952 48.5516C59.4237 48.5516 59.9332 48.0421 59.9332 47.4136C59.9332 46.7851 59.4237 46.2756 58.7952 46.2756C58.1667 46.2756 57.6572 46.7851 57.6572 47.4136C57.6572 48.0421 58.1667 48.5516 58.7952 48.5516Z"
      fill={color}
    />
    <path
      d="M66.3821 48.5516C67.0106 48.5516 67.5201 48.0421 67.5201 47.4136C67.5201 46.7851 67.0106 46.2756 66.3821 46.2756C65.7536 46.2756 65.2441 46.7851 65.2441 47.4136C65.2441 48.0421 65.7536 48.5516 66.3821 48.5516Z"
      fill={color}
    />
    <path
      d="M73.9681 48.5516C74.5965 48.5516 75.106 48.0421 75.106 47.4136C75.106 46.7851 74.5965 46.2756 73.9681 46.2756C73.3396 46.2756 72.8301 46.7851 72.8301 47.4136C72.8301 48.0421 73.3396 48.5516 73.9681 48.5516Z"
      fill={color}
    />
    <path
      d="M77.762 48.5516C78.3905 48.5516 78.9 48.0421 78.9 47.4136C78.9 46.7851 78.3905 46.2756 77.762 46.2756C77.1335 46.2756 76.624 46.7851 76.624 47.4136C76.624 48.0421 77.1335 48.5516 77.762 48.5516Z"
      fill={color}
    />
    <path
      d="M85.3479 48.5516C85.9764 48.5516 86.4859 48.0421 86.4859 47.4136C86.4859 46.7851 85.9764 46.2756 85.3479 46.2756C84.7194 46.2756 84.21 46.7851 84.21 47.4136C84.21 48.0421 84.7194 48.5516 85.3479 48.5516Z"
      fill={color}
    />
    <path
      d="M96.7278 48.5516C97.3563 48.5516 97.8658 48.0421 97.8658 47.4136C97.8658 46.7851 97.3563 46.2756 96.7278 46.2756C96.0993 46.2756 95.5898 46.7851 95.5898 47.4136C95.5898 48.0421 96.0993 48.5516 96.7278 48.5516Z"
      fill={color}
    />
    <path
      d="M104.315 48.5516C104.943 48.5516 105.453 48.0421 105.453 47.4136C105.453 46.7851 104.943 46.2756 104.315 46.2756C103.686 46.2756 103.177 46.7851 103.177 47.4136C103.177 48.0421 103.686 48.5516 104.315 48.5516Z"
      fill={color}
    />
    <path
      d="M108.108 48.5516C108.736 48.5516 109.246 48.0421 109.246 47.4136C109.246 46.7851 108.736 46.2756 108.108 46.2756C107.479 46.2756 106.97 46.7851 106.97 47.4136C106.97 48.0421 107.479 48.5516 108.108 48.5516Z"
      fill={color}
    />
    <path
      d="M5.68973 52.3448C6.31822 52.3448 6.82771 51.8353 6.82771 51.2068C6.82771 50.5783 6.31822 50.0688 5.68973 50.0688C5.06125 50.0688 4.55176 50.5783 4.55176 51.2068C4.55176 51.8353 5.06125 52.3448 5.68973 52.3448Z"
      fill={color}
    />
    <path
      d="M9.4827 52.3448C10.1112 52.3448 10.6207 51.8353 10.6207 51.2068C10.6207 50.5783 10.1112 50.0688 9.4827 50.0688C8.85422 50.0688 8.34473 50.5783 8.34473 51.2068C8.34473 51.8353 8.85422 52.3448 9.4827 52.3448Z"
      fill={color}
    />
    <path
      d="M13.2766 52.3448C13.9051 52.3448 14.4146 51.8353 14.4146 51.2068C14.4146 50.5783 13.9051 50.0688 13.2766 50.0688C12.6482 50.0688 12.1387 50.5783 12.1387 51.2068C12.1387 51.8353 12.6482 52.3448 13.2766 52.3448Z"
      fill={color}
    />
    <path
      d="M17.0696 52.3448C17.6981 52.3448 18.2076 51.8353 18.2076 51.2068C18.2076 50.5783 17.6981 50.0688 17.0696 50.0688C16.4411 50.0688 15.9316 50.5783 15.9316 51.2068C15.9316 51.8353 16.4411 52.3448 17.0696 52.3448Z"
      fill={color}
    />
    <path
      d="M28.4495 52.3448C29.078 52.3448 29.5875 51.8353 29.5875 51.2068C29.5875 50.5783 29.078 50.0688 28.4495 50.0688C27.821 50.0688 27.3115 50.5783 27.3115 51.2068C27.3115 51.8353 27.821 52.3448 28.4495 52.3448Z"
      fill={color}
    />
    <path
      d="M32.2425 52.3448C32.871 52.3448 33.3804 51.8353 33.3804 51.2068C33.3804 50.5783 32.871 50.0688 32.2425 50.0688C31.614 50.0688 31.1045 50.5783 31.1045 51.2068C31.1045 51.8353 31.614 52.3448 32.2425 52.3448Z"
      fill={color}
    />
    <path
      d="M43.6223 52.3448C44.2508 52.3448 44.7603 51.8353 44.7603 51.2068C44.7603 50.5783 44.2508 50.0688 43.6223 50.0688C42.9939 50.0688 42.4844 50.5783 42.4844 51.2068C42.4844 51.8353 42.9939 52.3448 43.6223 52.3448Z"
      fill={color}
    />
    <path
      d="M51.2093 52.3448C51.8378 52.3448 52.3472 51.8353 52.3472 51.2068C52.3472 50.5783 51.8378 50.0688 51.2093 50.0688C50.5808 50.0688 50.0713 50.5783 50.0713 51.2068C50.0713 51.8353 50.5808 52.3448 51.2093 52.3448Z"
      fill={color}
    />
    <path
      d="M55.0022 52.3448C55.6307 52.3448 56.1402 51.8353 56.1402 51.2068C56.1402 50.5783 55.6307 50.0688 55.0022 50.0688C54.3737 50.0688 53.8643 50.5783 53.8643 51.2068C53.8643 51.8353 54.3737 52.3448 55.0022 52.3448Z"
      fill={color}
    />
    <path
      d="M58.7952 52.3448C59.4237 52.3448 59.9332 51.8353 59.9332 51.2068C59.9332 50.5783 59.4237 50.0688 58.7952 50.0688C58.1667 50.0688 57.6572 50.5783 57.6572 51.2068C57.6572 51.8353 58.1667 52.3448 58.7952 52.3448Z"
      fill={color}
    />
    <path
      d="M62.5882 52.3448C63.2167 52.3448 63.7261 51.8353 63.7261 51.2068C63.7261 50.5783 63.2167 50.0688 62.5882 50.0688C61.9597 50.0688 61.4502 50.5783 61.4502 51.2068C61.4502 51.8353 61.9597 52.3448 62.5882 52.3448Z"
      fill={color}
    />
    <path
      d="M73.9681 52.3448C74.5965 52.3448 75.106 51.8353 75.106 51.2068C75.106 50.5783 74.5965 50.0688 73.9681 50.0688C73.3396 50.0688 72.8301 50.5783 72.8301 51.2068C72.8301 51.8353 73.3396 52.3448 73.9681 52.3448Z"
      fill={color}
    />
    <path
      d="M77.762 52.3448C78.3905 52.3448 78.9 51.8353 78.9 51.2068C78.9 50.5783 78.3905 50.0688 77.762 50.0688C77.1335 50.0688 76.624 50.5783 76.624 51.2068C76.624 51.8353 77.1335 52.3448 77.762 52.3448Z"
      fill={color}
    />
    <path
      d="M81.555 52.3448C82.1835 52.3448 82.6929 51.8353 82.6929 51.2068C82.6929 50.5783 82.1835 50.0688 81.555 50.0688C80.9265 50.0688 80.417 50.5783 80.417 51.2068C80.417 51.8353 80.9265 52.3448 81.555 52.3448Z"
      fill={color}
    />
    <path
      d="M5.68973 56.138C6.31822 56.138 6.82771 55.6285 6.82771 55C6.82771 54.3715 6.31822 53.8621 5.68973 53.8621C5.06125 53.8621 4.55176 54.3715 4.55176 55C4.55176 55.6285 5.06125 56.138 5.68973 56.138Z"
      fill={color}
    />
    <path
      d="M17.0696 56.138C17.6981 56.138 18.2076 55.6285 18.2076 55C18.2076 54.3715 17.6981 53.8621 17.0696 53.8621C16.4411 53.8621 15.9316 54.3715 15.9316 55C15.9316 55.6285 16.4411 56.138 17.0696 56.138Z"
      fill={color}
    />
    <path
      d="M24.6565 56.138C25.285 56.138 25.7945 55.6285 25.7945 55C25.7945 54.3715 25.285 53.8621 24.6565 53.8621C24.028 53.8621 23.5186 54.3715 23.5186 55C23.5186 55.6285 24.028 56.138 24.6565 56.138Z"
      fill={color}
    />
    <path
      d="M28.4495 56.138C29.078 56.138 29.5875 55.6285 29.5875 55C29.5875 54.3715 29.078 53.8621 28.4495 53.8621C27.821 53.8621 27.3115 54.3715 27.3115 55C27.3115 55.6285 27.821 56.138 28.4495 56.138Z"
      fill={color}
    />
    <path
      d="M32.2425 56.138C32.871 56.138 33.3804 55.6285 33.3804 55C33.3804 54.3715 32.871 53.8621 32.2425 53.8621C31.614 53.8621 31.1045 54.3715 31.1045 55C31.1045 55.6285 31.614 56.138 32.2425 56.138Z"
      fill={color}
    />
    <path
      d="M36.0354 56.138C36.6639 56.138 37.1734 55.6285 37.1734 55C37.1734 54.3715 36.6639 53.8621 36.0354 53.8621C35.4069 53.8621 34.8975 54.3715 34.8975 55C34.8975 55.6285 35.4069 56.138 36.0354 56.138Z"
      fill={color}
    />
    <path
      d="M39.8294 56.138C40.4579 56.138 40.9674 55.6285 40.9674 55C40.9674 54.3715 40.4579 53.8621 39.8294 53.8621C39.2009 53.8621 38.6914 54.3715 38.6914 55C38.6914 55.6285 39.2009 56.138 39.8294 56.138Z"
      fill={color}
    />
    <path
      d="M51.2093 56.138C51.8378 56.138 52.3472 55.6285 52.3472 55C52.3472 54.3715 51.8378 53.8621 51.2093 53.8621C50.5808 53.8621 50.0713 54.3715 50.0713 55C50.0713 55.6285 50.5808 56.138 51.2093 56.138Z"
      fill={color}
    />
    <path
      d="M55.0022 56.138C55.6307 56.138 56.1402 55.6285 56.1402 55C56.1402 54.3715 55.6307 53.8621 55.0022 53.8621C54.3737 53.8621 53.8643 54.3715 53.8643 55C53.8643 55.6285 54.3737 56.138 55.0022 56.138Z"
      fill={color}
    />
    <path
      d="M66.3821 56.138C67.0106 56.138 67.5201 55.6285 67.5201 55C67.5201 54.3715 67.0106 53.8621 66.3821 53.8621C65.7536 53.8621 65.2441 54.3715 65.2441 55C65.2441 55.6285 65.7536 56.138 66.3821 56.138Z"
      fill={color}
    />
    <path
      d="M70.1751 56.138C70.8036 56.138 71.3131 55.6285 71.3131 55C71.3131 54.3715 70.8036 53.8621 70.1751 53.8621C69.5466 53.8621 69.0371 54.3715 69.0371 55C69.0371 55.6285 69.5466 56.138 70.1751 56.138Z"
      fill={color}
    />
    <path
      d="M81.555 56.138C82.1835 56.138 82.6929 55.6285 82.6929 55C82.6929 54.3715 82.1835 53.8621 81.555 53.8621C80.9265 53.8621 80.417 54.3715 80.417 55C80.417 55.6285 80.9265 56.138 81.555 56.138Z"
      fill={color}
    />
    <path
      d="M92.9348 56.138C93.5633 56.138 94.0728 55.6285 94.0728 55C94.0728 54.3715 93.5633 53.8621 92.9348 53.8621C92.3064 53.8621 91.7969 54.3715 91.7969 55C91.7969 55.6285 92.3064 56.138 92.9348 56.138Z"
      fill={color}
    />
    <path
      d="M96.7278 56.138C97.3563 56.138 97.8658 55.6285 97.8658 55C97.8658 54.3715 97.3563 53.8621 96.7278 53.8621C96.0993 53.8621 95.5898 54.3715 95.5898 55C95.5898 55.6285 96.0993 56.138 96.7278 56.138Z"
      fill={color}
    />
    <path
      d="M100.521 56.138C101.149 56.138 101.659 55.6285 101.659 55C101.659 54.3715 101.149 53.8621 100.521 53.8621C99.8923 53.8621 99.3828 54.3715 99.3828 55C99.3828 55.6285 99.8923 56.138 100.521 56.138Z"
      fill={color}
    />
    <path
      d="M104.315 56.138C104.943 56.138 105.453 55.6285 105.453 55C105.453 54.3715 104.943 53.8621 104.315 53.8621C103.686 53.8621 103.177 54.3715 103.177 55C103.177 55.6285 103.686 56.138 104.315 56.138Z"
      fill={color}
    />
    <path
      d="M108.108 56.138C108.736 56.138 109.246 55.6285 109.246 55C109.246 54.3715 108.736 53.8621 108.108 53.8621C107.479 53.8621 106.97 54.3715 106.97 55C106.97 55.6285 107.479 56.138 108.108 56.138Z"
      fill={color}
    />
    <path
      d="M1.89676 59.9312C2.52525 59.9312 3.03474 59.4217 3.03474 58.7932C3.03474 58.1648 2.52525 57.6553 1.89676 57.6553C1.26828 57.6553 0.758789 58.1648 0.758789 58.7932C0.758789 59.4217 1.26828 59.9312 1.89676 59.9312Z"
      fill={color}
    />
    <path
      d="M5.68973 59.9312C6.31822 59.9312 6.82771 59.4217 6.82771 58.7932C6.82771 58.1648 6.31822 57.6553 5.68973 57.6553C5.06125 57.6553 4.55176 58.1648 4.55176 58.7932C4.55176 59.4217 5.06125 59.9312 5.68973 59.9312Z"
      fill={color}
    />
    <path
      d="M9.4827 59.9312C10.1112 59.9312 10.6207 59.4217 10.6207 58.7932C10.6207 58.1648 10.1112 57.6553 9.4827 57.6553C8.85422 57.6553 8.34473 58.1648 8.34473 58.7932C8.34473 59.4217 8.85422 59.9312 9.4827 59.9312Z"
      fill={color}
    />
    <path
      d="M13.2766 59.9312C13.9051 59.9312 14.4146 59.4217 14.4146 58.7932C14.4146 58.1648 13.9051 57.6553 13.2766 57.6553C12.6482 57.6553 12.1387 58.1648 12.1387 58.7932C12.1387 59.4217 12.6482 59.9312 13.2766 59.9312Z"
      fill={color}
    />
    <path
      d="M32.2425 59.9312C32.871 59.9312 33.3804 59.4217 33.3804 58.7932C33.3804 58.1648 32.871 57.6553 32.2425 57.6553C31.614 57.6553 31.1045 58.1648 31.1045 58.7932C31.1045 59.4217 31.614 59.9312 32.2425 59.9312Z"
      fill={color}
    />
    <path
      d="M36.0354 59.9312C36.6639 59.9312 37.1734 59.4217 37.1734 58.7932C37.1734 58.1648 36.6639 57.6553 36.0354 57.6553C35.4069 57.6553 34.8975 58.1648 34.8975 58.7932C34.8975 59.4217 35.4069 59.9312 36.0354 59.9312Z"
      fill={color}
    />
    <path
      d="M43.6223 59.9312C44.2508 59.9312 44.7603 59.4217 44.7603 58.7932C44.7603 58.1648 44.2508 57.6553 43.6223 57.6553C42.9939 57.6553 42.4844 58.1648 42.4844 58.7932C42.4844 59.4217 42.9939 59.9312 43.6223 59.9312Z"
      fill={color}
    />
    <path
      d="M47.4153 59.9312C48.0438 59.9312 48.5533 59.4217 48.5533 58.7932C48.5533 58.1648 48.0438 57.6553 47.4153 57.6553C46.7868 57.6553 46.2773 58.1648 46.2773 58.7932C46.2773 59.4217 46.7868 59.9312 47.4153 59.9312Z"
      fill={color}
    />
    <path
      d="M58.7952 59.9312C59.4237 59.9312 59.9332 59.4217 59.9332 58.7932C59.9332 58.1648 59.4237 57.6553 58.7952 57.6553C58.1667 57.6553 57.6572 58.1648 57.6572 58.7932C57.6572 59.4217 58.1667 59.9312 58.7952 59.9312Z"
      fill={color}
    />
    <path
      d="M66.3821 59.9312C67.0106 59.9312 67.5201 59.4217 67.5201 58.7932C67.5201 58.1648 67.0106 57.6553 66.3821 57.6553C65.7536 57.6553 65.2441 58.1648 65.2441 58.7932C65.2441 59.4217 65.7536 59.9312 66.3821 59.9312Z"
      fill={color}
    />
    <path
      d="M70.1751 59.9312C70.8036 59.9312 71.3131 59.4217 71.3131 58.7932C71.3131 58.1648 70.8036 57.6553 70.1751 57.6553C69.5466 57.6553 69.0371 58.1648 69.0371 58.7932C69.0371 59.4217 69.5466 59.9312 70.1751 59.9312Z"
      fill={color}
    />
    <path
      d="M73.9681 59.9312C74.5965 59.9312 75.106 59.4217 75.106 58.7932C75.106 58.1648 74.5965 57.6553 73.9681 57.6553C73.3396 57.6553 72.8301 58.1648 72.8301 58.7932C72.8301 59.4217 73.3396 59.9312 73.9681 59.9312Z"
      fill={color}
    />
    <path
      d="M77.762 59.9312C78.3905 59.9312 78.9 59.4217 78.9 58.7932C78.9 58.1648 78.3905 57.6553 77.762 57.6553C77.1335 57.6553 76.624 58.1648 76.624 58.7932C76.624 59.4217 77.1335 59.9312 77.762 59.9312Z"
      fill={color}
    />
    <path
      d="M81.555 59.9312C82.1835 59.9312 82.6929 59.4217 82.6929 58.7932C82.6929 58.1648 82.1835 57.6553 81.555 57.6553C80.9265 57.6553 80.417 58.1648 80.417 58.7932C80.417 59.4217 80.9265 59.9312 81.555 59.9312Z"
      fill={color}
    />
    <path
      d="M85.3479 59.9312C85.9764 59.9312 86.4859 59.4217 86.4859 58.7932C86.4859 58.1648 85.9764 57.6553 85.3479 57.6553C84.7194 57.6553 84.21 58.1648 84.21 58.7932C84.21 59.4217 84.7194 59.9312 85.3479 59.9312Z"
      fill={color}
    />
    <path
      d="M89.1409 59.9312C89.7694 59.9312 90.2789 59.4217 90.2789 58.7932C90.2789 58.1648 89.7694 57.6553 89.1409 57.6553C88.5124 57.6553 88.0029 58.1648 88.0029 58.7932C88.0029 59.4217 88.5124 59.9312 89.1409 59.9312Z"
      fill={color}
    />
    <path
      d="M92.9348 59.9312C93.5633 59.9312 94.0728 59.4217 94.0728 58.7932C94.0728 58.1648 93.5633 57.6553 92.9348 57.6553C92.3064 57.6553 91.7969 58.1648 91.7969 58.7932C91.7969 59.4217 92.3064 59.9312 92.9348 59.9312Z"
      fill={color}
    />
    <path
      d="M96.7278 59.9312C97.3563 59.9312 97.8658 59.4217 97.8658 58.7932C97.8658 58.1648 97.3563 57.6553 96.7278 57.6553C96.0993 57.6553 95.5898 58.1648 95.5898 58.7932C95.5898 59.4217 96.0993 59.9312 96.7278 59.9312Z"
      fill={color}
    />
    <path
      d="M104.315 59.9312C104.943 59.9312 105.453 59.4217 105.453 58.7932C105.453 58.1648 104.943 57.6553 104.315 57.6553C103.686 57.6553 103.177 58.1648 103.177 58.7932C103.177 59.4217 103.686 59.9312 104.315 59.9312Z"
      fill={color}
    />
    <path
      d="M5.68973 63.7244C6.31822 63.7244 6.82771 63.2149 6.82771 62.5865C6.82771 61.958 6.31822 61.4485 5.68973 61.4485C5.06125 61.4485 4.55176 61.958 4.55176 62.5865C4.55176 63.2149 5.06125 63.7244 5.68973 63.7244Z"
      fill={color}
    />
    <path
      d="M24.6565 63.7244C25.285 63.7244 25.7945 63.2149 25.7945 62.5865C25.7945 61.958 25.285 61.4485 24.6565 61.4485C24.028 61.4485 23.5186 61.958 23.5186 62.5865C23.5186 63.2149 24.028 63.7244 24.6565 63.7244Z"
      fill={color}
    />
    <path
      d="M28.4495 63.7244C29.078 63.7244 29.5875 63.2149 29.5875 62.5865C29.5875 61.958 29.078 61.4485 28.4495 61.4485C27.821 61.4485 27.3115 61.958 27.3115 62.5865C27.3115 63.2149 27.821 63.7244 28.4495 63.7244Z"
      fill={color}
    />
    <path
      d="M36.0354 63.7244C36.6639 63.7244 37.1734 63.2149 37.1734 62.5865C37.1734 61.958 36.6639 61.4485 36.0354 61.4485C35.4069 61.4485 34.8975 61.958 34.8975 62.5865C34.8975 63.2149 35.4069 63.7244 36.0354 63.7244Z"
      fill={color}
    />
    <path
      d="M39.8294 63.7244C40.4579 63.7244 40.9674 63.2149 40.9674 62.5865C40.9674 61.958 40.4579 61.4485 39.8294 61.4485C39.2009 61.4485 38.6914 61.958 38.6914 62.5865C38.6914 63.2149 39.2009 63.7244 39.8294 63.7244Z"
      fill={color}
    />
    <path
      d="M47.4153 63.7244C48.0438 63.7244 48.5533 63.2149 48.5533 62.5865C48.5533 61.958 48.0438 61.4485 47.4153 61.4485C46.7868 61.4485 46.2773 61.958 46.2773 62.5865C46.2773 63.2149 46.7868 63.7244 47.4153 63.7244Z"
      fill={color}
    />
    <path
      d="M51.2093 63.7244C51.8378 63.7244 52.3472 63.2149 52.3472 62.5865C52.3472 61.958 51.8378 61.4485 51.2093 61.4485C50.5808 61.4485 50.0713 61.958 50.0713 62.5865C50.0713 63.2149 50.5808 63.7244 51.2093 63.7244Z"
      fill={color}
    />
    <path
      d="M66.3821 63.7244C67.0106 63.7244 67.5201 63.2149 67.5201 62.5865C67.5201 61.958 67.0106 61.4485 66.3821 61.4485C65.7536 61.4485 65.2441 61.958 65.2441 62.5865C65.2441 63.2149 65.7536 63.7244 66.3821 63.7244Z"
      fill={color}
    />
    <path
      d="M77.762 63.7244C78.3905 63.7244 78.9 63.2149 78.9 62.5865C78.9 61.958 78.3905 61.4485 77.762 61.4485C77.1335 61.4485 76.624 61.958 76.624 62.5865C76.624 63.2149 77.1335 63.7244 77.762 63.7244Z"
      fill={color}
    />
    <path
      d="M81.555 63.7244C82.1835 63.7244 82.6929 63.2149 82.6929 62.5865C82.6929 61.958 82.1835 61.4485 81.555 61.4485C80.9265 61.4485 80.417 61.958 80.417 62.5865C80.417 63.2149 80.9265 63.7244 81.555 63.7244Z"
      fill={color}
    />
    <path
      d="M104.315 63.7244C104.943 63.7244 105.453 63.2149 105.453 62.5865C105.453 61.958 104.943 61.4485 104.315 61.4485C103.686 61.4485 103.177 61.958 103.177 62.5865C103.177 63.2149 103.686 63.7244 104.315 63.7244Z"
      fill={color}
    />
    <path
      d="M9.4827 67.5176C10.1112 67.5176 10.6207 67.0082 10.6207 66.3797C10.6207 65.7512 10.1112 65.2417 9.4827 65.2417C8.85422 65.2417 8.34473 65.7512 8.34473 66.3797C8.34473 67.0082 8.85422 67.5176 9.4827 67.5176Z"
      fill={color}
    />
    <path
      d="M13.2766 67.5176C13.9051 67.5176 14.4146 67.0082 14.4146 66.3797C14.4146 65.7512 13.9051 65.2417 13.2766 65.2417C12.6482 65.2417 12.1387 65.7512 12.1387 66.3797C12.1387 67.0082 12.6482 67.5176 13.2766 67.5176Z"
      fill={color}
    />
    <path
      d="M17.0696 67.5176C17.6981 67.5176 18.2076 67.0082 18.2076 66.3797C18.2076 65.7512 17.6981 65.2417 17.0696 65.2417C16.4411 65.2417 15.9316 65.7512 15.9316 66.3797C15.9316 67.0082 16.4411 67.5176 17.0696 67.5176Z"
      fill={color}
    />
    <path
      d="M20.8626 67.5176C21.4911 67.5176 22.0006 67.0082 22.0006 66.3797C22.0006 65.7512 21.4911 65.2417 20.8626 65.2417C20.2341 65.2417 19.7246 65.7512 19.7246 66.3797C19.7246 67.0082 20.2341 67.5176 20.8626 67.5176Z"
      fill={color}
    />
    <path
      d="M32.2425 67.5176C32.871 67.5176 33.3804 67.0082 33.3804 66.3797C33.3804 65.7512 32.871 65.2417 32.2425 65.2417C31.614 65.2417 31.1045 65.7512 31.1045 66.3797C31.1045 67.0082 31.614 67.5176 32.2425 67.5176Z"
      fill={color}
    />
    <path
      d="M36.0354 67.5176C36.6639 67.5176 37.1734 67.0082 37.1734 66.3797C37.1734 65.7512 36.6639 65.2417 36.0354 65.2417C35.4069 65.2417 34.8975 65.7512 34.8975 66.3797C34.8975 67.0082 35.4069 67.5176 36.0354 67.5176Z"
      fill={color}
    />
    <path
      d="M39.8294 67.5176C40.4579 67.5176 40.9674 67.0082 40.9674 66.3797C40.9674 65.7512 40.4579 65.2417 39.8294 65.2417C39.2009 65.2417 38.6914 65.7512 38.6914 66.3797C38.6914 67.0082 39.2009 67.5176 39.8294 67.5176Z"
      fill={color}
    />
    <path
      d="M43.6223 67.5176C44.2508 67.5176 44.7603 67.0082 44.7603 66.3797C44.7603 65.7512 44.2508 65.2417 43.6223 65.2417C42.9939 65.2417 42.4844 65.7512 42.4844 66.3797C42.4844 67.0082 42.9939 67.5176 43.6223 67.5176Z"
      fill={color}
    />
    <path
      d="M47.4153 67.5176C48.0438 67.5176 48.5533 67.0082 48.5533 66.3797C48.5533 65.7512 48.0438 65.2417 47.4153 65.2417C46.7868 65.2417 46.2773 65.7512 46.2773 66.3797C46.2773 67.0082 46.7868 67.5176 47.4153 67.5176Z"
      fill={color}
    />
    <path
      d="M51.2093 67.5176C51.8378 67.5176 52.3472 67.0082 52.3472 66.3797C52.3472 65.7512 51.8378 65.2417 51.2093 65.2417C50.5808 65.2417 50.0713 65.7512 50.0713 66.3797C50.0713 67.0082 50.5808 67.5176 51.2093 67.5176Z"
      fill={color}
    />
    <path
      d="M55.0022 67.5176C55.6307 67.5176 56.1402 67.0082 56.1402 66.3797C56.1402 65.7512 55.6307 65.2417 55.0022 65.2417C54.3737 65.2417 53.8643 65.7512 53.8643 66.3797C53.8643 67.0082 54.3737 67.5176 55.0022 67.5176Z"
      fill={color}
    />
    <path
      d="M58.7952 67.5176C59.4237 67.5176 59.9332 67.0082 59.9332 66.3797C59.9332 65.7512 59.4237 65.2417 58.7952 65.2417C58.1667 65.2417 57.6572 65.7512 57.6572 66.3797C57.6572 67.0082 58.1667 67.5176 58.7952 67.5176Z"
      fill={color}
    />
    <path
      d="M62.5882 67.5176C63.2167 67.5176 63.7261 67.0082 63.7261 66.3797C63.7261 65.7512 63.2167 65.2417 62.5882 65.2417C61.9597 65.2417 61.4502 65.7512 61.4502 66.3797C61.4502 67.0082 61.9597 67.5176 62.5882 67.5176Z"
      fill={color}
    />
    <path
      d="M66.3821 67.5176C67.0106 67.5176 67.5201 67.0082 67.5201 66.3797C67.5201 65.7512 67.0106 65.2417 66.3821 65.2417C65.7536 65.2417 65.2441 65.7512 65.2441 66.3797C65.2441 67.0082 65.7536 67.5176 66.3821 67.5176Z"
      fill={color}
    />
    <path
      d="M81.555 67.5176C82.1835 67.5176 82.6929 67.0082 82.6929 66.3797C82.6929 65.7512 82.1835 65.2417 81.555 65.2417C80.9265 65.2417 80.417 65.7512 80.417 66.3797C80.417 67.0082 80.9265 67.5176 81.555 67.5176Z"
      fill={color}
    />
    <path
      d="M85.3479 67.5176C85.9764 67.5176 86.4859 67.0082 86.4859 66.3797C86.4859 65.7512 85.9764 65.2417 85.3479 65.2417C84.7194 65.2417 84.21 65.7512 84.21 66.3797C84.21 67.0082 84.7194 67.5176 85.3479 67.5176Z"
      fill={color}
    />
    <path
      d="M89.1409 67.5176C89.7694 67.5176 90.2789 67.0082 90.2789 66.3797C90.2789 65.7512 89.7694 65.2417 89.1409 65.2417C88.5124 65.2417 88.0029 65.7512 88.0029 66.3797C88.0029 67.0082 88.5124 67.5176 89.1409 67.5176Z"
      fill={color}
    />
    <path
      d="M96.7278 67.5176C97.3563 67.5176 97.8658 67.0082 97.8658 66.3797C97.8658 65.7512 97.3563 65.2417 96.7278 65.2417C96.0993 65.2417 95.5898 65.7512 95.5898 66.3797C95.5898 67.0082 96.0993 67.5176 96.7278 67.5176Z"
      fill={color}
    />
    <path
      d="M108.108 67.5176C108.736 67.5176 109.246 67.0082 109.246 66.3797C109.246 65.7512 108.736 65.2417 108.108 65.2417C107.479 65.2417 106.97 65.7512 106.97 66.3797C106.97 67.0082 107.479 67.5176 108.108 67.5176Z"
      fill={color}
    />
    <path
      d="M1.89676 71.3109C2.52525 71.3109 3.03474 70.8014 3.03474 70.1729C3.03474 69.5444 2.52525 69.0349 1.89676 69.0349C1.26828 69.0349 0.758789 69.5444 0.758789 70.1729C0.758789 70.8014 1.26828 71.3109 1.89676 71.3109Z"
      fill={color}
    />
    <path
      d="M9.4827 71.3109C10.1112 71.3109 10.6207 70.8014 10.6207 70.1729C10.6207 69.5444 10.1112 69.0349 9.4827 69.0349C8.85422 69.0349 8.34473 69.5444 8.34473 70.1729C8.34473 70.8014 8.85422 71.3109 9.4827 71.3109Z"
      fill={color}
    />
    <path
      d="M17.0696 71.3109C17.6981 71.3109 18.2076 70.8014 18.2076 70.1729C18.2076 69.5444 17.6981 69.0349 17.0696 69.0349C16.4411 69.0349 15.9316 69.5444 15.9316 70.1729C15.9316 70.8014 16.4411 71.3109 17.0696 71.3109Z"
      fill={color}
    />
    <path
      d="M24.6565 71.3109C25.285 71.3109 25.7945 70.8014 25.7945 70.1729C25.7945 69.5444 25.285 69.0349 24.6565 69.0349C24.028 69.0349 23.5186 69.5444 23.5186 70.1729C23.5186 70.8014 24.028 71.3109 24.6565 71.3109Z"
      fill={color}
    />
    <path
      d="M28.4495 71.3109C29.078 71.3109 29.5875 70.8014 29.5875 70.1729C29.5875 69.5444 29.078 69.0349 28.4495 69.0349C27.821 69.0349 27.3115 69.5444 27.3115 70.1729C27.3115 70.8014 27.821 71.3109 28.4495 71.3109Z"
      fill={color}
    />
    <path
      d="M55.0022 71.3109C55.6307 71.3109 56.1402 70.8014 56.1402 70.1729C56.1402 69.5444 55.6307 69.0349 55.0022 69.0349C54.3737 69.0349 53.8643 69.5444 53.8643 70.1729C53.8643 70.8014 54.3737 71.3109 55.0022 71.3109Z"
      fill={color}
    />
    <path
      d="M58.7952 71.3109C59.4237 71.3109 59.9332 70.8014 59.9332 70.1729C59.9332 69.5444 59.4237 69.0349 58.7952 69.0349C58.1667 69.0349 57.6572 69.5444 57.6572 70.1729C57.6572 70.8014 58.1667 71.3109 58.7952 71.3109Z"
      fill={color}
    />
    <path
      d="M62.5882 71.3109C63.2167 71.3109 63.7261 70.8014 63.7261 70.1729C63.7261 69.5444 63.2167 69.0349 62.5882 69.0349C61.9597 69.0349 61.4502 69.5444 61.4502 70.1729C61.4502 70.8014 61.9597 71.3109 62.5882 71.3109Z"
      fill={color}
    />
    <path
      d="M66.3821 71.3109C67.0106 71.3109 67.5201 70.8014 67.5201 70.1729C67.5201 69.5444 67.0106 69.0349 66.3821 69.0349C65.7536 69.0349 65.2441 69.5444 65.2441 70.1729C65.2441 70.8014 65.7536 71.3109 66.3821 71.3109Z"
      fill={color}
    />
    <path
      d="M77.762 71.3109C78.3905 71.3109 78.9 70.8014 78.9 70.1729C78.9 69.5444 78.3905 69.0349 77.762 69.0349C77.1335 69.0349 76.624 69.5444 76.624 70.1729C76.624 70.8014 77.1335 71.3109 77.762 71.3109Z"
      fill={color}
    />
    <path
      d="M85.3479 71.3109C85.9764 71.3109 86.4859 70.8014 86.4859 70.1729C86.4859 69.5444 85.9764 69.0349 85.3479 69.0349C84.7194 69.0349 84.21 69.5444 84.21 70.1729C84.21 70.8014 84.7194 71.3109 85.3479 71.3109Z"
      fill={color}
    />
    <path
      d="M104.315 71.3109C104.943 71.3109 105.453 70.8014 105.453 70.1729C105.453 69.5444 104.943 69.0349 104.315 69.0349C103.686 69.0349 103.177 69.5444 103.177 70.1729C103.177 70.8014 103.686 71.3109 104.315 71.3109Z"
      fill={color}
    />
    <path
      d="M108.108 71.3109C108.736 71.3109 109.246 70.8014 109.246 70.1729C109.246 69.5444 108.736 69.0349 108.108 69.0349C107.479 69.0349 106.97 69.5444 106.97 70.1729C106.97 70.8014 107.479 71.3109 108.108 71.3109Z"
      fill={color}
    />
    <path
      d="M17.0696 75.1043C17.6981 75.1043 18.2076 74.5948 18.2076 73.9663C18.2076 73.3379 17.6981 72.8284 17.0696 72.8284C16.4411 72.8284 15.9316 73.3379 15.9316 73.9663C15.9316 74.5948 16.4411 75.1043 17.0696 75.1043Z"
      fill={color}
    />
    <path
      d="M20.8626 75.1043C21.4911 75.1043 22.0006 74.5948 22.0006 73.9663C22.0006 73.3379 21.4911 72.8284 20.8626 72.8284C20.2341 72.8284 19.7246 73.3379 19.7246 73.9663C19.7246 74.5948 20.2341 75.1043 20.8626 75.1043Z"
      fill={color}
    />
    <path
      d="M28.4495 75.1043C29.078 75.1043 29.5875 74.5948 29.5875 73.9663C29.5875 73.3379 29.078 72.8284 28.4495 72.8284C27.821 72.8284 27.3115 73.3379 27.3115 73.9663C27.3115 74.5948 27.821 75.1043 28.4495 75.1043Z"
      fill={color}
    />
    <path
      d="M36.0354 75.1043C36.6639 75.1043 37.1734 74.5948 37.1734 73.9663C37.1734 73.3379 36.6639 72.8284 36.0354 72.8284C35.4069 72.8284 34.8975 73.3379 34.8975 73.9663C34.8975 74.5948 35.4069 75.1043 36.0354 75.1043Z"
      fill={color}
    />
    <path
      d="M39.8294 75.1043C40.4579 75.1043 40.9674 74.5948 40.9674 73.9663C40.9674 73.3379 40.4579 72.8284 39.8294 72.8284C39.2009 72.8284 38.6914 73.3379 38.6914 73.9663C38.6914 74.5948 39.2009 75.1043 39.8294 75.1043Z"
      fill={color}
    />
    <path
      d="M43.6223 75.1043C44.2508 75.1043 44.7603 74.5948 44.7603 73.9663C44.7603 73.3379 44.2508 72.8284 43.6223 72.8284C42.9939 72.8284 42.4844 73.3379 42.4844 73.9663C42.4844 74.5948 42.9939 75.1043 43.6223 75.1043Z"
      fill={color}
    />
    <path
      d="M47.4153 75.1043C48.0438 75.1043 48.5533 74.5948 48.5533 73.9663C48.5533 73.3379 48.0438 72.8284 47.4153 72.8284C46.7868 72.8284 46.2773 73.3379 46.2773 73.9663C46.2773 74.5948 46.7868 75.1043 47.4153 75.1043Z"
      fill={color}
    />
    <path
      d="M55.0022 75.1043C55.6307 75.1043 56.1402 74.5948 56.1402 73.9663C56.1402 73.3379 55.6307 72.8284 55.0022 72.8284C54.3737 72.8284 53.8643 73.3379 53.8643 73.9663C53.8643 74.5948 54.3737 75.1043 55.0022 75.1043Z"
      fill={color}
    />
    <path
      d="M66.3821 75.1043C67.0106 75.1043 67.5201 74.5948 67.5201 73.9663C67.5201 73.3379 67.0106 72.8284 66.3821 72.8284C65.7536 72.8284 65.2441 73.3379 65.2441 73.9663C65.2441 74.5948 65.7536 75.1043 66.3821 75.1043Z"
      fill={color}
    />
    <path
      d="M73.9681 75.1043C74.5965 75.1043 75.106 74.5948 75.106 73.9663C75.106 73.3379 74.5965 72.8284 73.9681 72.8284C73.3396 72.8284 72.8301 73.3379 72.8301 73.9663C72.8301 74.5948 73.3396 75.1043 73.9681 75.1043Z"
      fill={color}
    />
    <path
      d="M77.762 75.1043C78.3905 75.1043 78.9 74.5948 78.9 73.9663C78.9 73.3379 78.3905 72.8284 77.762 72.8284C77.1335 72.8284 76.624 73.3379 76.624 73.9663C76.624 74.5948 77.1335 75.1043 77.762 75.1043Z"
      fill={color}
    />
    <path
      d="M85.3479 75.1043C85.9764 75.1043 86.4859 74.5948 86.4859 73.9663C86.4859 73.3379 85.9764 72.8284 85.3479 72.8284C84.7194 72.8284 84.21 73.3379 84.21 73.9663C84.21 74.5948 84.7194 75.1043 85.3479 75.1043Z"
      fill={color}
    />
    <path
      d="M104.315 75.1043C104.943 75.1043 105.453 74.5948 105.453 73.9663C105.453 73.3379 104.943 72.8284 104.315 72.8284C103.686 72.8284 103.177 73.3379 103.177 73.9663C103.177 74.5948 103.686 75.1043 104.315 75.1043Z"
      fill={color}
    />
    <path
      d="M108.108 75.1043C108.736 75.1043 109.246 74.5948 109.246 73.9663C109.246 73.3379 108.736 72.8284 108.108 72.8284C107.479 72.8284 106.97 73.3379 106.97 73.9663C106.97 74.5948 107.479 75.1043 108.108 75.1043Z"
      fill={color}
    />
    <path
      d="M1.89676 78.8975C2.52525 78.8975 3.03474 78.388 3.03474 77.7596C3.03474 77.1311 2.52525 76.6216 1.89676 76.6216C1.26828 76.6216 0.758789 77.1311 0.758789 77.7596C0.758789 78.388 1.26828 78.8975 1.89676 78.8975Z"
      fill={color}
    />
    <path
      d="M20.8626 78.8975C21.4911 78.8975 22.0006 78.388 22.0006 77.7596C22.0006 77.1311 21.4911 76.6216 20.8626 76.6216C20.2341 76.6216 19.7246 77.1311 19.7246 77.7596C19.7246 78.388 20.2341 78.8975 20.8626 78.8975Z"
      fill={color}
    />
    <path
      d="M24.6565 78.8975C25.285 78.8975 25.7945 78.388 25.7945 77.7596C25.7945 77.1311 25.285 76.6216 24.6565 76.6216C24.028 76.6216 23.5186 77.1311 23.5186 77.7596C23.5186 78.388 24.028 78.8975 24.6565 78.8975Z"
      fill={color}
    />
    <path
      d="M28.4495 78.8975C29.078 78.8975 29.5875 78.388 29.5875 77.7596C29.5875 77.1311 29.078 76.6216 28.4495 76.6216C27.821 76.6216 27.3115 77.1311 27.3115 77.7596C27.3115 78.388 27.821 78.8975 28.4495 78.8975Z"
      fill={color}
    />
    <path
      d="M43.6223 78.8975C44.2508 78.8975 44.7603 78.388 44.7603 77.7596C44.7603 77.1311 44.2508 76.6216 43.6223 76.6216C42.9939 76.6216 42.4844 77.1311 42.4844 77.7596C42.4844 78.388 42.9939 78.8975 43.6223 78.8975Z"
      fill={color}
    />
    <path
      d="M58.7952 78.8975C59.4237 78.8975 59.9332 78.388 59.9332 77.7596C59.9332 77.1311 59.4237 76.6216 58.7952 76.6216C58.1667 76.6216 57.6572 77.1311 57.6572 77.7596C57.6572 78.388 58.1667 78.8975 58.7952 78.8975Z"
      fill={color}
    />
    <path
      d="M66.3821 78.8975C67.0106 78.8975 67.5201 78.388 67.5201 77.7596C67.5201 77.1311 67.0106 76.6216 66.3821 76.6216C65.7536 76.6216 65.2441 77.1311 65.2441 77.7596C65.2441 78.388 65.7536 78.8975 66.3821 78.8975Z"
      fill={color}
    />
    <path
      d="M70.1751 78.8975C70.8036 78.8975 71.3131 78.388 71.3131 77.7596C71.3131 77.1311 70.8036 76.6216 70.1751 76.6216C69.5466 76.6216 69.0371 77.1311 69.0371 77.7596C69.0371 78.388 69.5466 78.8975 70.1751 78.8975Z"
      fill={color}
    />
    <path
      d="M73.9681 78.8975C74.5965 78.8975 75.106 78.388 75.106 77.7596C75.106 77.1311 74.5965 76.6216 73.9681 76.6216C73.3396 76.6216 72.8301 77.1311 72.8301 77.7596C72.8301 78.388 73.3396 78.8975 73.9681 78.8975Z"
      fill={color}
    />
    <path
      d="M77.762 78.8975C78.3905 78.8975 78.9 78.388 78.9 77.7596C78.9 77.1311 78.3905 76.6216 77.762 76.6216C77.1335 76.6216 76.624 77.1311 76.624 77.7596C76.624 78.388 77.1335 78.8975 77.762 78.8975Z"
      fill={color}
    />
    <path
      d="M81.555 78.8975C82.1835 78.8975 82.6929 78.388 82.6929 77.7596C82.6929 77.1311 82.1835 76.6216 81.555 76.6216C80.9265 76.6216 80.417 77.1311 80.417 77.7596C80.417 78.388 80.9265 78.8975 81.555 78.8975Z"
      fill={color}
    />
    <path
      d="M85.3479 78.8975C85.9764 78.8975 86.4859 78.388 86.4859 77.7596C86.4859 77.1311 85.9764 76.6216 85.3479 76.6216C84.7194 76.6216 84.21 77.1311 84.21 77.7596C84.21 78.388 84.7194 78.8975 85.3479 78.8975Z"
      fill={color}
    />
    <path
      d="M89.1409 78.8975C89.7694 78.8975 90.2789 78.388 90.2789 77.7596C90.2789 77.1311 89.7694 76.6216 89.1409 76.6216C88.5124 76.6216 88.0029 77.1311 88.0029 77.7596C88.0029 78.388 88.5124 78.8975 89.1409 78.8975Z"
      fill={color}
    />
    <path
      d="M92.9348 78.8975C93.5633 78.8975 94.0728 78.388 94.0728 77.7596C94.0728 77.1311 93.5633 76.6216 92.9348 76.6216C92.3064 76.6216 91.7969 77.1311 91.7969 77.7596C91.7969 78.388 92.3064 78.8975 92.9348 78.8975Z"
      fill={color}
    />
    <path
      d="M100.521 78.8975C101.149 78.8975 101.659 78.388 101.659 77.7596C101.659 77.1311 101.149 76.6216 100.521 76.6216C99.8923 76.6216 99.3828 77.1311 99.3828 77.7596C99.3828 78.388 99.8923 78.8975 100.521 78.8975Z"
      fill={color}
    />
    <path
      d="M32.2425 82.6907C32.871 82.6907 33.3804 82.1813 33.3804 81.5528C33.3804 80.9243 32.871 80.4148 32.2425 80.4148C31.614 80.4148 31.1045 80.9243 31.1045 81.5528C31.1045 82.1813 31.614 82.6907 32.2425 82.6907Z"
      fill={color}
    />
    <path
      d="M36.0354 82.6907C36.6639 82.6907 37.1734 82.1813 37.1734 81.5528C37.1734 80.9243 36.6639 80.4148 36.0354 80.4148C35.4069 80.4148 34.8975 80.9243 34.8975 81.5528C34.8975 82.1813 35.4069 82.6907 36.0354 82.6907Z"
      fill={color}
    />
    <path
      d="M47.4153 82.6907C48.0438 82.6907 48.5533 82.1813 48.5533 81.5528C48.5533 80.9243 48.0438 80.4148 47.4153 80.4148C46.7868 80.4148 46.2773 80.9243 46.2773 81.5528C46.2773 82.1813 46.7868 82.6907 47.4153 82.6907Z"
      fill={color}
    />
    <path
      d="M55.0022 82.6907C55.6307 82.6907 56.1402 82.1813 56.1402 81.5528C56.1402 80.9243 55.6307 80.4148 55.0022 80.4148C54.3737 80.4148 53.8643 80.9243 53.8643 81.5528C53.8643 82.1813 54.3737 82.6907 55.0022 82.6907Z"
      fill={color}
    />
    <path
      d="M58.7952 82.6907C59.4237 82.6907 59.9332 82.1813 59.9332 81.5528C59.9332 80.9243 59.4237 80.4148 58.7952 80.4148C58.1667 80.4148 57.6572 80.9243 57.6572 81.5528C57.6572 82.1813 58.1667 82.6907 58.7952 82.6907Z"
      fill={color}
    />
    <path
      d="M73.9681 82.6907C74.5965 82.6907 75.106 82.1813 75.106 81.5528C75.106 80.9243 74.5965 80.4148 73.9681 80.4148C73.3396 80.4148 72.8301 80.9243 72.8301 81.5528C72.8301 82.1813 73.3396 82.6907 73.9681 82.6907Z"
      fill={color}
    />
    <path
      d="M77.762 82.6907C78.3905 82.6907 78.9 82.1813 78.9 81.5528C78.9 80.9243 78.3905 80.4148 77.762 80.4148C77.1335 80.4148 76.624 80.9243 76.624 81.5528C76.624 82.1813 77.1335 82.6907 77.762 82.6907Z"
      fill={color}
    />
    <path
      d="M92.9348 82.6907C93.5633 82.6907 94.0728 82.1813 94.0728 81.5528C94.0728 80.9243 93.5633 80.4148 92.9348 80.4148C92.3064 80.4148 91.7969 80.9243 91.7969 81.5528C91.7969 82.1813 92.3064 82.6907 92.9348 82.6907Z"
      fill={color}
    />
    <path
      d="M100.521 82.6907C101.149 82.6907 101.659 82.1813 101.659 81.5528C101.659 80.9243 101.149 80.4148 100.521 80.4148C99.8923 80.4148 99.3828 80.9243 99.3828 81.5528C99.3828 82.1813 99.8923 82.6907 100.521 82.6907Z"
      fill={color}
    />
    <path
      d="M104.315 82.6907C104.943 82.6907 105.453 82.1813 105.453 81.5528C105.453 80.9243 104.943 80.4148 104.315 80.4148C103.686 80.4148 103.177 80.9243 103.177 81.5528C103.177 82.1813 103.686 82.6907 104.315 82.6907Z"
      fill={color}
    />
    <path
      d="M108.108 82.6907C108.736 82.6907 109.246 82.1813 109.246 81.5528C109.246 80.9243 108.736 80.4148 108.108 80.4148C107.479 80.4148 106.97 80.9243 106.97 81.5528C106.97 82.1813 107.479 82.6907 108.108 82.6907Z"
      fill={color}
    />
    <path
      d="M36.0354 86.484C36.6639 86.484 37.1734 85.9745 37.1734 85.346C37.1734 84.7175 36.6639 84.208 36.0354 84.208C35.4069 84.208 34.8975 84.7175 34.8975 85.346C34.8975 85.9745 35.4069 86.484 36.0354 86.484Z"
      fill={color}
    />
    <path
      d="M39.8294 86.484C40.4579 86.484 40.9674 85.9745 40.9674 85.346C40.9674 84.7175 40.4579 84.208 39.8294 84.208C39.2009 84.208 38.6914 84.7175 38.6914 85.346C38.6914 85.9745 39.2009 86.484 39.8294 86.484Z"
      fill={color}
    />
    <path
      d="M43.6223 86.484C44.2508 86.484 44.7603 85.9745 44.7603 85.346C44.7603 84.7175 44.2508 84.208 43.6223 84.208C42.9939 84.208 42.4844 84.7175 42.4844 85.346C42.4844 85.9745 42.9939 86.484 43.6223 86.484Z"
      fill={color}
    />
    <path
      d="M62.5882 86.484C63.2167 86.484 63.7261 85.9745 63.7261 85.346C63.7261 84.7175 63.2167 84.208 62.5882 84.208C61.9597 84.208 61.4502 84.7175 61.4502 85.346C61.4502 85.9745 61.9597 86.484 62.5882 86.484Z"
      fill={color}
    />
    <path
      d="M66.3821 86.484C67.0106 86.484 67.5201 85.9745 67.5201 85.346C67.5201 84.7175 67.0106 84.208 66.3821 84.208C65.7536 84.208 65.2441 84.7175 65.2441 85.346C65.2441 85.9745 65.7536 86.484 66.3821 86.484Z"
      fill={color}
    />
    <path
      d="M77.762 86.484C78.3905 86.484 78.9 85.9745 78.9 85.346C78.9 84.7175 78.3905 84.208 77.762 84.208C77.1335 84.208 76.624 84.7175 76.624 85.346C76.624 85.9745 77.1335 86.484 77.762 86.484Z"
      fill={color}
    />
    <path
      d="M85.3479 86.484C85.9764 86.484 86.4859 85.9745 86.4859 85.346C86.4859 84.7175 85.9764 84.208 85.3479 84.208C84.7194 84.208 84.21 84.7175 84.21 85.346C84.21 85.9745 84.7194 86.484 85.3479 86.484Z"
      fill={color}
    />
    <path
      d="M92.9348 86.484C93.5633 86.484 94.0728 85.9745 94.0728 85.346C94.0728 84.7175 93.5633 84.208 92.9348 84.208C92.3064 84.208 91.7969 84.7175 91.7969 85.346C91.7969 85.9745 92.3064 86.484 92.9348 86.484Z"
      fill={color}
    />
    <path
      d="M104.315 86.484C104.943 86.484 105.453 85.9745 105.453 85.346C105.453 84.7175 104.943 84.208 104.315 84.208C103.686 84.208 103.177 84.7175 103.177 85.346C103.177 85.9745 103.686 86.484 104.315 86.484Z"
      fill={color}
    />
    <path
      d="M32.2425 90.2772C32.871 90.2772 33.3804 89.7677 33.3804 89.1392C33.3804 88.5107 32.871 88.0012 32.2425 88.0012C31.614 88.0012 31.1045 88.5107 31.1045 89.1392C31.1045 89.7677 31.614 90.2772 32.2425 90.2772Z"
      fill={color}
    />
    <path
      d="M36.0354 90.2772C36.6639 90.2772 37.1734 89.7677 37.1734 89.1392C37.1734 88.5107 36.6639 88.0012 36.0354 88.0012C35.4069 88.0012 34.8975 88.5107 34.8975 89.1392C34.8975 89.7677 35.4069 90.2772 36.0354 90.2772Z"
      fill={color}
    />
    <path
      d="M39.8294 90.2772C40.4579 90.2772 40.9674 89.7677 40.9674 89.1392C40.9674 88.5107 40.4579 88.0012 39.8294 88.0012C39.2009 88.0012 38.6914 88.5107 38.6914 89.1392C38.6914 89.7677 39.2009 90.2772 39.8294 90.2772Z"
      fill={color}
    />
    <path
      d="M47.4153 90.2772C48.0438 90.2772 48.5533 89.7677 48.5533 89.1392C48.5533 88.5107 48.0438 88.0012 47.4153 88.0012C46.7868 88.0012 46.2773 88.5107 46.2773 89.1392C46.2773 89.7677 46.7868 90.2772 47.4153 90.2772Z"
      fill={color}
    />
    <path
      d="M55.0022 90.2772C55.6307 90.2772 56.1402 89.7677 56.1402 89.1392C56.1402 88.5107 55.6307 88.0012 55.0022 88.0012C54.3737 88.0012 53.8643 88.5107 53.8643 89.1392C53.8643 89.7677 54.3737 90.2772 55.0022 90.2772Z"
      fill={color}
    />
    <path
      d="M58.7952 90.2772C59.4237 90.2772 59.9332 89.7677 59.9332 89.1392C59.9332 88.5107 59.4237 88.0012 58.7952 88.0012C58.1667 88.0012 57.6572 88.5107 57.6572 89.1392C57.6572 89.7677 58.1667 90.2772 58.7952 90.2772Z"
      fill={color}
    />
    <path
      d="M66.3821 90.2772C67.0106 90.2772 67.5201 89.7677 67.5201 89.1392C67.5201 88.5107 67.0106 88.0012 66.3821 88.0012C65.7536 88.0012 65.2441 88.5107 65.2441 89.1392C65.2441 89.7677 65.7536 90.2772 66.3821 90.2772Z"
      fill={color}
    />
    <path
      d="M73.9681 90.2772C74.5965 90.2772 75.106 89.7677 75.106 89.1392C75.106 88.5107 74.5965 88.0012 73.9681 88.0012C73.3396 88.0012 72.8301 88.5107 72.8301 89.1392C72.8301 89.7677 73.3396 90.2772 73.9681 90.2772Z"
      fill={color}
    />
    <path
      d="M77.762 90.2772C78.3905 90.2772 78.9 89.7677 78.9 89.1392C78.9 88.5107 78.3905 88.0012 77.762 88.0012C77.1335 88.0012 76.624 88.5107 76.624 89.1392C76.624 89.7677 77.1335 90.2772 77.762 90.2772Z"
      fill={color}
    />
    <path
      d="M92.9348 90.2772C93.5633 90.2772 94.0728 89.7677 94.0728 89.1392C94.0728 88.5107 93.5633 88.0012 92.9348 88.0012C92.3064 88.0012 91.7969 88.5107 91.7969 89.1392C91.7969 89.7677 92.3064 90.2772 92.9348 90.2772Z"
      fill={color}
    />
    <path
      d="M96.7278 90.2772C97.3563 90.2772 97.8658 89.7677 97.8658 89.1392C97.8658 88.5107 97.3563 88.0012 96.7278 88.0012C96.0993 88.0012 95.5898 88.5107 95.5898 89.1392C95.5898 89.7677 96.0993 90.2772 96.7278 90.2772Z"
      fill={color}
    />
    <path
      d="M100.521 90.2772C101.149 90.2772 101.659 89.7677 101.659 89.1392C101.659 88.5107 101.149 88.0012 100.521 88.0012C99.8923 88.0012 99.3828 88.5107 99.3828 89.1392C99.3828 89.7677 99.8923 90.2772 100.521 90.2772Z"
      fill={color}
    />
    <path
      d="M108.108 90.2772C108.736 90.2772 109.246 89.7677 109.246 89.1392C109.246 88.5107 108.736 88.0012 108.108 88.0012C107.479 88.0012 106.97 88.5107 106.97 89.1392C106.97 89.7677 107.479 90.2772 108.108 90.2772Z"
      fill={color}
    />
    <path
      d="M62.5882 94.0704C63.2167 94.0704 63.7261 93.5609 63.7261 92.9324C63.7261 92.3039 63.2167 91.7944 62.5882 91.7944C61.9597 91.7944 61.4502 92.3039 61.4502 92.9324C61.4502 93.5609 61.9597 94.0704 62.5882 94.0704Z"
      fill={color}
    />
    <path
      d="M77.762 94.0704C78.3905 94.0704 78.9 93.5609 78.9 92.9324C78.9 92.3039 78.3905 91.7944 77.762 91.7944C77.1335 91.7944 76.624 92.3039 76.624 92.9324C76.624 93.5609 77.1335 94.0704 77.762 94.0704Z"
      fill={color}
    />
    <path
      d="M81.555 94.0704C82.1835 94.0704 82.6929 93.5609 82.6929 92.9324C82.6929 92.3039 82.1835 91.7944 81.555 91.7944C80.9265 91.7944 80.417 92.3039 80.417 92.9324C80.417 93.5609 80.9265 94.0704 81.555 94.0704Z"
      fill={color}
    />
    <path
      d="M85.3479 94.0704C85.9764 94.0704 86.4859 93.5609 86.4859 92.9324C86.4859 92.3039 85.9764 91.7944 85.3479 91.7944C84.7194 91.7944 84.21 92.3039 84.21 92.9324C84.21 93.5609 84.7194 94.0704 85.3479 94.0704Z"
      fill={color}
    />
    <path
      d="M89.1409 94.0704C89.7694 94.0704 90.2789 93.5609 90.2789 92.9324C90.2789 92.3039 89.7694 91.7944 89.1409 91.7944C88.5124 91.7944 88.0029 92.3039 88.0029 92.9324C88.0029 93.5609 88.5124 94.0704 89.1409 94.0704Z"
      fill={color}
    />
    <path
      d="M92.9348 94.0704C93.5633 94.0704 94.0728 93.5609 94.0728 92.9324C94.0728 92.3039 93.5633 91.7944 92.9348 91.7944C92.3064 91.7944 91.7969 92.3039 91.7969 92.9324C91.7969 93.5609 92.3064 94.0704 92.9348 94.0704Z"
      fill={color}
    />
    <path
      d="M32.2425 97.8638C32.871 97.8638 33.3804 97.3544 33.3804 96.7259C33.3804 96.0974 32.871 95.5879 32.2425 95.5879C31.614 95.5879 31.1045 96.0974 31.1045 96.7259C31.1045 97.3544 31.614 97.8638 32.2425 97.8638Z"
      fill={color}
    />
    <path
      d="M39.8294 97.8638C40.4579 97.8638 40.9674 97.3544 40.9674 96.7259C40.9674 96.0974 40.4579 95.5879 39.8294 95.5879C39.2009 95.5879 38.6914 96.0974 38.6914 96.7259C38.6914 97.3544 39.2009 97.8638 39.8294 97.8638Z"
      fill={color}
    />
    <path
      d="M47.4153 97.8638C48.0438 97.8638 48.5533 97.3544 48.5533 96.7259C48.5533 96.0974 48.0438 95.5879 47.4153 95.5879C46.7868 95.5879 46.2773 96.0974 46.2773 96.7259C46.2773 97.3544 46.7868 97.8638 47.4153 97.8638Z"
      fill={color}
    />
    <path
      d="M51.2093 97.8638C51.8378 97.8638 52.3472 97.3544 52.3472 96.7259C52.3472 96.0974 51.8378 95.5879 51.2093 95.5879C50.5808 95.5879 50.0713 96.0974 50.0713 96.7259C50.0713 97.3544 50.5808 97.8638 51.2093 97.8638Z"
      fill={color}
    />
    <path
      d="M58.7952 97.8638C59.4237 97.8638 59.9332 97.3544 59.9332 96.7259C59.9332 96.0974 59.4237 95.5879 58.7952 95.5879C58.1667 95.5879 57.6572 96.0974 57.6572 96.7259C57.6572 97.3544 58.1667 97.8638 58.7952 97.8638Z"
      fill={color}
    />
    <path
      d="M66.3821 97.8638C67.0106 97.8638 67.5201 97.3544 67.5201 96.7259C67.5201 96.0974 67.0106 95.5879 66.3821 95.5879C65.7536 95.5879 65.2441 96.0974 65.2441 96.7259C65.2441 97.3544 65.7536 97.8638 66.3821 97.8638Z"
      fill={color}
    />
    <path
      d="M85.3479 97.8638C85.9764 97.8638 86.4859 97.3544 86.4859 96.7259C86.4859 96.0974 85.9764 95.5879 85.3479 95.5879C84.7194 95.5879 84.21 96.0974 84.21 96.7259C84.21 97.3544 84.7194 97.8638 85.3479 97.8638Z"
      fill={color}
    />
    <path
      d="M92.9348 97.8638C93.5633 97.8638 94.0728 97.3544 94.0728 96.7259C94.0728 96.0974 93.5633 95.5879 92.9348 95.5879C92.3064 95.5879 91.7969 96.0974 91.7969 96.7259C91.7969 97.3544 92.3064 97.8638 92.9348 97.8638Z"
      fill={color}
    />
    <path
      d="M96.7278 97.8638C97.3563 97.8638 97.8658 97.3544 97.8658 96.7259C97.8658 96.0974 97.3563 95.5879 96.7278 95.5879C96.0993 95.5879 95.5898 96.0974 95.5898 96.7259C95.5898 97.3544 96.0993 97.8638 96.7278 97.8638Z"
      fill={color}
    />
    <path
      d="M100.521 97.8638C101.149 97.8638 101.659 97.3544 101.659 96.7259C101.659 96.0974 101.149 95.5879 100.521 95.5879C99.8923 95.5879 99.3828 96.0974 99.3828 96.7259C99.3828 97.3544 99.8923 97.8638 100.521 97.8638Z"
      fill={color}
    />
    <path
      d="M108.108 97.8638C108.736 97.8638 109.246 97.3544 109.246 96.7259C109.246 96.0974 108.736 95.5879 108.108 95.5879C107.479 95.5879 106.97 96.0974 106.97 96.7259C106.97 97.3544 107.479 97.8638 108.108 97.8638Z"
      fill={color}
    />
    <path
      d="M36.0354 101.657C36.6639 101.657 37.1734 101.148 37.1734 100.519C37.1734 99.8906 36.6639 99.3811 36.0354 99.3811C35.4069 99.3811 34.8975 99.8906 34.8975 100.519C34.8975 101.148 35.4069 101.657 36.0354 101.657Z"
      fill={color}
    />
    <path
      d="M39.8294 101.657C40.4579 101.657 40.9674 101.148 40.9674 100.519C40.9674 99.8906 40.4579 99.3811 39.8294 99.3811C39.2009 99.3811 38.6914 99.8906 38.6914 100.519C38.6914 101.148 39.2009 101.657 39.8294 101.657Z"
      fill={color}
    />
    <path
      d="M58.7952 101.657C59.4237 101.657 59.9332 101.148 59.9332 100.519C59.9332 99.8906 59.4237 99.3811 58.7952 99.3811C58.1667 99.3811 57.6572 99.8906 57.6572 100.519C57.6572 101.148 58.1667 101.657 58.7952 101.657Z"
      fill={color}
    />
    <path
      d="M62.5882 101.657C63.2167 101.657 63.7261 101.148 63.7261 100.519C63.7261 99.8906 63.2167 99.3811 62.5882 99.3811C61.9597 99.3811 61.4502 99.8906 61.4502 100.519C61.4502 101.148 61.9597 101.657 62.5882 101.657Z"
      fill={color}
    />
    <path
      d="M66.3821 101.657C67.0106 101.657 67.5201 101.148 67.5201 100.519C67.5201 99.8906 67.0106 99.3811 66.3821 99.3811C65.7536 99.3811 65.2441 99.8906 65.2441 100.519C65.2441 101.148 65.7536 101.657 66.3821 101.657Z"
      fill={color}
    />
    <path
      d="M70.1751 101.657C70.8036 101.657 71.3131 101.148 71.3131 100.519C71.3131 99.8906 70.8036 99.3811 70.1751 99.3811C69.5466 99.3811 69.0371 99.8906 69.0371 100.519C69.0371 101.148 69.5466 101.657 70.1751 101.657Z"
      fill={color}
    />
    <path
      d="M81.555 101.657C82.1835 101.657 82.6929 101.148 82.6929 100.519C82.6929 99.8906 82.1835 99.3811 81.555 99.3811C80.9265 99.3811 80.417 99.8906 80.417 100.519C80.417 101.148 80.9265 101.657 81.555 101.657Z"
      fill={color}
    />
    <path
      d="M92.9348 101.657C93.5633 101.657 94.0728 101.148 94.0728 100.519C94.0728 99.8906 93.5633 99.3811 92.9348 99.3811C92.3064 99.3811 91.7969 99.8906 91.7969 100.519C91.7969 101.148 92.3064 101.657 92.9348 101.657Z"
      fill={color}
    />
    <path
      d="M96.7278 101.657C97.3563 101.657 97.8658 101.148 97.8658 100.519C97.8658 99.8906 97.3563 99.3811 96.7278 99.3811C96.0993 99.3811 95.5898 99.8906 95.5898 100.519C95.5898 101.148 96.0993 101.657 96.7278 101.657Z"
      fill={color}
    />
    <path
      d="M100.521 101.657C101.149 101.657 101.659 101.148 101.659 100.519C101.659 99.8906 101.149 99.3811 100.521 99.3811C99.8923 99.3811 99.3828 99.8906 99.3828 100.519C99.3828 101.148 99.8923 101.657 100.521 101.657Z"
      fill={color}
    />
    <path
      d="M108.108 101.657C108.736 101.657 109.246 101.148 109.246 100.519C109.246 99.8906 108.736 99.3811 108.108 99.3811C107.479 99.3811 106.97 99.8906 106.97 100.519C106.97 101.148 107.479 101.657 108.108 101.657Z"
      fill={color}
    />
    <path
      d="M36.0354 105.45C36.6639 105.45 37.1734 104.941 37.1734 104.312C37.1734 103.684 36.6639 103.174 36.0354 103.174C35.4069 103.174 34.8975 103.684 34.8975 104.312C34.8975 104.941 35.4069 105.45 36.0354 105.45Z"
      fill={color}
    />
    <path
      d="M47.4153 105.45C48.0438 105.45 48.5533 104.941 48.5533 104.312C48.5533 103.684 48.0438 103.174 47.4153 103.174C46.7868 103.174 46.2773 103.684 46.2773 104.312C46.2773 104.941 46.7868 105.45 47.4153 105.45Z"
      fill={color}
    />
    <path
      d="M62.5882 105.45C63.2167 105.45 63.7261 104.941 63.7261 104.312C63.7261 103.684 63.2167 103.174 62.5882 103.174C61.9597 103.174 61.4502 103.684 61.4502 104.312C61.4502 104.941 61.9597 105.45 62.5882 105.45Z"
      fill={color}
    />
    <path
      d="M66.3821 105.45C67.0106 105.45 67.5201 104.941 67.5201 104.312C67.5201 103.684 67.0106 103.174 66.3821 103.174C65.7536 103.174 65.2441 103.684 65.2441 104.312C65.2441 104.941 65.7536 105.45 66.3821 105.45Z"
      fill={color}
    />
    <path
      d="M70.1751 105.45C70.8036 105.45 71.3131 104.941 71.3131 104.312C71.3131 103.684 70.8036 103.174 70.1751 103.174C69.5466 103.174 69.0371 103.684 69.0371 104.312C69.0371 104.941 69.5466 105.45 70.1751 105.45Z"
      fill={color}
    />
    <path
      d="M77.762 105.45C78.3905 105.45 78.9 104.941 78.9 104.312C78.9 103.684 78.3905 103.174 77.762 103.174C77.1335 103.174 76.624 103.684 76.624 104.312C76.624 104.941 77.1335 105.45 77.762 105.45Z"
      fill={color}
    />
    <path
      d="M81.555 105.45C82.1835 105.45 82.6929 104.941 82.6929 104.312C82.6929 103.684 82.1835 103.174 81.555 103.174C80.9265 103.174 80.417 103.684 80.417 104.312C80.417 104.941 80.9265 105.45 81.555 105.45Z"
      fill={color}
    />
    <path
      d="M92.9348 105.45C93.5633 105.45 94.0728 104.941 94.0728 104.312C94.0728 103.684 93.5633 103.174 92.9348 103.174C92.3064 103.174 91.7969 103.684 91.7969 104.312C91.7969 104.941 92.3064 105.45 92.9348 105.45Z"
      fill={color}
    />
    <path
      d="M104.315 105.45C104.943 105.45 105.453 104.941 105.453 104.312C105.453 103.684 104.943 103.174 104.315 103.174C103.686 103.174 103.177 103.684 103.177 104.312C103.177 104.941 103.686 105.45 104.315 105.45Z"
      fill={color}
    />
    <path
      d="M32.2425 109.243C32.871 109.243 33.3804 108.734 33.3804 108.106C33.3804 107.477 32.871 106.968 32.2425 106.968C31.614 106.968 31.1045 107.477 31.1045 108.106C31.1045 108.734 31.614 109.243 32.2425 109.243Z"
      fill={color}
    />
    <path
      d="M36.0354 109.243C36.6639 109.243 37.1734 108.734 37.1734 108.106C37.1734 107.477 36.6639 106.968 36.0354 106.968C35.4069 106.968 34.8975 107.477 34.8975 108.106C34.8975 108.734 35.4069 109.243 36.0354 109.243Z"
      fill={color}
    />
    <path
      d="M39.8294 109.243C40.4579 109.243 40.9674 108.734 40.9674 108.106C40.9674 107.477 40.4579 106.968 39.8294 106.968C39.2009 106.968 38.6914 107.477 38.6914 108.106C38.6914 108.734 39.2009 109.243 39.8294 109.243Z"
      fill={color}
    />
    <path
      d="M43.6223 109.243C44.2508 109.243 44.7603 108.734 44.7603 108.106C44.7603 107.477 44.2508 106.968 43.6223 106.968C42.9939 106.968 42.4844 107.477 42.4844 108.106C42.4844 108.734 42.9939 109.243 43.6223 109.243Z"
      fill={color}
    />
    <path
      d="M51.2093 109.243C51.8378 109.243 52.3472 108.734 52.3472 108.106C52.3472 107.477 51.8378 106.968 51.2093 106.968C50.5808 106.968 50.0713 107.477 50.0713 108.106C50.0713 108.734 50.5808 109.243 51.2093 109.243Z"
      fill={color}
    />
    <path
      d="M58.7952 109.243C59.4237 109.243 59.9332 108.734 59.9332 108.106C59.9332 107.477 59.4237 106.968 58.7952 106.968C58.1667 106.968 57.6572 107.477 57.6572 108.106C57.6572 108.734 58.1667 109.243 58.7952 109.243Z"
      fill={color}
    />
    <path
      d="M62.5882 109.243C63.2167 109.243 63.7261 108.734 63.7261 108.106C63.7261 107.477 63.2167 106.968 62.5882 106.968C61.9597 106.968 61.4502 107.477 61.4502 108.106C61.4502 108.734 61.9597 109.243 62.5882 109.243Z"
      fill={color}
    />
    <path
      d="M70.1751 109.243C70.8036 109.243 71.3131 108.734 71.3131 108.106C71.3131 107.477 70.8036 106.968 70.1751 106.968C69.5466 106.968 69.0371 107.477 69.0371 108.106C69.0371 108.734 69.5466 109.243 70.1751 109.243Z"
      fill={color}
    />
    <path
      d="M77.762 109.243C78.3905 109.243 78.9 108.734 78.9 108.106C78.9 107.477 78.3905 106.968 77.762 106.968C77.1335 106.968 76.624 107.477 76.624 108.106C76.624 108.734 77.1335 109.243 77.762 109.243Z"
      fill={color}
    />
    <path
      d="M89.1409 109.243C89.7694 109.243 90.2789 108.734 90.2789 108.106C90.2789 107.477 89.7694 106.968 89.1409 106.968C88.5124 106.968 88.0029 107.477 88.0029 108.106C88.0029 108.734 88.5124 109.243 89.1409 109.243Z"
      fill={color}
    />
    <path
      d="M92.9348 109.243C93.5633 109.243 94.0728 108.734 94.0728 108.106C94.0728 107.477 93.5633 106.968 92.9348 106.968C92.3064 106.968 91.7969 107.477 91.7969 108.106C91.7969 108.734 92.3064 109.243 92.9348 109.243Z"
      fill={color}
    />
    <path
      d="M96.7278 109.243C97.3563 109.243 97.8658 108.734 97.8658 108.106C97.8658 107.477 97.3563 106.968 96.7278 106.968C96.0993 106.968 95.5898 107.477 95.5898 108.106C95.5898 108.734 96.0993 109.243 96.7278 109.243Z"
      fill={color}
    />
    <path
      d="M104.315 109.243C104.943 109.243 105.453 108.734 105.453 108.106C105.453 107.477 104.943 106.968 104.315 106.968C103.686 106.968 103.177 107.477 103.177 108.106C103.177 108.734 103.686 109.243 104.315 109.243Z"
      fill={color}
    />
    <path
      d="M17.4874 0H9.06537C4.96085 0 1.4835 2.71183 0.371738 6.41833C0.256765 6.80175 0.167017 7.1958 0.104618 7.5986C0.0358462 8.04309 0 8.49821 0 8.96155V17.5835C0 22.5274 4.06788 26.5477 9.06537 26.5477L13.2764 26.5485L17.4874 26.5474C22.4846 26.5474 26.5527 22.5271 26.5527 17.5832V8.96155C26.5527 4.02009 22.4849 0 17.4874 0ZM22.5698 17.5835C22.5698 20.3301 20.29 22.5648 17.4863 22.5648L13.2764 22.5656L9.06644 22.5645C6.26273 22.5645 3.98291 20.3298 3.98291 17.5835V8.96155C3.98291 8.10363 4.20542 7.2959 4.59708 6.59013C4.97413 5.91117 5.50757 5.32728 6.14988 4.88518C6.97567 4.31668 7.98123 3.98291 9.06511 3.98291H17.4874C20.29 3.98291 22.5698 6.21626 22.5698 8.96155V17.5835Z"
      fill={color}
    />
    <path
      d="M100.939 0H92.5165C88.412 0 84.9347 2.71183 83.8229 6.41833C83.7079 6.80175 83.6182 7.1958 83.5558 7.5986C83.487 8.04309 83.4512 8.49821 83.4512 8.96155V17.5835C83.4512 22.5274 87.5191 26.5477 92.5165 26.5477L96.7275 26.5485L100.939 26.5474C105.936 26.5474 110.004 22.5271 110.004 17.5832V8.96155C110.004 4.02009 105.936 0 100.939 0ZM106.021 17.5835C106.021 20.3301 103.741 22.5648 100.937 22.5648L96.7275 22.5656L92.5176 22.5645C89.7139 22.5645 87.4341 20.3298 87.4341 17.5835V8.96155C87.4341 8.10363 87.6566 7.2959 88.0482 6.59013C88.4253 5.91117 88.9587 5.32728 89.6011 4.88518C90.4268 4.31668 91.4324 3.98291 92.5163 3.98291H100.939C103.741 3.98291 106.021 6.21626 106.021 8.96155V17.5835Z"
      fill={color}
    />
    <path
      d="M17.4874 83.4514H9.06537C4.96085 83.4514 1.4835 86.1632 0.371738 89.8697C0.256765 90.2532 0.167017 90.6472 0.104618 91.05C0.0358462 91.4945 0 91.9496 0 92.413V101.035C0 105.979 4.06788 109.999 9.06537 109.999L13.2764 110L17.4874 109.999C22.4846 109.999 26.5527 105.979 26.5527 101.035V92.413C26.5527 87.4715 22.4849 83.4514 17.4874 83.4514ZM22.5698 101.035C22.5698 103.782 20.29 106.016 17.4863 106.016L13.2764 106.017L9.06644 106.016C6.26273 106.016 3.98291 103.781 3.98291 101.035V92.413C3.98291 91.5551 4.20542 90.7473 4.59708 90.0415C4.97413 89.3626 5.50757 88.7787 6.14988 88.3366C6.97567 87.7681 7.98123 87.4343 9.06511 87.4343H17.4874C20.29 87.4343 22.5698 89.6677 22.5698 92.413V101.035Z"
      fill={color}
    />
    <path d="M18.9667 7.58447H7.58691V18.9642H18.9667V7.58447Z" fill={color} />
    <path d="M102.418 7.58447H91.0381V18.9642H102.418V7.58447Z" fill={color} />
    <path d="M18.9667 91.0359H7.58691V102.416H18.9667V91.0359Z" fill={color} />
  </svg>
);
export default QR;
