import { get } from '../../services/ApiServices';
import { getError, showError } from '../../utils';
import { store } from '../store';
import { uiFlagsAction } from './Reducer';

export const getUiFlagsAPI = async () => {
  try {
    store.dispatch(uiFlagsAction.getUiFlags({}));

    const res = await get('/ui/flags/');

    store.dispatch(uiFlagsAction.setUiFlags(res?.data?.data));
  } catch (error) {
    store.dispatch(uiFlagsAction.errorUiFlags(getError(error)));
    showError(error);
  }
};
