import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  post: [],
  comments: [],
  feed: '',
  height: 0
};

const communityPostSlice = createSlice({
  name: 'communityPostData',
  initialState,
  reducers: {
    setPostData(state, action) {
      state.post = action.payload;
    },
    setCommentData(state, action) {
      state.comments = action.payload;
    },
    setFeedType(state, action) {
      state.feed = action.payload;
    },
    setSHeight(state, action) {
      state.height = action.payload;
    },
    clearPostData(state, action) {
      state.post = [];
    }
  }
});

export const {
  setPostData,
  setCommentData,
  setFeedType,
  setSHeight,
  clearPostData
} = communityPostSlice.actions;
export default communityPostSlice;
