/* eslint-disable complexity */
import React, { useContext, useEffect, useRef, useState } from 'react';
import icons from '../../assets/leftSide';
import { colors } from '../../themes';
import { AppContext } from '../../context/AppProvider';
import API_CONSTANT from '../../constants/ApiConstant';
import { CircularProgress } from '@mui/material';
import { getCompanyAPI } from '../../store/login/Action';
import { useDispatch } from 'react-redux';
import { showError } from '../../utils';
import { AppConstant } from '../../constants/AppConstant';
import * as track from '../../utils/MixpanelTrackEvent';

let page = -1;
let apiCall = false;

const Company = ({ username, company, AreYouClick, onClick, btnColor }) => {
  const dispatch = useDispatch();

  const context = useContext(AppContext);

  const ref = useRef();

  const [IsLight, setIsLight] = useState(false);
  const [companyArr, setCompanyArr] = useState([]);
  const [searchCompanyArr, setSearchCompanyArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [selectCompany, setSelectCompany] = useState('');

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    track.trackSetCompanynameOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  useEffect(() => {
    setIsLoading(false);
    companyApiFun({
      pageCount: 0,
      offset: 0,
      search: ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyApiFun = ({ pageCount, offset, search }) => {
    apiCall = true;
    const limit = API_CONSTANT.LIMIT;
    const offsets = offset;
    const serchText = search;
    const queryParams = `?limit=${limit}&offset=${offsets}&display_name=${encodeURIComponent(
      serchText
    )}`;

    getCompanyAPI(dispatch, queryParams)
      .then(res => {
        setIsLoading(false);
        if (res?.data?.data?.results.length) {
          page = pageCount;
          if (search === '') {
            setSearchCompanyArr([]);
            setCompanyArr([...companyArr, ...res?.data?.data?.results]);
          } else {
            setSearchCompanyArr(res?.data?.data?.results);
          }
        }
      })
      .catch(err => {
        // showError(err);
      })
      .finally(() => {
        apiCall = false;
        setIsLoading(false);
      });
  };

  const onWheel = () => {
    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    if (scrollTop + clientHeight === scrollHeight && apiCall === false) {
      companyApiFun({
        pageCount: page + 1,
        offset: (page + 1) * 10,
        search: search
      });
    }
  };

  const _searchCompany = text => {
    setSearch(text);
    companyApiFun({
      pageCount: API_CONSTANT.LIMIT,
      offset: 0,
      search: text
    });
  };

  let companyList = search === '' ? companyArr : searchCompanyArr;

  return (
    <>
      <div className="mt-[32px] mx-[27px]">
        <p className="text-Neutral900 dark:text-Neutral900Dark heading-small-XXL text-center">
          {AppConstant?.WELCOME_USERNAME} {username},
        </p>
        <p className="text-Neutral900 dark:text-Neutral900Dark heading-small-XXL text-center">
          {AppConstant?.WHERE_DO_YOU_WORK}
        </p>
      </div>

      <div className="mx-[22px] mt-[22px]">
        <div className="flex h-[44px] items-center px-[10px] bg-Neutral200 dark:bg-Neutral200Dark rounded-[12px]">
          <icons.search w={15.83} h={15.83} color={colors.Neutral800} />
          <input
            value={search}
            placeholder="Search your company"
            className="no-input-arrow heading-medium-M h-[44px] pl-[10px] placeholder-Neutral500 dark:placeholder-Neutral500Dark text-Neutral900 dark:text-Neutral900Dark border-0 border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full bg-transparent dark:border-gray-600 outline-none appearance-none"
            onChange={e => _searchCompany(e?.target?.value)}
          />
        </div>
      </div>

      <div
        className="mx-[22px] mt-[22px] max-h-auto sm:max-h-[337px] min-h-[337px] overflow-y-auto [&::-webkit-scrollbar]:hidden"
        ref={ref}
        onWheel={onWheel}
        onTouchEnd={onWheel}
      >
        {companyList.map((d, i) => {
          let select = d?.id === selectCompany?.id;
          return (
            <div
              key={i}
              className="flex items-center h-[51px] mb-[15px] cursor-pointer"
              onClick={() => {
                setSelectCompany(d);
                company(d);
              }}
            >
              {select ? (
                <icons.check w={12.9} h={12} className="ml-[10px]" />
              ) : (
                <div
                  className={`${select ? 'bg-Action' : 'bg-transparent'}
              w-[12.59px] h-[12px] border-[2px] border-Action rounded-full ml-[10px]`}
                />
              )}
              <p className="text-Neutral900 dark:text-Neutral900Dark title-large-MB px-[18px] text-left w-full break-all overflow-hidden text-ellipsis whitespace-nowrap">
                {d?.display_name}
              </p>
            </div>
          );
        })}
        {isLoading && (
          <div className="flex items-center justify-center h-[60px]">
            <CircularProgress sx={{}} />
          </div>
        )}
      </div>

      <div className="w-full text-center">
        <div className="w-[252] h-[1px] bg-Neutral400 dark:bg-Neutral400Dark mb-[32px]" />
        <p
          className="title-large-B text-Action cursor-pointer"
          onClick={AreYouClick}
        >
          {AppConstant?.ARE_YOU_STUDENT}
        </p>
        <button
          className="bg-Primary h-[53px] mt-[37px] px-[45px] rounded-full items-center mb-[30px]"
          style={btnColor}
          onClick={onClick}
        >
          <p className="title-large-B text-White">{AppConstant?.CONTINUE}</p>
        </button>
      </div>
    </>
  );
};

export default Company;
