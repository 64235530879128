import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUiFlagsAPI } from '../store/uiFlags/action';
import PageLoader from '../components/loader/pageLoader';
import { AppContext } from './AppProvider';

export const AppStartupContext = createContext({});

const AppStartupProvider = ({ children }) => {
  const [appInitd, setAppInitd] = useState(false);

  const { isAuthenticated } = useContext(AppContext);

  const init = async () => {
    if (isAuthenticated) {
      await getUiFlagsAPI();
    }
    setAppInitd(true);
  };

  useEffect(() => {
    init();
  }, [isAuthenticated]);

  return (
    <AppStartupContext.Provider value={{}}>
      {children}
    </AppStartupContext.Provider>
  );
};

export default AppStartupProvider;
