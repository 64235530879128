module.exports = {
  main: '#00D632',
  mainDark: '#3F925A',
  mainLite: '#DAF5EB',
  fontGray: '#817F7F',
  borderGray: '#949191',
  fontLiteGray: '#BDBDBD',
  grayBg: '#F0F3F7',
  bgFont: '#A5B3CD',
  fontRed: '#FC5555',
  linearGr: '#FF7D07',
  linearGrDark: '#FF6A49',
  // ---------------------------------
  blue: {
    40: '#3091F2'
  },
  Primary: {
    DEFAULT: '#FF7800',
    10: '#FFF9F3',
    20: '#FFE3CB',
    50: '#DB6902',
    40: '#FF7800'
  },
  grey: {
    60: '#505050',
    30: '#D8D8D8',
    40: '#9B9B9B',
    DEFAULT: '#F6F6F6',
    20: '#EAEAEA',
    10: '#F6F6F6',
    50: '#777',
    70: '#3C3C3C',
    80: '#282828',
    25: '#E3E3E3',
    60: '#48484A'
  },
  green: {
    50: '#18B892'
  },
  Neutral900: '#262B2D',
  Neutral900Bft: '#455154',
  Neutral800: '#8B8B8B',
  Neutral700: '#848484',
  Neutral600: '#C4C4C4',
  Neutral500: '#C9C9C9',
  Neutral400: '#BDC5CD',
  Neutral300: '#F2F3F5',
  Neutral200: '#F7F7F7',
  Action: '#1E92FF',
  Action100: '#004BCC',
  Action500: '#99C8FF',
  Warning: '#D93939',
  Black: '#000000',
  White: '#FFFFFF',
  DividerColor: '#EBEBEB',
  DarkBg: '#121212',
  Progress: '#B2D8EF',
  ToggleColor: '#f1f1f1',

  PrimaryDark: {
    DEFAULT: '#FF7800',
    10: '#291707',
    20: '#49280B',
    40: '#DB7921'
  },
  darkGrey: {
    20: '#282828',
    10: '#0E0E0E',
    80: '#DEDEDE',
    70: '#CECECE',
    60: '#BDBDBD',
    50: '#9B9B9B',
    40: '#777',
    15: '#1F1F1F',
    25: '#373737'
  },
  Neutral900Dark: '#D6DDDD',
  Neutral800Dark: '#8B8B8B',
  Neutral700Dark: '#848484',
  Neutral600Dark: '#C4C4C4',
  Neutral500Dark: '#606060',
  Neutral400Dark: '#313234',
  Neutral300Dark: '#313234',
  Neutral200Dark: '#1E1E1E',
  Action500Dark: '#99C8FF',
  WarningDark: '#D93939',
  BlackDark: '#000000',
  WhiteDark: '#FFFFFF',
  ProgressDark: '#384A55',
  ToggleColorDark: '#414141',
  Notif_unseen: '#D3EAFF',
  Notif_unseenDark: '#26313B',
  SelectDropDown: '#F0F8FF',
  primary: '#FF7800',
  primary10: '#FFF9F3',
  primary20: '#FFE3CB',
  primary30: '#FFB36E',
  primary40: '#FF8D26',
  primary50: '#f27505',
  primary70: '#492301',
  grey10: '#F6F6F6',
  grey20: '#EAEAEA',
  grey50: '#777777',
  grey60: '#505050',
  grey70: '#282828',
  waveDark: '#FFB36E'
};
