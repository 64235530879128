import * as React from 'react';

const ReportUser = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.49996 1.0415C5.31383 1.0415 3.54163 2.81371 3.54163 4.99984C3.54163 7.18596 5.31383 8.95817 7.49996 8.95817C9.68609 8.95817 11.4583 7.18596 11.4583 4.99984C11.4583 2.81371 9.68609 1.0415 7.49996 1.0415ZM1.04163 15.7915C1.04163 13.1682 3.16827 11.0415 5.79163 11.0415H9.20829C11.8316 11.0415 13.9583 13.1682 13.9583 15.7915C13.9583 17.5404 12.5405 18.9582 10.7916 18.9582H4.2083C2.45939 18.9582 1.04163 17.5404 1.04163 15.7915ZM17.4166 4.1665C17.4166 3.75229 17.0808 3.4165 16.6666 3.4165C16.2524 3.4165 15.9166 3.75229 15.9166 4.1665V8.33317C15.9166 8.74738 16.2524 9.08317 16.6666 9.08317C17.0808 9.08317 17.4166 8.74738 17.4166 8.33317V4.1665ZM16.6666 9.6665C16.0223 9.6665 15.5 10.1888 15.5 10.8332C15.5 11.4775 16.0223 11.9998 16.6666 11.9998C17.311 11.9998 17.8333 11.4775 17.8333 10.8332C17.8333 10.1888 17.311 9.6665 16.6666 9.6665Z"
      fill={color}
    />
  </svg>
);
export default ReportUser;
