import * as React from 'react';

const ArrowRightLong = ({ color }) => (
  <svg
    width="54"
    height="24"
    viewBox="0 0 54 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.30762 10.5C1.47919 10.5 0.807617 11.1716 0.807617 12C0.807617 12.8284 1.47919 13.5 2.30762 13.5L2.30762 10.5ZM52.7281 13.0607C53.3138 12.4749 53.3138 11.5251 52.7281 10.9393L43.1821 1.3934C42.5963 0.807615 41.6466 0.807615 41.0608 1.3934C40.475 1.97919 40.475 2.92894 41.0608 3.51472L49.5461 12L41.0608 20.4853C40.475 21.0711 40.475 22.0208 41.0608 22.6066C41.6466 23.1924 42.5963 23.1924 43.1821 22.6066L52.7281 13.0607ZM2.30762 13.5L51.6674 13.5L51.6674 10.5L2.30762 10.5L2.30762 13.5Z"
      fill={color}
    />
  </svg>
);
export default ArrowRightLong;
