import * as React from 'react';

const Incognito = ({ w, h, color }) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0609 7.19178C15.2363 7.19178 16.9998 8.93967 16.9998 11.0959C16.9998 13.2521 15.2363 15 13.0609 15C11.3211 15 9.84473 13.882 9.32321 12.3314H7.67674C7.15522 13.882 5.6789 15 3.93912 15C1.76371 15 0.000187698 13.2521 0.000187698 11.0959C0.000187698 8.93967 1.76371 7.19178 3.93912 7.19178C5.97459 7.19178 7.64945 8.72203 7.85652 10.6852H9.14351C9.35058 8.72203 11.0254 7.19178 13.0609 7.19178ZM3.93912 8.83562C2.67968 8.83562 1.65869 9.84756 1.65869 11.0959C1.65869 12.3442 2.67968 13.3562 3.93912 13.3562C5.19857 13.3562 6.21956 12.3442 6.21956 11.0959C6.21956 9.84756 5.19857 8.83562 3.93912 8.83562ZM13.0609 8.83562C11.8014 8.83562 10.7805 9.84756 10.7805 11.0959C10.7805 12.3442 11.8014 13.3562 13.0609 13.3562C14.3204 13.3562 15.3413 12.3442 15.3413 11.0959C15.3413 9.84756 14.3204 8.83562 13.0609 8.83562ZM11.4123 0C12.4193 0 13.3072 0.654732 13.5965 1.61079L14.4309 4.36515C15.0881 4.47358 15.7313 4.60114 16.3605 4.74787C16.8063 4.85185 17.0827 5.29433 16.9778 5.7362C16.8729 6.17803 16.4264 6.45197 15.9806 6.348C13.6956 5.81515 11.2022 5.54795 8.50002 5.54795C5.79776 5.54795 3.3044 5.81515 1.01937 6.348C0.573564 6.45197 0.127129 6.17803 0.0222291 5.7362C-0.0826627 5.29433 0.193702 4.85185 0.639506 4.74787C1.26844 4.6012 1.91138 4.47368 2.56832 4.36529L3.40346 1.61079C3.69284 0.654732 4.58067 0 5.58772 0H11.4123Z"
      fill="#8B8B8B"
    />
  </svg>
);
export default Incognito;
