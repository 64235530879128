import { useRouter } from 'next/router';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { ROUTE } from '../utils';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const [navigationStack, setNavigationStack] = useState([]);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = url => {
      setNavigationStack(prevStack => [...prevStack, url]);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  const goBack = () => {
    if (navigationStack.length > 1) {
      router.back();
      setNavigationStack(prevStack => prevStack.slice(0, -1));
    } else {
      router.push(ROUTE.HOME);
    }
  };

  return (
    <NavigationContext.Provider value={{ navigationStack, goBack }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
