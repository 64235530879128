import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { ASYNC_KEY, TOAST_MESSAGE } from '../constants/AppConstant';
import API_CONSTANT from '../constants/ApiConstant';
import { getRefereshToken, removeToken } from './../services/ApiServices';
import { setInAsyncStorage } from '../utils';

let isRefreshing = false;

const generateRefreshToken = async () => {
  const refreshToken = getRefereshToken();
  const currentTime = moment().unix();
  const decoded = await jwtDecode(refreshToken);

  if (currentTime > decoded.exp) {
    removeToken();
  } else {
    try {
      const response = await axios.post(
        `${API_CONSTANT.BASE_URL}${API_CONSTANT.TOKEN_REFRESH}`,
        { refresh: refreshToken.replace(/"/g, '') }
      );
      setInAsyncStorage(
        ASYNC_KEY.ACCESS_TOKEN,
        response?.data?.token?.access.replace(/"/g, '')
      );
      setInAsyncStorage(
        ASYNC_KEY.REFRESH_TOKEN,
        response?.data?.token?.refresh.replace(/"/g, '')
      );
    } catch (error) {
      throw new Error(error, 'Error generating new Refresh Token');
    }
  }
};

export default generateRefreshToken;
