import * as React from 'react';

const Watch = ({ w, h, color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 10L12 12L13.5 13M16 5L15.606 3.42388C15.3893 2.55722 15.281 2.12389 15.0394 1.80124C14.8262 1.51657 14.541 1.29388 14.2131 1.15612C13.8415 0.999998 13.3949 0.999998 12.5015 0.999999L11.4985 0.999999C10.6051 0.999999 10.1585 0.999999 9.78687 1.15613C9.459 1.29388 9.17378 1.51657 8.96062 1.80124C8.71903 2.12389 8.61069 2.55722 8.39403 3.42388L8 5M16 19L15.606 20.5761C15.3893 21.4428 15.281 21.8761 15.0394 22.1988C14.8262 22.4834 14.541 22.7061 14.2131 22.8439C13.8415 23 13.3949 23 12.5015 23L11.4985 23C10.6052 23 10.1585 23 9.78688 22.8439C9.459 22.7061 9.17379 22.4834 8.96063 22.1988C8.71903 21.8761 8.6107 21.4428 8.39403 20.5761L8 19M12 20V20C16.4183 20 20 16.4183 20 12V12C20 7.58172 16.4183 4 12 4V4C7.58172 4 4 7.58172 4 12V12C4 16.4183 7.58173 20 12 20Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Watch;
