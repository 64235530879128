import * as React from 'react';

const Plus = ({}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99967 8.00016H7.99967M7.99967 8.00016H9.99967M7.99967 8.00016V6.00016M7.99967 8.00016V10.0002M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C7.2401 14.6668 6.5887 14.5587 5.98386 14.3423C5.4122 14.1378 5.12636 14.0356 5.01669 14.0098C4.00474 13.7718 3.58494 14.4653 2.71277 14.6106C2.2844 14.682 1.90324 14.3313 1.93878 13.8985C1.96987 13.52 2.2316 13.1621 2.33604 12.7987C2.55316 12.0431 2.25854 11.4703 1.94727 10.7984C1.55311 9.94748 1.33301 8.9995 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z"
      stroke="#8B8B8B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Plus;
