import * as React from 'react';

const NoNotification = ({ color }) => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.775 5.66608L11.6908 5.66606C11.2651 5.66588 10.7636 5.66567 10.3379 5.70458C9.92265 5.74255 9.00457 5.85162 8.1651 6.49953C7.22493 7.22516 6.65582 8.33105 6.61182 9.51786C6.57252 10.5775 7.01738 11.388 7.22786 11.748C7.44362 12.117 7.7353 12.525 7.98287 12.8713L8.03181 12.9398L48.9992 70.2941L48.9992 92.3327L34.6659 92.3327C33.009 92.3327 31.6659 93.6759 31.6659 95.3327C31.6659 96.9896 33.009 98.3327 34.6659 98.3327L51.9992 98.3327L69.3325 98.3327C70.9894 98.3327 72.3325 96.9896 72.3325 95.3327C72.3325 93.6759 70.9894 92.3327 69.3326 92.3327L54.9992 92.3327L54.9992 70.2941L95.9666 12.9398L96.0156 12.8713L96.0157 12.8711C96.2633 12.5248 96.5549 12.1169 96.7706 11.748C96.9811 11.388 97.4259 10.5776 97.3866 9.51786C97.3426 8.33105 96.7735 7.22516 95.8333 6.49953C94.9939 5.85162 94.0758 5.74255 93.6605 5.70458C93.2348 5.66567 92.7333 5.66588 92.3076 5.66606L92.2235 5.66608L11.775 5.66608ZM10.875 11.6806C10.8749 11.6806 10.8771 11.6803 10.8817 11.6799L10.875 11.6806ZM89.503 11.6661L14.4954 11.6661L51.9992 64.1713L89.503 11.6661ZM12.4063 8.71744C12.4039 8.71343 12.4029 8.71149 12.4029 8.71152L12.4063 8.71744Z"
      fill={color}
    />
  </svg>
);
export default NoNotification;
