import * as React from 'react';

const BookMark = ({ color, className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill={color?.fill}
      d="M18.3334 20.1663V9.16634C18.3334 6.99299 18.3334 5.90631 18.0013 5.04117C17.4935 3.71824 16.4482 2.67293 15.1253 2.16511C14.2601 1.83301 13.1734 1.83301 11.0001 1.83301C8.82673 1.83301 7.74005 1.83301 6.87491 2.16511C5.55198 2.67293 4.50667 3.71824 3.99885 5.04117C3.66675 5.90631 3.66675 6.99299 3.66675 9.16634V20.1663L5.27575 19.3618C7.37466 18.3124 8.42412 17.7877 9.5249 17.5811C10.4998 17.3982 11.5003 17.3982 12.4753 17.5811C13.576 17.7877 14.6255 18.3124 16.7244 19.3618L18.3334 20.1663Z"
      stroke={color?.stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BookMark;
