/* eslint-disable complexity */
import React, { useContext, useEffect, useState } from 'react';
import { AppConstant } from '../../constants/AppConstant';
import { AppContext } from '../../context/AppProvider';
import * as track from '../../utils/MixpanelTrackEvent';

const Introduce = ({ value, onChange, onClick, skip, btnColor }) => {
  const context = useContext(AppContext);

  const [IsLight, setIsLight] = useState(false);

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    track.trackSetBioOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  return (
    <div className="h-fit">
      <div className="mt-[32px] mx-[27px] h-[68px]">
        <p className="text-Neutral900 dark:text-Neutral900Dark heading-small-XXL text-center">
          {AppConstant?.INTRODUCE_YOURSELF}
        </p>
        <p className="heading-medium-M text-Neutral800 mt-[10px] text-center">
          {AppConstant?.BIO_DESCRIPTION_WEB}
        </p>
      </div>

      <div className="mx-[22px] mt-[22px]">
        <p className="title-medium-MB text-Neutral900 dark:text-Neutral900Dark">
          {AppConstant?.A_BRIEF_BIO}
        </p>
        <div className="flex items-center px-[10px] bg-Neutral200 dark:bg-Neutral200Dark rounded-[12px] mt-[10px]">
          <textarea
            value={value}
            placeholder="Tip: You can share your role, industry, years of experience, hobbies and more!"
            className="no-input-arrow heading-medium-M h-[198px] max-h-[198px] px-[10px] py-[10px] placeholder-Neutral500 dark:placeholder-Neutral500Dark text-Neutral900 dark:text-Neutral900Dark border-0 border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full bg-transparent dark:border-gray-600 outline-none appearance-none"
            onChange={onChange}
          />
        </div>
      </div>

      <div className="w-full text-center mt-[186px]">
        <div className="w-[252] h-[1px] bg-Neutral400 dark:bg-Neutral400Dark mt-3" />

        <button
          className="bg-Primary h-[53px] mt-[15px] px-[45px] rounded-full items-center mb-[20px]"
          style={btnColor}
          onClick={onClick}
        >
          <p className="title-large-B text-White">{AppConstant?.CONTINUE}</p>
        </button>
        <p
          className="title-large-B text-Action mb-[20px] cursor-pointer"
          onClick={skip}
        >
          {AppConstant?.SKIP}
        </p>
      </div>
    </div>
  );
};

export default Introduce;
