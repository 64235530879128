const asyncKeys = {
  userData: 'PayKES_userData'
};

const setInAsyncStorage = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

const setInSessionStorage = (key, value) => {
  return sessionStorage.setItem(key, JSON.stringify(value));
};
const getFromSessionStorage = key => {
  const value = sessionStorage?.getItem(key);
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

const removeFromSessionStorage = async key => {
  return sessionStorage.removeItem(key);
};

const getFromAsync = key => {
  const value = localStorage?.getItem(key);
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

const removeFromAsync = async key => {
  return localStorage.removeItem(key);
};

const asyncClear = () => localStorage.clear();

export {
  asyncKeys,
  setInAsyncStorage,
  getFromAsync,
  removeFromAsync,
  asyncClear,
  setInSessionStorage,
  getFromSessionStorage,
  removeFromSessionStorage
};
