import * as React from 'react';

const LinkIconFilled = ({ w, h, color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.98005 1.79497C5.04961 1.25 6.44974 1.25 9.25 1.25H14.75C17.5503 1.25 18.9504 1.25 20.02 1.79497C20.9608 2.27433 21.7257 3.03924 22.205 3.98005C22.75 5.04961 22.75 6.44974 22.75 9.25V14.75C22.75 17.5503 22.75 18.9504 22.205 20.02C21.7257 20.9608 20.9608 21.7257 20.02 22.205C18.9504 22.75 17.5503 22.75 14.75 22.75H9.25C6.44974 22.75 5.04961 22.75 3.98005 22.205C3.03924 21.7257 2.27433 20.9608 1.79497 20.02C1.25 18.9504 1.25 17.5503 1.25 14.75V9.25C1.25 6.44974 1.25 5.04961 1.79497 3.98005C2.27433 3.03924 3.03924 2.27433 3.98005 1.79497ZM17.5574 6.49028C15.9545 4.83657 13.3455 4.83657 11.7425 6.49028L11.2688 6.97905C10.9805 7.27647 10.9879 7.75129 11.2853 8.03959C11.5827 8.32788 12.0575 8.32049 12.3458 8.02307L12.8196 7.53429C13.8332 6.48857 15.4667 6.48857 16.4804 7.53429C17.5068 8.59312 17.5068 10.3192 16.4804 11.378L15.3506 12.5436C14.4376 13.4855 12.9673 13.4855 12.0542 12.5436L11.872 12.3556C11.5837 12.0582 11.1089 12.0508 10.8115 12.3391C10.5141 12.6274 10.5067 13.1022 10.795 13.3996L10.9772 13.5876C12.4795 15.1375 14.9253 15.1375 16.4276 13.5876L17.5574 12.422C19.1477 10.7814 19.1477 8.13088 17.5574 6.49028ZM8.64956 11.4564C9.56258 10.5145 11.0329 10.5145 11.9459 11.4564L12.1281 11.6444C12.4164 11.9418 12.8913 11.9492 13.1887 11.6609C13.4861 11.3726 13.4935 10.8978 13.2052 10.6004L13.023 10.4124C11.5206 8.86253 9.07486 8.86253 7.57251 10.4124L6.44272 11.578C4.85243 13.2186 4.85243 15.8691 6.44272 17.5097C8.04571 19.1634 10.6546 19.1634 12.2576 17.5097L12.7314 17.0209C13.0197 16.7235 13.0123 16.2487 12.7149 15.9604C12.4175 15.6721 11.9427 15.6795 11.6544 15.9769L11.1806 16.4657C10.1669 17.5114 8.53342 17.5114 7.51976 16.4657C6.49341 15.4069 6.49341 13.6808 7.51976 12.622L8.64956 11.4564Z"
      fill="#1E92FF"
    />
  </svg>
);
export default LinkIconFilled;
