import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../components/Layout';
import { ASYNC_KEY } from '../constants/AppConstant';
import { useTheme } from 'next-themes';
import { CreatePost } from '../components/createPost';
import { LoginModal } from '../components/login';
import { getFromAsync, ROUTE, setInAsyncStorage } from '../utils';
import { useRouter } from 'next/router';
import { ColorModeContext } from '../../pages/_app';
import AppStartupProvider from './appStartup';
import OpenAppModal from '../components/openAppModal';

export const AppContext = createContext();
const AppProvider = ({ children, pageProps }) => {
  const { theme, setTheme, systemTheme, themes } = useTheme();
  const [createPostModal, setCreatePostModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [createPostType, setCreatePostType] = useState('');
  const [token, setToken] = useState(null);
  const [freePostCheck, setFreePostCheck] = useState(0);
  const router = useRouter();
  const colorMode = useContext(ColorModeContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const appDownloadModalRef = useRef();

  const checkUserAuth = async () => {
    const token = localStorage.getItem(ASYNC_KEY.ACCESS_TOKEN);

    if (token) {
      setIsAuthenticated(true);
    }
  };

  useEffect(() => {
    checkUserAuth();
  }, []);

  useEffect(() => {
    setInAsyncStorage(ASYNC_KEY?.FREE_POST, { date: new Date(), count: 2 });
    setFreePostCheck(2);

    const tokenAsync = getFromAsync(ASYNC_KEY.ACCESS_TOKEN);

    const authorizeRoute = [
      ROUTE.POST_DETAILS,
      ROUTE.INBOX,
      ROUTE.YOU,
      ROUTE.JOBS
      // ROUTE.SALARIES
    ].includes(router.pathname);

    if (authorizeRoute && !tokenAsync) {
      router.push(ROUTE.HOME);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInAsyncStorage(ASYNC_KEY?.FREE_POST, {
      date: new Date(),
      count: freePostCheck
    });
  }, [freePostCheck]);

  useEffect(() => {
    colorMode.setMode(theme);
  }, [theme]);

  useEffect(() => {
    const test = getFromAsync(ASYNC_KEY?.LOCAL_THEME);
    if (test) {
      setTheme(systemTheme);
      colorMode.setMode(systemTheme);
    }
  }, [setTheme, systemTheme]);

  const downloadAppModalShow = () => {
    appDownloadModalRef.current.show();
  };

  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <AppContext.Provider
        value={{
          theme,
          themes,
          setTheme,
          systemTheme,
          createPostModal,
          setCreatePostModal,
          createPostType,
          setCreatePostType,
          loginModal,
          setLoginModal,
          token,
          setToken,
          freePostCheck,
          setFreePostCheck,
          isAuthenticated,
          downloadAppModalShow
        }}
      >
        <AppStartupProvider>
          <ToastContainer limit={1} autoClose={2000} />
          {pageProps.customLayout ? (
            children
          ) : (
            <Layout pageProps={pageProps}>{children}</Layout>
          )}
          <CreatePost
            createPostType={createPostType}
            createPostModal={createPostModal}
            closeModal={() => {
              setCreatePostModal(false);
              setCreatePostType('');
            }}
          />
          <LoginModal
            loginModal={loginModal}
            closeModal={() => {
              setLoginModal(false);
              setTheme(theme);
            }}
          />
          <OpenAppModal ref={appDownloadModalRef} pageProps={pageProps} />
        </AppStartupProvider>
      </AppContext.Provider>
      {/* </PersistGate> */}
    </Provider>
  );
};

export default AppProvider;
