import * as React from 'react';

const Fire = ({ w, h, color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.1268 8.12821C4.19985 9.51262 3.25 11.5317 3.25 14C3.25 18.9625 7.08859 22.75 12 22.75C16.9114 22.75 20.75 18.9625 20.75 14C20.75 9.64306 18.6151 6.46259 16.5273 4.3985C14.9567 2.84575 13.1755 1.83783 11.25 0.811035C11.25 2.3934 13.1182 11.25 10 11.25C7.50784 11.25 8.25 6.63434 8.25 4.88542C7.03689 5.90947 6.04322 6.75953 5.1268 8.12821Z"
      fill={color}
    />
  </svg>
);
export default Fire;
