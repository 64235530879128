import * as React from 'react';

const CircleCut = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="10.25"
      stroke={color}
      strokeWidth="1.5"
      strokeDasharray="3 3"
    />
  </svg>
);
export default CircleCut;
