import * as React from 'react';

const Filter = ({ w, h, color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4H14M4 8H12M6.66667 12H9.33333"
      stroke="#004BCC"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
export default Filter;
