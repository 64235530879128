import * as React from 'react';

const CrossCircle = ({ onClick }) => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.86255 10.2C1.86255 5.1535 5.95355 1.0625 11 1.0625C16.0465 1.0625 20.1375 5.1535 20.1375 10.2C20.1375 15.2465 16.0465 19.3375 11 19.3375C5.95355 19.3375 1.86255 15.2465 1.86255 10.2ZM8.90083 7.19922C8.65187 6.95026 8.24823 6.95026 7.99927 7.19922C7.75031 7.44818 7.75031 7.85182 7.99927 8.10078L10.0985 10.2L7.99927 12.2992C7.75031 12.5482 7.75031 12.9518 7.99927 13.2008C8.24823 13.4497 8.65187 13.4497 8.90083 13.2008L11 11.1016L13.0993 13.2008C13.3482 13.4497 13.7519 13.4497 14.0008 13.2008C14.2498 12.9518 14.2498 12.5482 14.0008 12.2992L11.9016 10.2L14.0008 8.10078C14.2498 7.85182 14.2498 7.44818 14.0008 7.19922C13.7519 6.95026 13.3482 6.95026 13.0993 7.19922L11 9.29844L8.90083 7.19922Z"
      fill="#8B8B8B"
    />
  </svg>
);
export default CrossCircle;
