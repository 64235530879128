import * as React from 'react';

const ImageIcon = ({ w, h, color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 18L6.77044 14.2296C7.55197 13.448 7.94273 13.0573 8.39428 12.9083C8.79155 12.7773 9.21991 12.7741 9.61907 12.8993C10.0728 13.0415 10.4693 13.4265 11.2622 14.1964L14.4861 17.3264M21.5 17.6875L20.0752 16.2627C19.2832 15.4707 18.8872 15.0747 18.4305 14.9263C18.0289 14.7958 17.5962 14.7958 17.1945 14.9263C16.7378 15.0747 16.3416 15.4709 15.5492 16.2633C14.9448 16.8677 14.4861 17.3264 14.4861 17.3264M18.5 21.3403L14.4861 17.3264M18 8C18 9.10457 17.1046 10 16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8ZM11.6 22L12.4 22C15.7603 22 17.4405 22 18.7239 21.346C19.8529 20.7708 20.7708 19.8529 21.346 18.7239C22 17.4405 22 15.7603 22 12.4L22 11.6C22 8.23969 22 6.55953 21.346 5.27606C20.7708 4.14709 19.8529 3.2292 18.7239 2.65396C17.4405 2 15.7603 2 12.4 2L11.6 2C8.23969 2 6.55953 2 5.27606 2.65396C4.14708 3.2292 3.2292 4.14708 2.65396 5.27606C2 6.55953 2 8.23969 2 11.6L2 12.4C2 15.7603 2 17.4405 2.65396 18.7239C3.2292 19.8529 4.14708 20.7708 5.27605 21.346C6.55952 22 8.23968 22 11.6 22Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ImageIcon;
