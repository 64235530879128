import * as React from 'react';

const Reply = ({}) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.48875 3.71967C5.78164 4.01256 5.78164 4.48744 5.48875 4.78033L3.22741 7.04167H10.9792L11.0368 7.04166C11.5429 7.04162 11.8648 7.04159 12.1456 7.07505C14.3235 7.33463 16.0405 9.05161 16.3 11.2295C16.3335 11.5103 16.3335 11.8322 16.3334 12.3383L16.3334 12.3958V12.75C16.3334 13.1642 15.9976 13.5 15.5834 13.5C15.1692 13.5 14.8334 13.1642 14.8334 12.75V12.3958C14.8334 11.8131 14.8321 11.5875 14.8106 11.407C14.633 9.91689 13.4582 8.74211 11.9681 8.56451C11.7876 8.543 11.562 8.54167 10.9792 8.54167H3.22741L5.48875 10.803C5.78164 11.0959 5.78164 11.5708 5.48875 11.8637C5.19585 12.1566 4.72098 12.1566 4.42808 11.8637L0.886418 8.322C0.745766 8.18134 0.666748 7.99058 0.666748 7.79167C0.666748 7.59275 0.745766 7.40199 0.886418 7.26134L4.42808 3.71967C4.72098 3.42678 5.19585 3.42678 5.48875 3.71967Z"
      fill="#8B8B8B"
    />
  </svg>
);
export default Reply;
