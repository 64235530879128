import { CircularProgress } from '@mui/material';
import React from 'react';
import { colors } from '../../themes';

const PageLoader = () => {
  return (
    <div className='h-full flex items-center justify-center'>
      <CircularProgress />
    </div>
  );
};

export default PageLoader;
