import axios from 'axios';
import API_CONSTANT from '../constants/ApiConstant';
import { ASYNC_KEY } from '../constants/AppConstant';
import generateRefreshToken from '../store/GenerateRefreshToken';
import { getFromAsync, removeFromAsync } from '../utils';
import { store } from '../store/store';
import { setIsAuthenticated } from '../store/login/Reducer';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { toast } from 'react-toastify';

const getAccessToken = () =>
  'Bearer ' + localStorage.getItem(ASYNC_KEY.ACCESS_TOKEN)?.replace(/"/g, '');

const getRefereshToken = () => localStorage.getItem(ASYNC_KEY.REFRESH_TOKEN);

const axiosInstance = axios.create({
  baseURL: API_CONSTANT.BASE_URL,
  withCredentials: true,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' }
});

axiosInstance.interceptors.request.use(
  async request => {
    const isNativeWebView = getFromAsync(ASYNC_KEY.NATIVE_WEBVIEW);
    const token = getFromAsync(ASYNC_KEY.ACCESS_TOKEN);

    if (!isNativeWebView && token) {
      const accessToken = getAccessToken();
      const decodedJwt = await jwtDecode(accessToken);
      const jwtExpiry = decodedJwt.exp;
      const currentTime = moment().unix();
      const timeRemainingToExpire = jwtExpiry - currentTime;
      if (timeRemainingToExpire < 30) {
        await generateRefreshToken();
      }
    }

    const userAccessToken = getAccessToken();

    if (userAccessToken?.replace('Bearer ', '')  !== 'undefined') {
      request.headers['Authorization'] = getAccessToken();
      store.dispatch(setIsAuthenticated(true));
    }

    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(undefined, async error => {
  const { config, message } = error;
  let originalRequest = error.config;

  if (error?.response?.status === 401) {
    return Promise.reject(error);
  }

  if (!config || !originalRequest?.retry) {
    toast.dismiss();
    toast.warn('Please Refresh');
    //Sentry.captureException(error);
    return Promise.reject(error);
  }

  if (!(message.includes('timeout') || message.includes('Network Error'))) {
    //Sentry.captureException(error);
    return Promise.reject(error);
  }

  originalRequest.retry -= 1;
  return new Promise(resolve => {
    setTimeout(() => {
      toast.warn('Retrying ...', { autoClose: 1000 });
      resolve(axiosInstance(originalRequest));
    }, originalRequest.retryDelay || 2000);
  });
});

const removeToken = async () => {
  await removeFromAsync(ASYNC_KEY.REFRESH_TOKEN);
  await removeFromAsync(ASYNC_KEY.ACCESS_TOKEN);
};

const get = async (endPoint, params = {}) => {
  return new Promise(async (resolve, reject) => {
    axiosInstance
      .get(`${endPoint}`, { params: params })
      .then(res => {
        resolve(res);
      })
      .catch(async err => {
        reject(err);
      });
  });
};

const getWithOutToken = (endPoint, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_CONSTANT.BASE_URL}${endPoint}`, { params: params })
      .then(res => resolve(res))
      .catch(err => {
        reject(err);
      });
  });
};

const post = (endPoint, data = {}) => {
  return new Promise(async (resolve, reject) => {
    axiosInstance
      .post(`${endPoint}`, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const postWithTokenAndFormHeader = async (endPoint, data = {}) => {
  return new Promise(async (resolve, reject) => {
    const headerWithFormData = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };
    axiosInstance
      .post(`${endPoint}`, data, headerWithFormData)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const postWithOutToken = (endPoint, data = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_CONSTANT.BASE_URL}${endPoint}`, data)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const removePostComment = (endPoint, postData = {}) => {
  return new Promise(async (resolve, reject) => {
    var config = {
      method: 'delete',
      url: `${API_CONSTANT.BASE_URL}${endPoint}`,
      headers: {
        Authorization: getAccessToken(),
        'Content-Type': 'application/json'
      },
      data: postData
    };
    axiosInstance(config)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export {
  get,
  post,
  postWithTokenAndFormHeader,
  postWithOutToken,
  getWithOutToken,
  getAccessToken,
  getRefereshToken,
  removePostComment,
  removeToken
};
