/* eslint-disable complexity */
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppProvider';
import { AppConstant } from '../../constants/AppConstant';
import * as track from '../../utils/MixpanelTrackEvent';

const Otp = ({
  phNo,
  value,
  onChange,
  onKeyDownCapture,
  onClick,
  btnColor,
  loginAPi
}) => {
  const context = useContext(AppContext);

  const [IsLight, setIsLight] = useState(false);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    track.trackOtpOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  useEffect(() => {
    const timer =
      seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [seconds, setSeconds]);

  const resend = () => {
    if (!seconds) {
      loginAPi().then(() => {
        setSeconds(30);
      });
    }
  };

  return (
    <>
      <div className="mt-[32px] mx-[27px] h-[68px]">
        <p className="text-Neutral900 dark:text-Neutral900Dark heading-small-XXL text-center">
          {AppConstant?.VERIFICATION_CODE}
        </p>
        <p className="heading-medium-M text-Neutral800 mt-[10px] text-center">
          {AppConstant?.OTP_MSG} {AppConstant.IN_CODE + phNo}
        </p>
      </div>

      <div className="mx-[22px] mt-[45px]">
        <div className="flex h-[38px] items-center px-[10px] border-b-[1px] border-Primary">
          <input
            type={'tel'}
            maxLength={6}
            value={value}
            placeholder="enter OTP"
            className="no-input-arrow title-extra-large-B h-[38px] placeholder-Neutral500 dark:placeholder-Neutral500Dark text-Neutral900 dark:text-Neutral900Dark border-0 border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full bg-transparent dark:border-gray-600 outline-none appearance-none"
            onChange={onChange}
            onKeyDownCapture={onKeyDownCapture}
          />
        </div>

        <div className="mt-[12px] h-[56px]">
          <p className="heading-small-R text-Neutral800 mt-[15px]">
            {AppConstant?.OTP_TIME_MSG}
            <span
              className={`${
                seconds ? '' : 'text-Primary heading-small-M cursor-pointer'
              } `}
              onClick={() => {
                resend();
                track.trackOtpResendTap();
              }}
            >
              {AppConstant?.RESEND}
            </span>
            {seconds ? AppConstant?.IN_S + seconds : null}
          </p>
        </div>
      </div>

      <div className="flex mt-[182px] justify-center">
        <button
          className="bg-Primary flex h-[53px] px-[45px] rounded-full items-center mb-[30px]"
          style={btnColor}
          onClick={onClick}
        >
          <p className="title-large-B text-White">{AppConstant?.CONTINUE}</p>
        </button>
      </div>
    </>
  );
};

export default Otp;
