import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  isAuthenticated: false
};

const loginSlice = createSlice({
  name: 'loginData',
  initialState,
  reducers: {
    setTokenData(state, action) {
      state.token = action.payload;
    },
    setLogout() {},
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    }
  }
});

export const { setTokenData, setLogout, setIsAuthenticated } =
  loginSlice.actions;
export default loginSlice;
