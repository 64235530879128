import * as React from 'react';

const ShadowBanPost = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.62557 18.9587C7.77727 18.9587 7.92276 18.8984 8.03003 18.7911L12.0835 14.7376C13.1212 13.6999 13.6401 13.1811 14.0111 12.5756C14.3401 12.0387 14.5825 11.4535 14.7295 10.8413C14.8953 10.1507 14.8953 9.41697 14.8953 7.94942L14.8953 5.84199C14.8953 4.16183 14.8953 3.32175 14.5683 2.68002C14.2807 2.11553 13.8217 1.65659 13.2572 1.36897C12.6155 1.04199 11.7754 1.04199 10.0953 1.04199L6.18542 1.04199C4.72394 1.04199 3.9932 1.04199 3.40299 1.30943C2.88281 1.54513 2.44071 1.92441 2.12865 2.40271C1.77458 2.94539 1.66347 3.66763 1.44124 5.11212L1.05166 7.64435C0.92899 8.44173 0.867653 8.84042 0.871104 9.16753C0.891599 11.1098 2.30443 12.7566 4.22097 13.0722C4.54375 13.1253 4.94714 13.1253 5.7539 13.1253L5.95061 13.1253C6.28443 13.1253 6.50949 13.4666 6.378 13.7735L5.52259 15.7694C4.87555 17.2792 5.983 18.9587 7.62557 18.9587Z"
      fill={color}
    />
    <path
      d="M15.9369 10.9378C15.9369 11.8008 16.6365 12.5003 17.4994 12.5003C18.3624 12.5003 19.0619 11.8008 19.0619 10.9378L19.0619 2.60467C19.0619 1.74172 18.3624 1.04217 17.4994 1.04217C16.6365 1.04217 15.9369 1.74172 15.9369 2.60467L15.9369 10.9378Z"
      fill={color}
    />
  </svg>
);
export default ShadowBanPost;
