import * as React from 'react';

const Send = ({ w, h, color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_291_43416)">
      <path
        d="M6.55171 11.2501C6.22548 11.2501 6.06236 11.2501 5.91543 11.2037C5.7854 11.1626 5.66513 11.0954 5.56203 11.0062C5.44553 10.9054 5.36004 10.7665 5.18906 10.4886L4.33517 9.10105C2.14006 5.53399 1.0425 3.75046 1.26959 2.75447C1.46561 1.89475 2.10004 1.20189 2.9392 0.93106C3.91137 0.617305 5.78446 1.55385 9.53066 3.42695L19.5214 8.42234C21.8245 9.57386 22.976 10.1496 23.3455 10.9287C23.6671 11.6067 23.6671 12.3933 23.3455 13.0714C22.976 13.8505 21.8245 14.4262 19.5214 15.5778L9.53066 20.5732C5.78446 22.4462 3.91137 23.3828 2.9392 23.069C2.10004 22.7982 1.46561 22.1053 1.26959 21.2456C1.0425 20.2496 2.14006 18.4661 4.33517 14.899L5.18905 13.5115C5.36002 13.2337 5.44551 13.0947 5.56201 12.9939C5.66512 12.9047 5.78539 12.8375 5.91542 12.7964C6.06235 12.7501 6.22547 12.7501 6.5517 12.7501L11.2498 12.7501C11.664 12.7501 11.9998 12.4143 11.9998 12.0001C11.9998 11.5858 11.664 11.2501 11.2498 11.2501L6.55171 11.2501Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_291_43416">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Send;
