import { createSlice } from '@reduxjs/toolkit';

export const loadingCommunityState = {
  initial: 'INITIAL',
  loading: 'LOADING',
  loaded: 'loaded',
  error: 'ERROR'
};

const initialState = {
  communityList: {
    state: loadingCommunityState.initial,
    data: [],
    error: undefined
  }
};

const getCommunityList = (state, action) => {
  state = {
    ...state,
    communityList: {
      state: loadingCommunityState.loading
    }
  };

  return state;
};

const setCommunityList = (state, action) => {
  const orderedCommunityGroup = communityListToObj(action.payload);

  state = {
    ...state,
    communityList: {
      state: loadingCommunityState.loaded,
      data: orderedCommunityGroup
    }
  };

  return state;
};

const errorCommunityList = (state, action) => {
  state = {
    ...state,
    communityList: {
      state: loadingCommunityState.error,
      error: action?.payload
    }
  };

  return state;
};

const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    getCommunityList,
    setCommunityList,
    errorCommunityList
  }
});

export const communityAction = communitySlice.actions;

export const communityListToObj = communityList => {
  const communityGroup = {};

  for (let index = 0; index < communityList?.length; index++) {
    const groupName = communityList[index]?.group?.name || 'Group';

    if (!communityGroup[groupName]) {
      communityGroup[groupName] = [];
    }

    communityGroup[groupName].push(communityList[index]);
  }

  let orderedCommunityGroup = {};
  const orderList = [
    'Sectors',
    'Careers',
    'Your Interests',
    'Cities',
    'Lifestyle',
    'For everything else'
  ];
  for (let index = 0; index < orderList.length; index++) {
    if (communityGroup.hasOwnProperty(orderList[index])) {
      orderedCommunityGroup[orderList[index]] =
        communityGroup[orderList[index]];
    }
  }

  return { ...orderedCommunityGroup, ...communityGroup };
};

export default communitySlice;
