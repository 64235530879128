import * as React from 'react';

const LinkShare = ({}) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.83333 0.75H4.54167H3.95C2.8299 0.75 2.26984 0.75 1.84202 0.967987C1.46569 1.15973 1.15973 1.46569 0.967987 1.84202C0.75 2.26984 0.75 2.82989 0.75 3.95V8.05C0.75 9.1701 0.75 9.73016 0.967987 10.158C1.15973 10.5343 1.46569 10.8403 1.84202 11.032C2.26984 11.25 2.82989 11.25 3.95 11.25H8.05C9.1701 11.25 9.73016 11.25 10.158 11.032C10.5343 10.8403 10.8403 10.5343 11.032 10.158C11.25 9.73016 11.25 9.1701 11.25 8.05V7.45833V7.16667M6 6L11.25 0.75M11.25 0.75H7.75M11.25 0.75V4.25"
      stroke="#8B8B8B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LinkShare;
