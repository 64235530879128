import React from 'react';
import { colors } from '../../themes';
import icons from '../../assets/leftSide';
import { AppConstant } from '../../constants/AppConstant';
import { DebounceInput } from 'react-debounce-input';

const SearchInput = ({
  rootClass,
  value,
  onChange,
  onClick,
  onClearClicked
}) => {
  return (
    <div
      className={`w-full bg-White dark:bg-DarkBg max-w-[480px] ${rootClass}`}
      onClick={onClick}
    >
      <div className="w-full flex h-[36px] items-center px-[10px] bg-grey border-grey-20 dark:border-darkGrey-20 border dark:bg-Neutral200Dark rounded-[12px]">
        <icons.search w={15.83} h={15.83} color={colors.Neutral800} />
        <DebounceInput
          placeholder={AppConstant?.SEARCH_POSTS}
          debounceTimeout={500}
          value={value}
          className="no-input-arrow heading-medium-M h-[44px] pl-[8px] placeholder-grey-40 dark:placeholder-Neutral500Dark text-grey-40 dark:text-Neutral900Dark border-0 border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full bg-transparent dark:border-gray-600 outline-none appearance-none"
          onChange={onChange}
        />
        {!!value && <icons.crossCircle onClick={onClearClicked} />}
      </div>
    </div>
  );
};

export default SearchInput;
