import React, { useContext, useEffect, useState, useRef } from 'react';
import icons from '../../assets/leftSide';
import { colors } from '../../themes';
import { AppContext } from '../../context/AppProvider';
import { ROUTE, hasValue, showError } from '../../utils';
import { Menu, MenuItem } from '@szhsin/react-menu';
import Image from 'next/image';
import image from '../../assets/Icons';
import { getCommunityAPI } from '../../store/home/Action';
import API_CONSTANT from '../../constants/ApiConstant';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useRouter } from 'next/router';
import { getCommunitySubscrided } from '../../store/login/Action';

let page = -1;
let apiCall = false;

const RenderCommunity = ({ community, setCommunity }) => {
  const context = useContext(AppContext);
  const dispatch = useDispatch();
  const router = useRouter();

  const ref = useRef();
  const [_preventEvent, setPreventEvent] = useState(false);

  const [IsLight, setIsLight] = useState(false);
  const [communityListSize, setCommunityListSize] = useState(0);
  const [communityArr, setCommunityArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  useEffect(() => {
    setIsLoading(false);
    communityApiFun({
      pageCount: 0,
      offset: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const communityApiFun = ({ pageCount, offset }) => {
    apiCall = true;
    const limit = API_CONSTANT.LIMIT;
    const offsets = offset;
    const queryParams = `?limit=${limit}&offset=${offsets}&display_name=${''}`;
    const API = router.pathname.includes(ROUTE.INTRA)
      ? getCommunitySubscrided
      : getCommunityAPI;
    API(dispatch, queryParams)
      .then(res => {
        setIsLoading(false);
        if (res?.data?.data?.results.length !== 0) {
          page = pageCount;
          setCommunityArr([...communityArr, ...res?.data?.data?.results]);
          setCommunityListSize(res?.data?.data?.count);
        }
      })
      .catch(err => {
        showError(err);
      })
      .finally(() => {
        apiCall = false;
        setIsLoading(false);
      });
  };

  const onWheel = () => {
    const { scrollTop, scrollHeight, clientHeight } = ref.current;
    if (
      scrollTop + clientHeight + 200 >= scrollHeight &&
      apiCall === false &&
      communityArr.length !== communityListSize
    ) {
      communityApiFun({
        pageCount: page + 1,
        offset: (page + 1) * 10
      });
    }
  };

  return (
    <Menu
      transition
      menuStyle={{ borderRadius: '7px' }}
      menuButton={
        <div className="mx-[18px] flex w-[270px] h-[40px] items-center mb-[20px] bg-Neutral200 dark:bg-Neutral200Dark rounded-[12px] cursor-pointer">
          <div className="ml-[12px] mr-[10px]">
            {community === '' ? (
              <icons.circleCut
                color={hasValue(
                  IsLight,
                  colors.Neutral900,
                  colors.Neutral900Dark
                )}
              />
            ) : (
              <Image
                priority
                className="w-[24px] h-[24px] rounded-full"
                src={
                  community?.profile_image !== null
                    ? community?.profile_image
                    : image?.communitylogo
                }
                alt="img"
                width={24}
                height={24}
              />
            )}
          </div>
          <p className="w-full title-large-MB text-Neutral900 dark:text-Neutral900Dark max-w-[190px] overflow-hidden text-ellipsis whitespace-nowrap">
            {community === '' ? 'Select a community' : community?.display_name}
          </p>
          <icons.chevronDown
            w={20}
            h={10}
            color={hasValue(IsLight, colors.Neutral900, colors.Neutral900Dark)}
          />
        </div>
      }
    >
      <div className="bg-Neutral200 dark:bg-Neutral200Dark">
        <div
          className="w-[270px] menuStyle justify-center] overflow-auto max-h-[445px]"
          ref={ref}
          onWheel={onWheel}
          onTouchEnd={onWheel}
        >
          <MenuItem className="!p-0 mx-[12px]">
            <div className=" w-full">
              <div>
                {communityArr.map((d, i) => {
                  return (
                    <div
                      key={i}
                      className="flex items-center h-[60px] cursor-default"
                      onClick={() => setCommunity(d)}
                    >
                      <div className="w-[40px] h-[40px] rounded-full">
                        <Image
                          priority
                          className="w-[40px] h-[40px] rounded-full"
                          src={
                            d?.profile_image !== null
                              ? d?.profile_image
                              : image?.communitylogo
                          }
                          alt="img"
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className="ml-[10px]">
                        <p className="text-Neutral900 dark:text-Neutral900Dark title-medium-B">
                          {d?.display_name}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {isLoading && (
                  <div className="flex items-center justify-center h-[60px]">
                    <CircularProgress sx={{}} />
                  </div>
                )}
              </div>
            </div>
          </MenuItem>
        </div>
      </div>
    </Menu>
    // </div>
  );
};

export default RenderCommunity;
