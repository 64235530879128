import * as React from 'react';

const ChevronDownDark = ({ w = 12, h = 12, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 4.5L6 7.5L9 4.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ChevronDownDark;
