import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getDiscoverPostsAPI, getDiscoverPostsUnauthorizedAPI } from './Action';
import API_CONSTANT from '../../constants/ApiConstant';


const initialState = {
  postIdToRelatedPosts: {},
  loading: false,
  error: null
};

export const fetchRelatedPosts = createAsyncThunk(
  'discover/fetchRelatedPosts',
  async ({ postId, limit = API_CONSTANT.LIMIT, page = 0, isAuthenticated }, { rejectWithValue }) => {
    try {
        
      const API = isAuthenticated ? getDiscoverPostsAPI : getDiscoverPostsUnauthorizedAPI;
      const response = await API(postId, limit, page);
      return { postId, posts: response.data?.data?.results ?? response.data.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const discoverSlice = createSlice({
  name: 'discover',
  initialState,
  reducers: {
    setDiscoverPostsByPostId: (state, action) => {
      state.postIdToRelatedPosts= action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRelatedPosts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRelatedPosts.fulfilled, (state, action) => {
        const { postId, posts } = action.payload;
      
        if (action.meta?.arg?.page === 0) {
          state.postIdToRelatedPosts[postId] = posts;
        } else {
          state.postIdToRelatedPosts[postId] = [
            ...(state.postIdToRelatedPosts[postId] || []),
            ...posts
          ];
        }
        state.loading = false;
      })
      .addCase(fetchRelatedPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setDiscoverPostsByPostId } = discoverSlice.actions

export default discoverSlice;
