import * as React from 'react';

const Warn = ({ w, h, color, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99984 8.66667V6M8.33317 11C8.33317 11.1841 8.18393 11.3333 7.99984 11.3333C7.81574 11.3333 7.6665 11.1841 7.6665 11M8.33317 11C8.33317 10.8159 8.18393 10.6667 7.99984 10.6667C7.81574 10.6667 7.6665 10.8159 7.6665 11M8.33317 11H7.6665M12.8872 6.72745L12.6532 6.30958C11.1323 3.59376 10.3719 2.23584 9.37158 1.78417C8.49947 1.39039 7.5002 1.39039 6.6281 1.78417C5.62776 2.23584 4.86733 3.59376 3.34647 6.30958L3.11247 6.72745C1.65054 9.33803 0.919579 10.6433 1.04185 11.7122C1.14852 12.6447 1.64341 13.489 2.40492 14.0377C3.27776 14.6667 4.77378 14.6667 7.76583 14.6667H8.23384C11.2259 14.6667 12.7219 14.6667 13.5948 14.0377C14.3563 13.489 14.8512 12.6447 14.9578 11.7122C15.0801 10.6433 14.3491 9.33803 12.8872 6.72745Z"
      stroke="#8B8B8B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Warn;
