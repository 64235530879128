import { combineReducers, configureStore, createSlice } from '@reduxjs/toolkit';
import loginSlice from './login/Reducer';
import homeSlice from './home/Reducer';
import userSlice from './user/Reducer';
import notifSlice from './notification/Reducer';
import searchSlice from './search/Reducer';
import discoverSlice from './discover/Reducer';
import intraSlice from './intra/Reducer';
import SalarySlice from './salaries/Reducer';
import communitySlice from './community/reducer';
import trendingPostSlice from './trendingPost/Reducer';
import uiFlagsSlice from './uiFlags/Reducer';
import companyPostSlice from './companyPost/reducer';
import communityPostSlice from './communityPost/reducer';
import discoverPostsSlice from './discoverPosts/discoverPostsSlice';

const global = createSlice({
  name: 'global',
  initialState: { screenHeight: {}, scrollHeight: {} },
  reducers: {
    setScreenHeight(state, action) {
      state.screenHeight = { ...state.screenHeight, ...action.payload };
    },
    setScrollHeight(state, action) {
      state.scrollHeight = { ...state.scrollHeight, ...action.payload };
    }
  }
});

const reducers = combineReducers({
  login: loginSlice.reducer,
  postFeed: homeSlice.reducer,
  intraFeed: intraSlice.reducer,
  user: userSlice.reducer,
  notification: notifSlice.reducer,
  search: searchSlice.reducer,
  discover: discoverSlice.reducer,
  global: global.reducer,
  salaries: SalarySlice.reducer,
  community: communitySlice.reducer,
  trendingPost: trendingPostSlice.reducer,
  uiFlags: uiFlagsSlice.reducer,
  companyPost: companyPostSlice.reducer,
  communityPost: communityPostSlice.reducer,
  discoverPosts: discoverPostsSlice.reducer
});

export const { setScreenHeight, setScrollHeight } = global.actions;

export const store = configureStore({
  reducer: reducers
});

// const persistedReducer = persistReducer(persistConfig, reducers);

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
//       }
//     })
// });

// export const persistor = persistStore(store);
