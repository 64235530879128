import * as React from 'react';

const Rocket = ({ color }) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8782 1.5375C13.1972 1.57728 12.8566 1.59716 12.535 1.67005C12.0157 1.78774 11.5252 2.0079 11.0921 2.31765C10.8239 2.50951 10.5838 2.74957 10.1037 3.22969L4.64853 8.68485C3.22822 10.1052 3.21817 12.4048 4.62599 13.8375C4.80416 14.0188 5.09598 14.0201 5.27573 13.8403L7.44938 11.6667C7.69345 11.4226 8.08918 11.4226 8.33326 11.6667C8.57734 11.9108 8.57734 12.3065 8.33326 12.5506L6.15963 14.7242C5.97987 14.904 5.98115 15.1958 6.16247 15.374C7.5952 16.7818 9.89494 16.7718 11.3153 15.3514L16.7929 9.87383C17.2629 9.40378 17.498 9.16876 17.6868 8.90662C17.9973 8.47551 18.2188 7.98685 18.3384 7.46918C18.4111 7.15441 18.433 6.82276 18.4768 6.15945C18.5689 4.76331 18.6149 4.06524 18.437 3.51691C18.1427 2.6104 17.4353 1.89761 16.531 1.59659C15.9841 1.4145 15.2821 1.4555 13.8782 1.5375ZM14.1687 7.4996C14.1687 8.42008 13.4225 9.16627 12.5021 9.16627C11.5816 9.16627 10.8354 8.42008 10.8354 7.4996C10.8354 6.57913 11.5816 5.83294 12.5021 5.83294C13.4225 5.83294 14.1687 6.57913 14.1687 7.4996Z"
      fill={color}
    />
    <path
      d="M1.7583 7.71294C1.33544 7.42997 1.12401 7.28849 1.01219 7.11644C0.834977 6.84375 0.801431 6.50192 0.922255 6.19999C0.998488 6.00949 1.17838 5.8296 1.53817 5.46982L1.85039 5.15761C2.28897 4.71904 2.50826 4.49976 2.7406 4.32651C3.58196 3.69913 4.63618 3.42756 5.67594 3.57035C5.96307 3.60979 6.26101 3.69583 6.8569 3.86791C7.04943 3.92352 7.14569 3.95132 7.20561 3.9891C7.42956 4.13031 7.50461 4.42163 7.37676 4.65348C7.34256 4.7155 7.27171 4.78635 7.13 4.92806L3.77263 8.28543C3.60717 8.45089 3.52444 8.53362 3.427 8.57173C3.34109 8.60534 3.24754 8.61452 3.15673 8.59826C3.05374 8.57982 2.95651 8.51475 2.76203 8.38461L1.7583 7.71294Z"
      fill={color}
    />
    <path
      d="M12.0321 16.796C11.902 16.6016 11.8369 16.5043 11.8184 16.4013C11.8022 16.3105 11.8113 16.217 11.845 16.131C11.8831 16.0336 11.9658 15.9509 12.1313 15.7854L15.4887 12.428C15.6304 12.2862 15.7013 12.2154 15.7633 12.1812C15.9951 12.0534 16.2864 12.1284 16.4276 12.3523C16.4654 12.4122 16.4932 12.5085 16.5489 12.701C16.7211 13.2972 16.8073 13.5953 16.8468 13.8825C16.9896 14.9223 16.7181 15.9766 16.0908 16.818C15.9175 17.0505 15.6981 17.2699 15.2593 17.7087L14.9473 18.0206C14.5876 18.3803 14.4078 18.5601 14.2174 18.6363C13.9154 18.7572 13.5735 18.7237 13.3008 18.5464C13.1288 18.4346 12.9873 18.2233 12.7044 17.8006L12.0321 16.796Z"
      fill={color}
    />
  </svg>
);
export default Rocket;
