import '../styles/globals.css';
import { createContext, useEffect, useMemo, useState } from 'react';
import AppProvider from './../src/context/AppProvider';
import { ThemeProvider } from 'next-themes';
import { DefaultSeo, OrganizationJsonLd } from 'next-seo';
import seoConfig from '../seo.config.js';
import Script from 'next/script';
import { getFromAsync } from '../src/utils';
import {
  useTheme,
  ThemeProvider as MuiThemeProvider,
  createTheme
} from '@mui/material/styles';
import { NavigationProvider } from '../src/context/NavigationProvider.js';

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
  setMode: val => {}
});

function App({ Component, pageProps }) {
  const [mode, setMode] = useState('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      },
      setMode: val => {
        setMode(val);
      }
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode
        }
      }),
    [mode]
  );

  useEffect(() => {
    // mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
    //   api_host: process.env.NEXT_PUBLIC_MIXPANEL_HOST,
    //   debug: process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ? true : false,
    //   ignore_dnt: true,
    //   batch_requests: false,
    //   track_pageview: true,
    //   autotrack: true
    // });
    const user = getFromAsync('user');
    // mixpanel.identify(user?.id);
    // window.mixpanel = mixpanel;
  }, []);

  const render = (
    <>
      <DefaultSeo {...seoConfig} />
      <OrganizationJsonLd
        type="Organization"
        url="https://grapevine.in"
        logo="https://grapevine.in/images/logo.png"
        name="Grapevine"
        legalName="Grapevine"
        alternateName={['Grapevine']}
        sameAs={['https://in.linkedin.com/company/join-grapevine']}
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
      />
      <Script id="gtm-script" strategy="afterInteractive">
        {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}', {
page_path: window.location.pathname,
});`}
      </Script>
      {process.env.NEXT_PUBLIC_ENVIRONMENT !== 'staging' ? (
        <Script id="facebook-pixel-script" strategy="afterInteractive">
          {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '911941567096829');
fbq('track', 'PageView');`}
        </Script>
      ) : null}
      <Component {...pageProps} />
    </>
  );
  return (
    <ColorModeContext.Provider value={colorMode}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider attribute="class" defaultTheme="light">
          {pageProps.emptyPage ? (
            render
          ) : (
            <NavigationProvider>
              <AppProvider pageProps={pageProps}>{render}</AppProvider>
            </NavigationProvider>
          )}
        </ThemeProvider>
      </MuiThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
