import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  posts: [],
  tabData: [],
  height: 0,
  currentTab: 'Posts'
};

const userSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setTabData(state, action) {
      state.tabData = action.payload;
    },
    setUserData(state, action) {
      state.user = action.payload;
    },
    setUserPosts(state, action) {
      state.posts = action.payload;
    },
    setTabDataAdd(state, action) {
      state.tabData = [...state.tabData, ...action.payload];
    },
    setTabDataClear(state) {
      state.tabData = [];
    },
    setProfileScreenHeight(state, action) {
      state.height = action.payload;
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    }
  }
});

export const {
  setUserData,
  setUserPosts,
  setTabDataAdd,
  setTabDataClear,
  setProfileScreenHeight,
  setCurrentTab,
  setTabData
} = userSlice.actions;
export default userSlice;
