import * as React from 'react';

const AppStore = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7449 11.0789H14.4976M12.7449 11.0789L14.0476 13.25M12.7449 11.0789L11.1226 8.375M5.49756 11.0789H7.25019M7.25019 11.0789H10.7476M7.25019 11.0789L9.99756 6.5M5.94756 13.25L6.39756 12.5M9.99756 6.5L10.8976 5M9.99756 6.5L9.09756 5M2.49756 10.6V8.4C2.49756 6.15979 2.49756 5.03968 2.93353 4.18404C3.31703 3.43139 3.92895 2.81947 4.6816 2.43597C5.53724 2 6.65735 2 8.89756 2H11.0976C13.3378 2 14.4579 2 15.3135 2.43597C16.0662 2.81947 16.6781 3.43139 17.0616 4.18404C17.4976 5.03968 17.4976 6.15979 17.4976 8.4V10.6C17.4976 12.8402 17.4976 13.9603 17.0616 14.816C16.6781 15.5686 16.0662 16.1805 15.3135 16.564C14.4579 17 13.3378 17 11.0976 17H8.89756C6.65735 17 5.53724 17 4.6816 16.564C3.92895 16.1805 3.31703 15.5686 2.93353 14.816C2.49756 13.9603 2.49756 12.8402 2.49756 10.6Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default AppStore;
