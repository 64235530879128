import * as React from 'react';

const ShareSmall = ({}) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5104 3.71967C11.8033 3.42678 12.2782 3.42678 12.5711 3.71967L16.1128 7.26134C16.2534 7.40199 16.3324 7.59275 16.3324 7.79167C16.3324 7.99058 16.2534 8.18134 16.1128 8.322L12.5711 11.8637C12.2782 12.1566 11.8033 12.1566 11.5104 11.8637C11.2176 11.5708 11.2176 11.0959 11.5104 10.803L13.7718 8.54167H6.01995C5.43722 8.54167 5.21163 8.543 5.03114 8.56451C3.541 8.74211 2.36622 9.91689 2.18862 11.407C2.16711 11.5875 2.16578 11.8131 2.16578 12.3958V12.75C2.16578 13.1642 1.82999 13.5 1.41578 13.5C1.00156 13.5 0.665778 13.1642 0.665778 12.75V12.3958C0.665778 12.3764 0.665776 12.3572 0.665775 12.3383C0.665729 11.8322 0.6657 11.5102 0.699161 11.2295C0.958739 9.05161 2.67572 7.33463 4.85362 7.07505C5.13436 7.04159 5.45628 7.04162 5.96241 7.04166C5.98133 7.04166 6.00051 7.04167 6.01995 7.04167H13.7718L11.5104 4.78033C11.2176 4.48744 11.2176 4.01256 11.5104 3.71967Z"
      fill="#8B8B8B"
    />
  </svg>
);
export default ShareSmall;
