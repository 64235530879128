import * as React from 'react';

const Share = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39746 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 11.2491 1.94163 12.4341 2.43433 13.4977C2.82342 14.3376 3.19169 15.0537 2.92029 15.9981C2.78974 16.4524 2.46258 16.8998 2.42372 17.3728C2.37929 17.9139 2.85574 18.3523 3.39121 18.2631C4.48142 18.0814 5.00617 17.2145 6.2711 17.512C6.4082 17.5442 6.7655 17.672 7.48006 17.9276C8.23612 18.1981 9.05037 18.3333 9.99984 18.3333Z"
      stroke="#777777"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.92545 5.83338C6.86544 5.83338 6.83543 5.83338 6.79748 5.83627C6.30224 5.87403 5.86953 6.34478 5.87354 6.84145C5.87384 6.87951 5.8751 6.89439 5.87761 6.92414C5.98436 8.18952 6.2978 9.41965 6.95638 10.5354C7.54419 11.5313 8.347 12.3523 9.3267 12.9602C10.5562 13.7231 11.9368 14.0557 13.354 14.1425C13.8024 14.17 14.1669 13.8026 14.1669 13.3534C14.1669 12.607 13.6589 11.9564 12.9347 11.7753L12.244 11.6027C11.7942 11.4902 11.3238 11.6848 11.0848 12.0821C10.943 12.318 10.6425 12.4065 10.4015 12.2735C9.14727 11.5816 8.40723 10.8386 7.71582 9.57899C7.58981 9.34941 7.67499 9.06341 7.90047 8.93019C8.30907 8.68876 8.51035 8.20744 8.39524 7.74701L8.15727 6.79515C8.13804 6.71822 8.12843 6.67976 8.11775 6.64595C7.9728 6.18705 7.56014 5.86486 7.07979 5.83554C7.0444 5.83338 7.00475 5.83338 6.92545 5.83338Z"
      fill="#777777"
    />
  </svg>
);
export default Share;
