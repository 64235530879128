import * as React from 'react';

const ImageIconFilled = ({ w, h, color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.85 22.75C7.48969 22.75 5.80953 22.75 4.52606 22.096C3.39708 21.5208 2.4792 20.6029 1.90396 19.4739C1.25 18.1905 1.25 16.5103 1.25 13.15V10.85C1.25 7.48969 1.25 5.80953 1.90396 4.52606C2.4792 3.39708 3.39708 2.4792 4.52606 1.90396C5.80953 1.25 7.48969 1.25 10.85 1.25H13.15C16.5103 1.25 18.1905 1.25 19.4739 1.90396C20.6029 2.4792 21.5208 3.39708 22.096 4.52606C22.75 5.80953 22.75 7.48969 22.75 10.85V13.15C22.75 16.5103 22.75 18.1905 22.096 19.4739C21.5208 20.6029 20.6029 21.5208 19.4739 22.096C18.1905 22.75 16.5103 22.75 13.15 22.75H10.85ZM16 10C17.1046 10 18 9.10457 18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10ZM15.584 16.2285C16.3438 15.4688 16.7236 15.0889 17.1584 14.9385C17.5821 14.7918 18.0429 14.7918 18.4666 14.9385C18.9014 15.0889 19.2813 15.4688 20.041 16.2285L20.2541 16.4416C20.4609 16.6484 20.5643 16.7519 20.6382 16.9059C20.6928 17.0198 20.7369 17.2158 20.7364 17.3422C20.7358 17.513 20.7003 17.6124 20.6294 17.8114C20.2233 18.951 19.3703 19.8944 18.2699 20.455C17.7927 20.6982 17.198 20.8449 16.2593 20.9216C15.309 20.9992 14.0966 21 12.4 21H11.6C9.90333 21 8.69098 20.9992 7.74063 20.9216C6.80196 20.8449 6.2073 20.6982 5.73004 20.455C4.90917 20.0368 4.16542 19.3678 3.69345 18.556C3.57834 18.358 3.52078 18.259 3.49434 18.0614C3.47529 17.919 3.50643 17.6797 3.56128 17.5469C3.63742 17.3626 3.75418 17.2459 3.9877 17.0123L6.77044 14.2296C7.55197 13.4481 7.94273 13.0573 8.39428 12.9084C8.79155 12.7773 9.21991 12.7742 9.61907 12.8993C10.0728 13.0416 10.4693 13.4265 11.2622 14.1964L13.3721 16.2449C13.7524 16.6141 13.9426 16.7987 14.1587 16.871C14.3692 16.9414 14.5973 16.9397 14.8068 16.8662C15.0218 16.7908 15.2092 16.6034 15.584 16.2285Z"
      fill="#1E92FF"
    />
  </svg>
);
export default ImageIconFilled;
