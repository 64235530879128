import * as React from 'react';

const DLink = ({ w, h, color, ...props }) => (
  <svg
    width="22.8"
    height="22.8"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1832 16.274L11.7331 16.7383C10.4902 18.0206 8.47509 18.0206 7.23218 16.7383C5.98927 15.4561 5.98927 13.3772 7.23218 12.095L8.30549 10.9877C9.45278 9.8041 11.3129 9.8041 12.4602 10.9877L12.6333 11.1663M11.8169 7.72601L12.267 7.26167C13.5099 5.97944 15.5251 5.97944 16.768 7.26167C18.0109 8.5439 18.0109 10.6228 16.768 11.905L15.6947 13.0123C14.5474 14.1959 12.6872 14.1959 11.5399 13.0123L11.3668 12.8337M10.1 21.5H13.9C16.5602 21.5 17.8904 21.5 18.9065 20.9823C19.8002 20.5269 20.5269 19.8002 20.9823 18.9065C21.5 17.8904 21.5 16.5602 21.5 13.9V10.1C21.5 7.43975 21.5 6.10963 20.9823 5.09355C20.5269 4.19978 19.8002 3.47312 18.9065 3.01772C17.8904 2.5 16.5602 2.5 13.9 2.5H10.1C7.43975 2.5 6.10963 2.5 5.09355 3.01772C4.19978 3.47312 3.47312 4.19978 3.01772 5.09355C2.5 6.10963 2.5 7.43975 2.5 10.1V13.9C2.5 16.5602 2.5 17.8904 3.01772 18.9065C3.47312 19.8002 4.19978 20.5269 5.09355 20.9823C6.10963 21.5 7.43975 21.5 10.1 21.5Z"
      stroke={color}
      strokeWidth="1.425"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DLink;
