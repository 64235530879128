import * as React from 'react';

const ArrowLeftCorner = ({ color }) => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25 7.16667L3.33333 9.25M1.25 7.16667L3.33333 5.08333M1.25 7.16667H3.33333C4.65107 7.16667 5.30994 7.16667 5.84835 7.01291C7.17762 6.63332 8.21666 5.59428 8.59625 4.26502C8.75 3.72661 8.75 3.06774 8.75 1.75V1.75"
      stroke="#8B8B8B"
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ArrowLeftCorner;
