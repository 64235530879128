import axios from 'axios';
import { AppConstant } from '../constants/AppConstant';
import API_CONSTANT from '../constants/ApiConstant';
import { toast } from 'react-toastify';

const domainUrl = 'https://share.gvine.app/?';
const domainUrlPrefix = 'https://share.gvine.app';

const fallbackUrl = param => {
  if (param?.vineId) {
    return `https://grapevine.in/post/${param?.vineId}`;
  }

  if (param?.postId) {
    return `https://grapevine.in/post/${param?.postId}`;
  } else {
    return 'https://grapevine.in';
  }
};
const appStoreId = '1641708426';

const buildLinkWithAPI = async param => {
  var prms = new URLSearchParams(param);
  const androidPackageName = API_CONSTANT.ANDROID_BUNDLE_ID;
  const iosBundleId = API_CONSTANT.IOS_BUNDLE_ID;
  const URL = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=';

  const params = {
    dynamicLinkInfo: {
      androidInfo: {
        androidPackageName: androidPackageName
      },
      domainUriPrefix: domainUrlPrefix,
      iosInfo: {
        iosAppStoreId: appStoreId,
        iosBundleId: iosBundleId
      },
      link: `${domainUrl}${prms.toString()}`,
      navigationInfo: {
        enableForcedRedirect: true
      },
      desktopInfo: {
        desktopFallbackLink: fallbackUrl(param)
      }
    }
  };

  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${URL}${API_CONSTANT?.WEB_API_KEY}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    })
      .then(res => {
        let link = res.data.shortLink;

        resolve(link);
      })
      .catch(err => {
        toast(err);
        reject(err);
      });
  });
};

const buildLink = async (param, data = '', isOnlyLink = false) => {
  const link = await buildLinkWithAPI(param);

  if (isOnlyLink) {
    return link;
  }

  const handleComment = comment => {
    if (comment < 4) {
      return '';
    } else if (comment < 11) {
      return `${comment}${AppConstant.COMMENT_SHARE_MSG}\n`;
    } else if (comment > 10) {
      return `${comment}+${AppConstant.COMMENT_SHARE_MSG}\n`;
    }
  };

  const linkText = (postTitle = '', description = '', comment = '') =>
    `${postTitle}\n\n${
      description?.length > 0 ? `${description?.substring(0, 200)}\n\n` : ''
    }${handleComment(comment)}${AppConstant.POST_SHARE_MSG}${link}`;

  const message = () => {
    if (param?.type === AppConstant.POST) {
      return linkText(data?.title, data?.description, data?.comment);
    } else if (param?.type === AppConstant.COMMUNITY) {
      return `${AppConstant.COMMUNITY_SHARE_MSG}${link}`;
    } else if (param?.companyId) {
      return `${AppConstant.COMMUNITY_SHARE_MSG}${link}`;
    }
  };
  return message();
};

export default buildLink;
