import * as React from 'react';

const TypePopular = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33398 11.667C3.33398 15.4549 6.25065 18.3337 10.0007 18.3337C13.7507 18.3337 16.6673 15.4549 16.6673 11.667C16.6673 4.84881 10.0007 1.66699 10.0007 1.66699C10.0007 5.00033 10.834 10.0003 8.33398 10.0003C6.66732 10.0003 6.25065 8.33366 6.25065 5.41699C6.25065 5.41699 3.33398 7.87911 3.33398 11.667Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default TypePopular;
