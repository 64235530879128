import React, { useContext, useEffect, useState } from 'react';
import icons from '../../assets/leftSide';
import { colors } from '../../themes';
import { AppContext } from '../../context/AppProvider';
import { hasValue } from '../../utils';
import { AppConstant } from '../../constants/AppConstant';

const RenderHeader = ({ closeModal }) => {
  const [IsLight, setIsLight] = useState(false);

  const context = useContext(AppContext);

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  return (
    <div className="px-[18px] flex h-[48px] mt-[15px] items-center">
      <p className="w-full ml-[4px] title-extra-large-B text-Neutral900 dark:text-Neutral900Dark">
        {AppConstant?.CREATE_POST}
      </p>
      <div className="cursor-pointer" onClick={() => closeModal()}>
        <icons.cross
          w={24}
          h={24}
          color={hasValue(IsLight, colors.Neutral900, colors.Neutral900Dark)}
        />
      </div>
    </div>
  );
};

export default RenderHeader;
