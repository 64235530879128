import * as React from 'react';

const Notification = ({ w, h, color }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0001 0.166504C6.7439 0.166504 3.07942 3.17066 2.24472 7.34416L0.886442 14.1355C0.432706 16.4042 1.63683 18.6827 3.73877 19.6169C4.45849 19.9368 5.1965 20.2065 5.94706 20.4262L6.02407 20.5994C6.89805 22.5659 8.84813 23.8332 11.0001 23.8332C13.152 23.8332 15.1021 22.5659 15.976 20.5994L16.053 20.4262C16.8036 20.2065 17.5416 19.9368 18.2613 19.6169C20.3633 18.6827 21.5674 16.4042 21.1137 14.1355L19.7554 7.34416C18.9207 3.17066 15.2562 0.166504 11.0001 0.166504ZM15.1747 18.5957C15.1102 18.6062 15.047 18.6228 14.9861 18.6454C12.3791 19.3152 9.62105 19.3152 7.01406 18.6454C6.95308 18.6228 6.8899 18.6062 6.82545 18.5957C6.05058 18.3864 5.28978 18.1176 4.55105 17.7893C3.29195 17.2297 2.58001 15.8657 2.8476 14.5278L4.20588 7.7364C4.85361 4.49774 7.69726 2.1665 11.0001 2.1665C14.3028 2.1665 17.1465 4.49774 17.7942 7.7364L19.1525 14.5278C19.4201 15.8657 18.7082 17.2297 17.4491 17.7893C16.7103 18.1176 15.9495 18.3864 15.1747 18.5957ZM8.76417 21.0092C10.248 21.194 11.7521 21.194 13.2359 21.0092C12.6242 21.5308 11.8362 21.8332 11.0001 21.8332C10.1639 21.8332 9.37589 21.5308 8.76417 21.0092Z"
      fill={color}
    />
  </svg>
);
export default Notification;
