import * as React from 'react';

const TypeMonth = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_577_72820)">
      <path
        d="M10.3327 1.66699L9.66601 1.66699C6.86575 1.66699 5.46562 1.66699 4.39606 2.21196C3.45525 2.69133 2.69035 3.45623 2.21098 4.39704C1.66602 5.4666 1.66602 6.86673 1.66602 9.667L1.66602 10.3337C1.66602 13.1339 1.66602 14.5341 2.21099 15.6036C2.69035 16.5444 3.45526 17.3093 4.39607 17.7887C5.46562 18.3337 6.86576 18.3337 9.66602 18.3337L10.3327 18.3337C13.1329 18.3337 14.5331 18.3337 15.6026 17.7887C16.5434 17.3093 17.3084 16.5444 17.7877 15.6036C18.3327 14.534 18.3327 13.1339 18.3327 10.3337L18.3327 9.66699C18.3327 6.86673 18.3327 5.4666 17.7877 4.39704C17.3083 3.45623 16.5434 2.69132 15.6026 2.21196C14.5331 1.66699 13.1329 1.66699 10.3327 1.66699Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.67018 13.2028C4.33407 13.2028 4.16602 13.0378 4.16602 12.7078C4.16602 12.39 4.33407 12.2312 4.67018 12.2312L5.69685 12.2312L5.69685 8.07866L4.91768 8.54616C4.77102 8.63171 4.63657 8.6531 4.51435 8.61033C4.39824 8.56755 4.30963 8.49116 4.24852 8.38116C4.1874 8.27116 4.16907 8.15199 4.19352 8.02366C4.21796 7.88921 4.30352 7.77921 4.45018 7.69366L5.71518 6.93283C5.8374 6.85949 5.95963 6.79838 6.08185 6.74949C6.20407 6.69449 6.31713 6.66699 6.42102 6.66699C6.54935 6.66699 6.65629 6.70366 6.74185 6.77699C6.8274 6.84421 6.87018 6.95727 6.87018 7.11616L6.87018 12.2312L7.80518 12.2312C8.14129 12.2312 8.30935 12.39 8.30935 12.7078C8.30935 13.0378 8.14129 13.2028 7.80518 13.2028L4.67018 13.2028Z"
        fill={color}
      />
      <path
        d="M9.99244 13.2762C9.63799 13.2762 9.46077 13.0928 9.46077 12.7262L9.46077 7.21699C9.46077 6.85032 9.6441 6.66699 10.0108 6.66699C10.1635 6.66699 10.2827 6.69755 10.3683 6.75866C10.4599 6.81366 10.5424 6.91144 10.6158 7.05199L12.7333 10.9662L14.8508 7.05199C14.9241 6.91144 15.0035 6.81366 15.0891 6.75866C15.1747 6.69755 15.2938 6.66699 15.4466 6.66699C15.801 6.66699 15.9783 6.85032 15.9783 7.21699L15.9783 12.7262C15.9783 13.0928 15.801 13.2762 15.4466 13.2762C15.0922 13.2762 14.9149 13.0928 14.9149 12.7262L14.9149 8.84866L13.1916 11.9928C13.1305 12.0967 13.0663 12.1731 12.9991 12.222C12.9319 12.2709 12.8402 12.2953 12.7241 12.2953C12.608 12.2953 12.5133 12.2709 12.4399 12.222C12.3666 12.167 12.3024 12.0906 12.2474 11.9928L10.5241 8.86699L10.5241 12.7262C10.5241 13.0928 10.3469 13.2762 9.99244 13.2762Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_577_72820">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default TypeMonth;
