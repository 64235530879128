/* eslint-disable complexity */
import React, { useContext, useEffect, useState } from 'react';
import icons from '../../assets/leftSide';
import { colors } from '../../themes';
import { useRouter } from 'next/router';
import { AppContext } from '../../context/AppProvider';
import { hasValue, ROUTE, showError } from '../../utils';
import { AppConstant, POST_TYPE } from '../../constants/AppConstant';
import { CircularProgress, Dialog } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RenderCommunity, RenderHeader } from './';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { CreatePostAPI, postImg } from '../../store/home/Action';
import { useDispatch } from 'react-redux';
import Compressor from 'compressorjs';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  // width: 100,
  // height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const CreatePost = ({ createPostModal, createPostType, closeModal }) => {
  const router = useRouter();
  const context = useContext(AppContext);
  const dispatch = useDispatch();

  const [IsLight, setIsLight] = useState(false);

  const [selectedType, setSelectedPost] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [pollDescription, setPollDescription] = useState('');
  const [link, setLink] = useState('');
  const [community, setCommunity] = useState('');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextBtnDisappear, setNextBtnDisappear] = useState(true);

  const matches = useMediaQuery('(min-width:600px)');

  const [pollOption, setPollOption] = useState([{ title: '' }, { title: '' }]);

  let arr = [
    {
      id: 1,
      title: AppConstant?.TEXT,
      type: POST_TYPE?.TEXT,
      Icon: icons.textIcon,
      IconF: icons.textIconFilled
    },
    {
      id: 2,
      title: AppConstant?.POLL,
      type: POST_TYPE?.POLL,
      Icon: icons.pollIcon,
      IconF: icons.pollIconFilled
    },
    {
      id: 3,
      title: AppConstant?.IMAGE,
      type: POST_TYPE?.IMAGE,
      Icon: icons.imageIcon,
      IconF: icons.imageIconFilled
    },
    {
      id: 4,
      title: AppConstant?.LINK,
      type: POST_TYPE?.LINK,
      Icon: icons.linkIcon,
      IconF: icons.linkIconFilled
    }
  ];

  useEffect(() => {
    setSelectedPost(createPostType === '' ? POST_TYPE?.TEXT : createPostType);
  }, [createPostType]);
  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  useEffect(
    () => () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: { 'image/*': [] },
    multiple: false,
    onDrop: acceptedFiles => {
      const maxImgSize = (acceptedFiles[0].size / 1000000).toFixed(2);
      if (acceptedFiles[0]?.type.includes('image')) {
        if (maxImgSize <= 10.51) {
          setFiles(
            acceptedFiles.map(file =>
              Object.assign(file, {
                preview: URL.createObjectURL(file)
              })
            )
          );
        } else {
          toast.error(AppConstant?.ALERT_SELECTED_IMAGE_MB);
        }
      } else {
        toast.error(AppConstant?.UPLOAD_IMAGE_ERROR);
      }
    }
  });

  const removeFile = file => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const thumbs = files.map(file => (
    <div className="relative" style={thumb} key={file.name}>
      <div style={thumbInner} className=" border-[0px]">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={file.preview} style={img} alt={file.name} />
      </div>
      <div
        className="flex absolute -top-1 -right-1 bg-Warning rounded-full w-[24px] h-[24px] justify-center items-center"
        onClick={removeFile(file)}
      >
        <icons.cross w={16} h={16} color={colors.White} />
      </div>
    </div>
  ));

  const _addNewPollOption = () => {
    let arr = [...pollOption];
    setPollOption([...arr, { title: '' }]);
  };

  const _addPolls = (e, ind) => {
    let arr = pollOption?.map((d, i) => {
      if (i === ind) {
        return { ...d, title: e.target.value };
      } else {
        return d;
      }
    });
    setPollOption(arr);
  };

  const _removePollOption = ind => {
    setPollOption(old => old.filter((e, i) => i !== ind));
  };

  const _onSelectPollType = val => [setSelectedPost(val?.type)];

  const validLink = link => {
    const regeXurl =
      '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$';
    var _url = new RegExp(regeXurl, 'g');
    const check = _url.test(link);
    return check;
  };

  const checkPollOptionsEmpty = () => {
    let emptyVal = false;
    pollOption.map(res => {
      if (res.title.trim() === '') {
        emptyVal = true;
      }
    });
    return emptyVal;
  };

  const handlePressNext = () => {
    // if (selectedType === POST_TYPE.TEXT && description.trim() === '') {
    //   toast.error(AppConstant.ALERT_ENTER_DESCRIPTION);
    //   return false;
    // }
    if (selectedType === POST_TYPE.LINK && link.trim() === '') {
      toast.error(AppConstant.ALERT_ENTER_LINK);
      return false;
    } else if (
      selectedType === POST_TYPE.LINK &&
      link !== '' &&
      !validLink(link)
    ) {
      toast.error(AppConstant.ALERT_ENTER_VALID_LINK);
      return false;
    } else if (selectedType === POST_TYPE.IMAGE && files?.length === 0) {
      toast.error(AppConstant.ALERT_SELECT_IMAGE);
      return false;
    } else if (
      selectedType === POST_TYPE.POLL &&
      (pollDescription ? pollDescription === '' : false)
    ) {
      toast.error(AppConstant.ALERT_ENTER_DESCRIPTION);
      return false;
    } else if (selectedType === POST_TYPE.POLL && checkPollOptionsEmpty()) {
      toast.error(AppConstant.ENTER_OPTION_TEXT);
      return false;
    } else {
      const postObj = {
        title: title.trim(),
        post_type: selectedType === '' ? POST_TYPE.TEXT : selectedType
      };
      if (description) {
        postObj.content = description.trim();
      }
      if (selectedType === POST_TYPE.LINK) {
        postObj.url = link.trim();
      }
      if (selectedType === POST_TYPE.IMAGE) {
        postObj.image = files[0];
      }
      if (selectedType === POST_TYPE.POLL) {
        let arr = [];
        pollOption.map(res => {
          arr.push(res.title);
        });
        postObj.poll_options = arr;
        if (pollDescription) {
          postObj.content = pollDescription;
        }
      }
      if (community?.id) {
        let data = {
          ...postObj,
          community_id: community?.id
        };

        const handleCompressedUpload = () => {
          const image = data.image;
          new Compressor(image, {
            quality: 0.6,
            success: compressedResult => {
              data.image = new File([compressedResult], compressedResult.name, {
                type: compressedResult.type
              });
              apiCall(postImg);
            }
          });
        };

        const apiCall = API => {
          setNextBtnDisappear(false);
          setLoading(true);
          API(dispatch, data)
            .then(res => {
              setNextBtnDisappear(true);
              if (res?.data?.status) {
                closeModal();
                setTimeout(() => {
                  router.push({
                    pathname: ROUTE?.POST_DETAILS,
                    query: { id: res?.data?.data?.post_id, post: 'post' }
                  });
                  setSelectedPost(POST_TYPE?.TEXT);
                  setFiles([]);
                  setTitle('');
                  setDescription('');
                  setPollDescription('');
                  setLink('');
                  setCommunity('');
                  setPollOption([{ title: '' }, { title: '' }]);
                }, 100);
                setLoading(false);
              } else {
              }
            })
            .catch(err => {
              setNextBtnDisappear(false);
              setLoading(false);
              showError(err);
            })
            .finally(() => {
              setNextBtnDisappear(false);
              setLoading(false);
            });
        };

        POST_TYPE?.IMAGE !== selectedType
          ? apiCall(CreatePostAPI)
          : handleCompressedUpload();
      } else {
        toast.error(AppConstant?.ALERT_SELECT_COMMUNITY);
      }
    }
  };

  const RenderArr = ({ data }) => {
    let sel = selectedType === data?.type;
    return (
      <div
        className="sm:mr-[53px] mr-0 mx-[12px] relative"
        onClick={() => {
          _onSelectPollType(data);
          // setFiles([]);
        }}
      >
        <div className="flex items-center cursor-pointer h-[36px]">
          <div
            className="
          flex items-center justify-center text-center] mr-[8px]"
          >
            {sel ? (
              <data.IconF />
            ) : (
              <data.Icon
                color={hasValue(
                  IsLight,
                  colors.Neutral900,
                  colors.Neutral900Dark
                )}
              />
            )}
          </div>
          <div className="text-center">
            <p
              className={`${
                sel ? 'text-Action' : 'text-Neutral900 dark:text-Neutral900Dark'
              } title-large-B`}
            >
              {data?.title}
            </p>
          </div>
        </div>
        <div
          className={`${
            sel ? 'bg-Action' : 'bg-transparent'
          } w-full h-[2px] rounded-[2px] px-[12px] absolute -mt-[2px]`}
        />
      </div>
    );
  };

  return (
    <Dialog
      fullWidth
      style={{ backdropFilter: 'blur(5px)' }}
      open={createPostModal}
      className="w-full"
      maxWidth={'md'}
      fullScreen={matches ? false : true}
      height={300}
      onClose={() => {
        closeModal();
        setTimeout(() => {
          setSelectedPost(POST_TYPE?.TEXT);
          setFiles([]);
          setTitle('');
          setDescription('');
          setPollDescription('');
          setLink('');
          setCommunity('');
          setPollOption([{ title: '' }, { title: '' }]);
        }, 100);
      }}
    >
      {loading ? (
        <div
          className="w-full items-center justify-center flex h-[649px]"
          style={{ background: 'rgba(0,0,0,0.3)' }}
        >
          <CircularProgress sx={{ mt: 5 }} />
        </div>
      ) : (
        <div
          className={`
      // ${!matches && 'h-screen'}
       bg-White dark:bg-DarkBg`}
        >
          <div className="h-[161px]">
            <RenderHeader
              closeModal={() => {
                closeModal();
                setTimeout(() => {
                  setSelectedPost(POST_TYPE?.TEXT);
                  setFiles([]);
                  setTitle('');
                  setDescription('');
                  setPollDescription('');
                  setLink('');
                  setCommunity('');
                  setPollOption([{ title: '' }, { title: '' }]);
                }, 100);
              }}
            />
            <div className="z-10 relative">
              <RenderCommunity
                community={community}
                setCommunity={val => setCommunity(val)}
              />
            </div>
            <div className="px-[18px] flex items-center mb-[20px] shadow-[0_0.33px_0_0_#BDC5CD] dark:shadow-[0_0.33px_0_0_#313234] ">
              {arr.map((d, i) => {
                return <RenderArr data={d} key={i} />;
              })}
            </div>
          </div>
          <div className="px-[18px] min-h-[410px]">
            {/* ---------- text post ---------- */}
            <div className="h-[48px]">
              <input
                maxLength={240}
                placeholder={AppConstant?.GIVE_YOUR_POST_TITLE}
                className="heading-small-B p-[12px] placeholder-Neutral800 bg-transparent text-Neutral900 dark:text-Neutral900Dark border-[0px] rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full outline-none appearance-none"
                value={title}
                onChange={e => setTitle(e?.target?.value)}
              />
            </div>
            <div className="mt-[5px]">
              {POST_TYPE?.POLL !== selectedType ? (
                <textarea
                  // rows={5}
                  maxLength={2000}
                  placeholder={AppConstant?.DESC_TO_YOUR_TEXT_POST}
                  className={`
                  ${
                    POST_TYPE?.TEXT === selectedType
                      ? 'h-[345px] max-h-[345px] min-h-[345px]'
                      : 'h-[100px] max-h-[100px] min-h-[100px]'
                  }
                  title-large-MB p-[12px] placeholder-Neutral800 bg-transparent text-Neutral900 dark:text-Neutral900Dark rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full outline-none appearance-none`}
                  //border-[1px] border-Neutral400 dark:border-Neutral400Dark
                  value={description}
                  onChange={e => setDescription(e?.target?.value)}
                />
              ) : (
                <textarea
                  // rows={5}
                  maxLength={240}
                  placeholder={AppConstant?.ADD_OPTIONAL_DESCRIPTION}
                  className="title-large-MB h-[180px] max-h-[180px] min-h-[180px] p-[12px] placeholder-Neutral800 bg-transparent text-Neutral900 dark:text-Neutral900Dark rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full outline-none appearance-none"
                  //border-[1px] border-Neutral400 dark:border-Neutral400Dark
                  value={pollDescription}
                  onChange={e => setPollDescription(e?.target?.value)}
                />
              )}

              {POST_TYPE?.LINK === selectedType && (
                <textarea
                  // rows={5}
                  maxLength={300}
                  placeholder={AppConstant?.VALID_LINK_TO_YOUR_POST}
                  className="title-large-MB h-[255px] max-h-[255px] min-h-[255px] p-[12px] placeholder-Neutral800 bg-transparent text-Neutral900 dark:text-Neutral900Dark rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full outline-none appearance-none"
                  //border-[1px] border-Neutral400 dark:border-Neutral400Dark
                  value={link}
                  onChange={e => setLink(e?.target?.value)}
                />
              )}

              {POST_TYPE?.IMAGE === selectedType && (
                <>
                  {files?.length === 0 ? (
                    <div
                      {...getRootProps({
                        className: 'dropzone h-[255px] justify-center'
                      })}
                      onClick={e => e.stopPropagation}
                    >
                      <input {...getInputProps()} />
                      <div className="flex items-center">
                        <p className="title-large-MB text-Neutral800">
                          {AppConstant?.DRAG_DROP_IMAGE}
                        </p>
                        <button
                          className="flex h-[43px] px-[20px] ml-[10px] bg-Action rounded-full items-center"
                          onClick={open}
                        >
                          <p className="title-large-B text-White">
                            {AppConstant?.UPLOAD}
                          </p>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <aside style={thumbsContainer}>{thumbs}</aside>
                  )}
                </>
              )}
              {POST_TYPE?.POLL === selectedType && (
                <div>
                  {pollOption?.map((d, i) => {
                    return (
                      <div
                        key={i}
                        className="flex m-[10px] h-[44px] items-center"
                      >
                        <div className="w-full my-[10px] h-[44px]">
                          <div className="bg-Neutral200 dark:bg-Neutral200Dark w-full flex rounded-[8px] md:mr-5 mr-1 items-center relative h-[44px]">
                            <input
                              maxLength={80}
                              placeholder={`${AppConstant?.OPTION} ${i + 1}`}
                              className="heading-medium-M p-[12px] placeholder-Neutral500 dark:placeholder-Neutral500Dark bg-transparent text-Neutral900 dark:text-Neutral900Dark border-[0px] rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full outline-none appearance-none"
                              value={d?.title}
                              onChange={e => _addPolls(e, i)}
                            />
                          </div>
                        </div>

                        {i >= 2 && (
                          <div
                            className="ml-[10px]"
                            onClick={() => _removePollOption(i)}
                          >
                            <icons.cross
                              w={24}
                              h={24}
                              color={hasValue(
                                IsLight,
                                colors.Neutral900,
                                colors.Neutral900Dark
                              )}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {pollOption.length < 6 && (
                    <button
                      className="h-[50px] flex items-center px-[18px]"
                      onClick={() => _addNewPollOption()}
                    >
                      <p className="heading-medium-M text-Neutral900 dark:text-Neutral900Dark">
                        {AppConstant?.ADD_OPTION}
                      </p>
                    </button>
                  )}
                </div>
              )}
            </div>
            {/* ----------  ---------- */}
          </div>
          {/* ----------  ---------- */}

          <div
            className="bg-White dark:bg-DarkBg bottom-0"
            style={{ width: '-webkit-fill-available' }}
          >
            <div className="px-[18px] flex h-[48px] mt-[15px] items-center pb-[12px] ">
              <div className="flex w-full mr-3 items-center">
                <div className="mr-[8px] hidden sm:block">
                  <icons.incognito />
                </div>
                <p className="w-full title-medium-B text-Neutral800 hidden sm:block">
                  {AppConstant?.NO_ONE_CAN_KNOW}
                </p>
              </div>
              <button
                className={` ${
                  title?.trim() !== '' ? 'bg-Action' : 'bg-Neutral800'
                } flex h-[43px] px-[20px] rounded-full items-center`}
                onClick={() => handlePressNext()}
              >
                <p className="title-large-B text-White">{AppConstant?.NEXT}</p>
                {/* <div className="hidden sm:block ml-[4px]">
                  <div className="w-[37px] h-[16px] bg-White rounded-[20px] flex items-center pl-[6.75px] pr-[5px] justify-between">
                    <icons.vector w={10} h={10} />
                    <icons.arrowLeftCorner />
                  </div>
                </div> */}
              </button>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default CreatePost;
